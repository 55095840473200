import React, { FC, useState } from 'react';
import { Anchor, Box, Button, Group, Modal, SimpleGrid, Text, Title } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import WeekDayList from '@modules/weeks/components/list/WeekDayList';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { WeeksService } from '@modules/weeks/service';
import { Link } from 'react-router-dom';
import { defineAction, useAction } from '@core/router/action';
import * as EI from 'fp-ts/Either';
import { constFalse } from 'fp-ts/function';
import { renderOptional } from '@shared/utils/render';
import { HttpError, HttpStatusCode } from '@core/http';
import { defineRoute } from '@core/router';

interface ErrorMessageProps {
  error: HttpError;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  return (
    <Text size="sm" mb="md" fw={700} c="red">
      {error.status === HttpStatusCode.CONFLICT
        ? 'Oups, il manque des trajets pour valider la semaine'
        : 'Erreur technique'}
    </Text>
  );
};

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(WeeksService.getWeeks()),
});

const actions = {
  validate: defineAction({
    type: 'validate',
    handler: () => WeeksService.validateWeek(),
    flashOptions: {
      error: constFalse,
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/' : undefined),
  }),
};

const WeekListPage: FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  const week = useLoader<typeof loader>();

  const isFirstWeek = week.weekNumber === 0;

  const [validateLoading, validate, validateError] = useAction(actions.validate);

  const handleOpen = () => setOpened(true);
  const handleClose = () => setOpened(false);
  const handleValidate = () => {
    handleClose();
    validate();
  };

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title={isFirstWeek ? 'Ma semaine type' : 'Mes trajets de la semaine'} />

      {isFirstWeek ? (
        <Text mt="xl" mb="lg">
          Remplis chaque journée, avec ton mode de transport le plus fréquent. Cette semaine type servira à mesurer ta
          progression en terme d’émissions dans l’air.
        </Text>
      ) : (
        <>
          <Text size="lg" fw={700} mt="xl">
            Semaine {week.weekNumber}/10 du défi
          </Text>

          <Text mt="xs" mb="lg">
            Hop, c’est l’heure de nous dire quels mode de transport tu as utilisé cette semaine. On est cools, on t’a
            remis ta semaine type : modifie les jours où tu as changé de transport.
          </Text>
        </>
      )}

      <WeekDayList week={week} withErrors />

      <Box mt="lg" ta="center">
        {isFirstWeek ? (
          <>
            {renderOptional(validateError, error => (
              <ErrorMessage error={error} />
            ))}
            <Button loading={validateLoading} disabled={validateLoading} onClick={handleOpen}>
              Valider mes trajets
            </Button>

            <Group pt={15} style={{ justifyContent: 'center' }}>
              <Anchor component={Link} to="/profile">
                Voir mes adresses et mes trajets enregistrés
              </Anchor>
            </Group>
          </>
        ) : (
          <>
            {renderOptional(validateError, error => (
              <ErrorMessage error={error} />
            ))}

            <Group grow>
              <Button loading={validateLoading} disabled={validateLoading} onClick={handleOpen}>
                Valider mes trajets
              </Button>
            </Group>
          </>
        )}
      </Box>

      <Modal
        size="md"
        opened={opened}
        onClose={handleClose}
        title={
          <Title color="violet" size="h3">
            Souhaitez-vous valider votre semaine ?
          </Title>
        }
        withCloseButton={false}
        centered
      >
        <Text>Cette action est irréversible et vous ne pourrez plus modifier cette semaine.</Text>

        <SimpleGrid cols={2} pt={10}>
          <Button onClick={handleClose} size="sm" variant="outline">
            <Text size={14}>Annuler</Text>
          </Button>

          <Button onClick={handleValidate} size="sm">
            <Text size={14}>Valider</Text>
          </Button>
        </SimpleGrid>
      </Modal>
    </Box>
  );
};

const weekListRoute = defineRoute({
  component: WeekListPage,
  loader,
  actions,
});

export default weekListRoute;
