import styled from '@emotion/styled';

export const HomeHeaderProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${props => props.theme.colors.violet[6]};
  }
`;

export const HomeHeaderProfileImgContainer = styled.div`
  width: 60px;
  height: 60px;
`;
