import React, { FC, useMemo } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { Address } from '@shared/modules/address/model';
import { ProfileAddress } from '@modules/profile/address/model';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { ProfileAddressService } from '@modules/profile/address/service';
import { HttpError } from '@core/http';
import { defineRoute, useBack } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Box } from '@mantine/core';
import AddressForm from '@modules/profile/address/components/form/AddressForm';
import { defineAction, useAction } from '@core/router/action';
import { ProfileAddressUtils } from '@modules/profile/address/utils';
import { AddressUtils } from '@shared/modules/address/utils';
import AddressType = Address.AddressType;

interface LoaderReturnType {
  id: ProfileAddress.ProfileAddressId | null;
  type: Address.AddressType;
  body: ProfileAddress.ProfileAddressBody;
}

const params = z.object({
  id: z.union([
    ProfileAddress.ProfileAddressId,
    z.literal('new'),
    z.literal(Address.AddressType.Home),
    z.literal(Address.AddressType.Work),
  ]),
});

const loader = defineLoader({
  params,
  handler: ({ params }) => {
    const mapAddressToLoaderReturn = (address: ProfileAddress.ProfileAddress): LoaderReturnType => ({
      id: address.id,
      type: address.type,
      body: ProfileAddressUtils.profileAddressToBody({
        ...address,
        name: address.type === AddressType.Custom ? address.name : Address.addressTypeName[address.type],
      }),
    });

    const getDefaultLoaderReturn = (type: Address.AddressType): LoaderReturnType => ({
      id: null,
      type,
      body: {
        type,
        name: type === AddressType.Custom ? '' : Address.addressTypeName[type],
        address: AddressUtils.getEmptyAddress(),
      },
    });

    if (params.id === 'new') {
      return T.of(getDefaultLoaderReturn(Address.AddressType.Custom));
    }

    if (params.id === Address.AddressType.Home || params.id === Address.AddressType.Work) {
      return pipe(
        ProfileAddressService.getProfileAddresses(),
        TE.chainOptionK(() => HttpError.notFound)(addresses =>
          pipe(
            addresses,
            A.findFirst(address => address.type === params.id),
          ),
        ),
        TE.fold(
          () => T.of(getDefaultLoaderReturn(params.id as any)),
          address => T.of(mapAddressToLoaderReturn(address)),
        ),
      );
    }

    return pipe(
      ProfileAddressService.getProfileAddress(ProfileAddress.ProfileAddressId.parse(params.id)),
      TE.map(mapAddressToLoaderReturn),
      httpTaskToResponseTask,
    );
  },
});

const savePayload = z.object({
  id: ProfileAddress.ProfileAddressId.nullable(),
  params: ProfileAddress.ProfileAddressParams,
});

const actions = {
  save: defineAction({
    type: 'save',
    payload: savePayload,
    handler: ({ payload: { id, params } }) =>
      pipe(
        O.fromNullable(id),
        O.fold(
          () => ProfileAddressService.createProfileAddress(params),
          id => ProfileAddressService.updateProfileAddress(id, params),
        ),
      ),
  }),
};

const DetailAddressDetailPage: FC = () => {
  const { id, type, body } = useLoader<typeof loader>();

  const goBack = useBack();

  const [loading, save] = useAction(actions.save);

  const title = useMemo(() => {
    if (type === Address.AddressType.Custom) {
      if (id) {
        return body.name;
      } else {
        return 'Nouvelle adresse';
      }
    }

    return Address.addressTypeName[type];
  }, [id, body.name, type]);

  const handleSubmit = (body: ProfileAddress.ProfileAddressBody) =>
    pipe(
      () =>
        save({
          id,
          params: ProfileAddressUtils.profileAddressBodyToParams(body),
        }),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title={title} />

      <AddressForm type={type} body={body} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const profileAddressDetailRoute = defineRoute({
  component: DetailAddressDetailPage,
  loader,
  actions,
});

export default profileAddressDetailRoute;
