import styled from '@emotion/styled';
import { createPolymorphicComponent, Text, TextProps } from '@mantine/core';

function getTextColor(color: string, width: number): string {
  if (width >= 10) {
    if (color === 'white') {
      return '#707070';
    } else {
      return 'white';
    }
  } else {
    return color;
  }
  /*if (color === 'white') {
    return  "#707070";
  } else {
    return "white";
  }*/
}

export const ImpactProgressContainer = styled.div`
  margin-top: 40px;
  position: relative;
  height: 45px;
`;

export const ImpactProgressBase = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: ${props => props.color};
  width: 100%;
  min-width: 5px;
  height: 30px;

  > div:first-of-type {
    color: ${props => getTextColor(props.color, 100)};
  }
`;

export const ImpactProgressCurrent = styled.div<{ color: string; width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: ${props => props.color};
  width: ${props => props.width}%;
  min-width: 5px;
  height: 30px;

  > div:first-of-type {
    transform: translateY(-50%) ${props => (props.width < 10 ? 'translateX(calc(100% + 20px))' : null)};
    color: ${props => getTextColor(props.color, props.width)};
  }
`;

export const ImpactProgressPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(to left, #ffffff 30%, #b0a3bc 100%);
  width: 100%;
  height: 30px;
`;

export const ImpactProgressLabel = createPolymorphicComponent<'p', TextProps>(styled(Text)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`);

export const ImpactProgressObjective = styled.div<{ objective: number; background: string }>`
  position: absolute;
  top: 0;
  right: ${props => props.objective * 100}%;
  border: 1px dashed ${props => props.background};
  width: 1px;
  height: 35px;
  z-index: 2;
`;

export const ImpactProgressBest = styled.div<{ best: number }>`
  position: absolute;
  top: 0;
  left: ${props => props.best}%;
  border: 1px dashed ${props => props.theme.colors.violet[2]};
  width: 1px;
  height: 35px;
  z-index: 2;
`;

export const ImpactProgressValue = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(110%) translateX(-5px);
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    width: max-content;
  }
`;

export const ImpactProgressTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-110%);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
`;

export const ImpactCurrentProgressPercent = styled.div<{ positive: boolean }>`
  display: inline-flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-radius: 3px;
  background-color: ${props => (props.positive ? '#EE5800' : '#AEFF46')};
  color: ${props => (props.positive ? '#ffffff' : '#000000')};
  flex: 1 1 auto;
  width: max-content;
  height: 15px;
`;
