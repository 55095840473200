import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';

export namespace Address {
  export const PlaceId = nonEmptyStringSchema.brand<'PlaceId'>();
  export type PlaceId = z.infer<typeof PlaceId>;

  export enum AddressType {
    Home = 'home',
    Work = 'work',
    Custom = 'custom',
  }

  export const addressTypeName: Record<AddressType, string> = {
    [AddressType.Home]: 'Mon domicile',
    [AddressType.Work]: 'Mon lieu de travail',
    [AddressType.Custom]: 'Mon adresse',
  };

  export const Address = z.object({
    label: nonEmptyStringSchema,
    placeId: PlaceId
  })

  export type Address = z.infer<typeof Address>;
}
