import { CookieConsentText } from '@innovantic/cookie-consent';

export namespace Analytics {
  export const PRIVACY_LINK = '/cgu';

  export enum ConsentChoice {
    Statistic = 'statistic',
  }

  export const consentChoices: Record<ConsentChoice, string> = {
    [ConsentChoice.Statistic]: 'Analyse statistique et optimisation',
  };

  export const CONSENT_TEXT: Partial<CookieConsentText> = {
    bannerLine1:
      "Le programme Modoux est une expérimentation d'intérêt général visant à réduire les émissions polluantes. En participant à ce projet, vous nous transmettez vos habitudes de transport qui sont récoltées de façon anonyme notamment via des cookies.",
    bannerLine2:
      'Ces informations nous permettent de concevoir des solutions innovantes adaptées aux franciliens. La recherche vous dit MERCI !',
  };
}
