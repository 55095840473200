import React, { FC, useMemo } from 'react';
import { defineRoute } from '@core/router';
import AuthLayout from '@layout/auth/AuthLayout';
import RegisterForm from '@modules/auth/components/register/RegisterForm';
import { defineAction, useAction } from '@core/router/action';
import { Auth } from '@modules/auth/model';
import { AuthService } from '@modules/auth/service';
import { constFalse, pipe } from 'fp-ts/function';
import * as EI from 'fp-ts/Either';
import { renderOptional } from '@shared/utils/render';
import { Alert, Text } from '@mantine/core';
import * as O from 'fp-ts/Option';
import { AuthUtils } from '@modules/auth/utils';
import { HttpStatusCode } from '@core/http';

function getErrorMessage(status: HttpStatusCode): string {
  switch (status) {
    case HttpStatusCode.CONFLICT:
      return 'Un utilisateur existe déjà pour cet email';
    case HttpStatusCode.FORBIDDEN:
      return "Cet e-mail n'est pas autorisé à créer un compte";
    case HttpStatusCode.PRECONDITION_FAILED:
      return "Oups ! Les inscriptions sont clôturées pour ce projet d'étude";
    default:
      return 'Une erreur technique est survenue';
  }
}

const actions = {
  register: defineAction({
    type: 'register',
    payload: Auth.RegisterParams,
    handler: ({ payload }) => AuthService.register(payload),
    flashOptions: {
      error: constFalse,
    },
    redirect: ({ request, result }) => {
      if (EI.isRight(result)) {
        return AuthUtils.getAuthenticatedRedirection(request.url);
      }

      return null;
    },
  }),
};

const RegisterPage: FC = () => {
  const [loading, register, error] = useAction(actions.register);

  const errorMessage = useMemo(
    () =>
      pipe(
        error,
        O.map(error => getErrorMessage(error.status)),
      ),
    [error],
  );

  return (
    <AuthLayout title="créer mon compte">
      {renderOptional(errorMessage, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      <RegisterForm loading={loading} onSubmit={register} />
    </AuthLayout>
  );
};

const registerRoute = defineRoute({
  component: RegisterPage,
  actions,
});

export default registerRoute;
