import React, { FC } from 'react';
import { Weeks } from '@modules/weeks/model';
import { Carousel } from '@mantine/carousel';
import DayTravelOutward from '@modules/weeks/components/update/day/outward/DayTravelOutward';
import { ProfileAddress } from '@modules/profile/address/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as O from 'fp-ts/Option';
import { WeeksUtils } from '@modules/weeks/utils';
import DayTravelReturn from '@modules/weeks/components/update/day/return/DayTravelReturn';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileTravels } from '@modules/profile/travels/model';
import { filterEmptyStringToOption } from '@shared/utils/string';
import { sequenceS } from 'fp-ts/Apply';

interface DayTravelsProps {
  day: Weeks.TravelDay;
  addresses: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels: Array<ProfileTravels.ProfileTravel>;
  loading: boolean;
  readonly: boolean;
  onSubmit: (
    params: Weeks.UpdateTravelDayParams,
    direction: 'prev' | 'next',
    travelToSave: ProfileTravels.ProfileTravelParams | null,
  ) => void;
}

const DayTravels: FC<DayTravelsProps> = ({ day, addresses, vehicles, travels, loading, readonly, onSubmit }) => {
  const handleSubmitOutward = (body: Weeks.UpdateTravelDayOutwardBody, direction: 'prev' | 'next') => {
    const getTravels = (): Array<Weeks.UpdateTravelParams> => {
      if (body.type === Weeks.TravelType.Office) {
        const returnTravels = pipe(
          day.travels,
          A.filter(travel => travel.direction === Weeks.TravelDirection.Return),
          A.sort(WeeksUtils.ordTravel),
          A.map(WeeksUtils.travelToUpdateTravelParams),
        );

        return [
          ...WeeksUtils.updateTravelBodyToUpdateTravelParams(body, Weeks.TravelDirection.Outward),
          ...returnTravels,
        ];
      }

      return [];
    };

    const getTravelToSave = (): ProfileTravels.ProfileTravelParams | null => {
      if (body.type === Weeks.TravelType.Office) {
        return pipe(
          sequenceS(O.Apply)({
            name: filterEmptyStringToOption(body.travelName),
            travels: NEA.fromArray(
              WeeksUtils.updateTravelBodyToUpdateTravelParams(body, Weeks.TravelDirection.Outward),
            ),
          }),
          O.toNullable,
        );
      }

      return null;
    };

    const params: Weeks.UpdateTravelDayParams = {
      id: day.id,
      type: body.type,
      isReturnTravelIdentical: day.isReturnTravelIdentical,
      travels: getTravels(),
    };

    onSubmit(params, direction, getTravelToSave());
  };

  const handleSubmitReturn = (body: Weeks.UpdateTravelDayReturnBody, direction: 'prev' | 'next') => {
    const getTravels = (): Array<Weeks.UpdateTravelParams> => {
      const outwardsTravels = pipe(
        day.travels,
        A.filter(travel => travel.direction === Weeks.TravelDirection.Outward),
        A.sort(WeeksUtils.ordTravel),
        A.map(WeeksUtils.travelToUpdateTravelParams),
      );

      if (body.isReturnTravelIdentical) {
        return outwardsTravels;
      } else {
        return [
          ...outwardsTravels,
          ...WeeksUtils.updateTravelBodyToUpdateTravelParams(body, Weeks.TravelDirection.Return),
        ];
      }
    };

    const getTravelToSave = (): ProfileTravels.ProfileTravelParams | null => {
      if (!body.isReturnTravelIdentical) {
        return pipe(
          sequenceS(O.Apply)({
            name: filterEmptyStringToOption(body.travelName),
            travels: NEA.fromArray(WeeksUtils.updateTravelBodyToUpdateTravelParams(body, Weeks.TravelDirection.Return)),
          }),
          O.toNullable,
        );
      }

      return null;
    };

    const params: Weeks.UpdateTravelDayParams = {
      id: day.id,
      type: day.type,
      isReturnTravelIdentical: body.isReturnTravelIdentical,
      travels: getTravels(),
    };

    onSubmit(params, direction, getTravelToSave());
  };

  return (
    <>
      <Carousel.Slide id={`day-${day.day}`}>
        <DayTravelOutward
          day={day}
          addresses={addresses}
          vehicles={vehicles}
          travels={travels}
          loading={loading}
          readonly={readonly}
          onSubmit={handleSubmitOutward}
        />
      </Carousel.Slide>

      {day.type === Weeks.TravelType.Office ? (
        <Carousel.Slide>
          <DayTravelReturn
            day={day}
            addresses={addresses}
            vehicles={vehicles}
            travels={travels}
            loading={loading}
            readonly={readonly}
            onSubmit={handleSubmitReturn}
          />
        </Carousel.Slide>
      ) : null}
    </>
  );
};

export default DayTravels;
