import { z } from 'zod';
import { emailSchema, nonEmptyStringSchema } from '@shared/schemas';
import { Passwords } from '@modules/passwords/model';

export namespace Auth {
  export namespace Tokens {
    export const Bearer = z.string().brand<'Bearer'>();
    export type Bearer = z.infer<typeof Bearer>;

    export const Refresh = z.string().brand<'Refresh'>();
    export type Refresh = z.infer<typeof Refresh>;

    export interface Tokens {
      token: Bearer;
      refreshToken: Refresh | null;
    }
  }

  export const AuthenticateParams = z.object({
    email: emailSchema,
    password: nonEmptyStringSchema,
  });

  export type AuthenticateParams = z.infer<typeof AuthenticateParams>;

  export const RegisterParams = z
    .object({
      email: emailSchema,
      firstName: nonEmptyStringSchema,
      lastName: nonEmptyStringSchema,
      password: Passwords.passwordSchema,
      confirmPassword: z.string(),
    })
    .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
      path: ['confirmPassword'],
      message: 'Les mots de passe ne correspondent pas',
    });

  export type RegisterParams = z.infer<typeof RegisterParams>;
}
