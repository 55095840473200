import { z } from 'zod';
import { Weeks } from '@modules/weeks/model';
import { nonEmptyStringSchema } from '@shared/schemas';

export namespace ProfileTravels {
  export const ProfileTravelId = z.string().uuid().brand<'ProfileTravelId'>();
  export type ProfileTravelId = z.infer<typeof ProfileTravelId>;

  export type ProfileTravelItem = Omit<Weeks.Travel, 'direction'>;

  export interface ProfileTravel {
    id: ProfileTravelId;
    name: string;
    travels: Array<ProfileTravelItem>;
  }

  export const ProfileTravelParams = z.object({
    name: nonEmptyStringSchema,
    travels: z.array(Weeks.UpdateTravelParams),
  });

  export type ProfileTravelParams = z.infer<typeof ProfileTravelParams>;

  export const ProfileTravelBody = Weeks.UpdateTravelBody.extend({
    name: nonEmptyStringSchema,
  });

  export type ProfileTravelBody = z.infer<typeof ProfileTravelBody>;
}
