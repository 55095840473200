import { z } from 'zod';

export namespace Infos {
  export const Id = z.string().uuid().brand<'InfoId'>();
  export type Id = z.infer<typeof Id>;

  export enum Name {
    Car = 'car',
    Pollution = 'pollution',
    Atmosphere = 'atmosphere',
    Particles = 'particles',
    Traffic = 'traffic',
    Transport = 'transport',
    Measure = 'measure',
    Size = 'size',
    PM = 'pm',
    Fuel = 'fuel',
    Rejection = 'rejection',
    NO2 = 'no2',
    Datas = 'datas',
    IARC = 'iarc',
    Necrosis = 'necrosis',
    Gas = 'gas',
    Problems = 'problems',
  }

  export interface Detail {
    description: string;
    link: string;
  }

  export interface ApiDetail {
    id: Id;
    name: Name;
  }
}
