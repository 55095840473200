import styled from '@emotion/styled';
import { Accordion, AccordionProps, createPolymorphicComponent } from '@mantine/core';

export const HomeHeaderAccordionContainer = createPolymorphicComponent<'div', AccordionProps>(styled(Accordion)`
  [data-active] {
    background-color: transparent;
  }

  .mantine-Accordion-control {
    padding: 0;
    justify-content: flex-end;

    .mantine-Accordion-label {
      flex: 0 0 auto;
    }
  }

  .mantine-Accordion-content {
    padding: 0;
  }
`);

export const HomeHeaderAccordionItems = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;
