import React, { FC } from 'react';

import { Carousel } from '@mantine/carousel';
import { Group, Stack, Text } from '@mantine/core';

import logo from '@assets/auth/illus/modoux.svg';

import illu1 from '@assets/auth/illus/illu1.svg';
import illu2 from '@assets/auth/illus/illu2.svg';
import illu3 from '@assets/auth/illus/illu3.svg';
import illu4 from '@assets/auth/illus/illu4.svg';
import illu5 from '@assets/auth/illus/illu5.svg';

import * as Styled from './AuthHomeSlider.styles';

const slides: Array<[string, string]> = [
  [illu1, 'bouger doux, vivre doux, respirer doux'],
  [illu2, 'Réalise ton diagnostic mobilité pour comprendre ton impact et bouger tes trajets'],
  [illu3, 'Mets-toi au défi en équipe : c’est plus facile ensemble de changer ses habitudes !'],
  [illu4, 'Challenge les autres équipes à travers le Grand Paris. C’est à celui qui changera le plus !'],
  [illu5, 'Bref, déplace-toi autrement, offre-toi un meilleur quotidien, et un air plus sain'],
];

const AuthHomeSlider: FC = () => {
  return (
    <Carousel
      withIndicators
      withControls={false}
      pb={45}
      mb={15}
      styles={theme => ({
        indicator: {
          width: 7,
          height: 7,
          background: theme.colors.violet[2],
          transition: 'all 0.25s ease-in-out',

          '&[data-active]': {
            width: 10,
            background: theme.colors.violet[6],
          },
        },
      })}
    >
      {slides.map(([img, text], index) => (
        <Carousel.Slide key={index}>
          <Stack px={20} spacing="xs">
            <Styled.AuthHomeSliderImageContainer>
              <img src={img} alt="Illustration" />
            </Styled.AuthHomeSliderImageContainer>

            {index === 0 ? (
              <Group position="center">
                <img src={logo} width={148} height={28} alt="Logo" />
              </Group>
            ) : null}

            <Text align="center">{text}</Text>
          </Stack>
        </Carousel.Slide>
      ))}
    </Carousel>
  );
};

export default AuthHomeSlider;
