import { ReactNode } from 'react';

export enum ProjectName {
  AirParif = 'air-parif',
  GrandParis = 'grand-paris',
  Paris = 'paris',
  Ademe = 'ademe',
}

export interface ProjectDetail {
  image: ReactNode;
  description: string;
  link: string;
}

export type ProjectList = Record<ProjectName, ProjectDetail>;
