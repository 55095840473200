import { defineLoader, useParentLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import { HttpStatusCode } from '@core/http';
import { redirect } from 'react-router-dom';
import { defineRoute } from '@core/router';
import { ProfileService } from '@modules/profile/service';
import { AuthUtils } from '@modules/auth/utils';

const profileLoader = defineLoader({
  id: 'profile-loader',
  handler: ({ request }) =>
    pipe(
      ProfileService.getProfile(),
      T.chainIOK(res => () => {
        if (EI.isLeft(res)) {
          if (HttpStatusCode.UNAUTHORIZED === res.left.status) {
            throw redirect(AuthUtils.getNotAuthenticatedRedirection(request.url));
          }

          throw res.left.toResponse();
        }

        return res.right;
      }),
    ),
});

const profileLoaderRoute = defineRoute({
  loader: profileLoader,
});

export function useProfile() {
  return useParentLoader<typeof profileLoaderRoute.loader>('profile-loader');
}

export default profileLoaderRoute;
