import React, { FC, useMemo } from 'react';
import { Address } from '@shared/modules/address/model';
import { ProfileAddress } from '@modules/profile/address/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Stack } from '@mantine/core';
import ProfileListItem from '@modules/profile/components/list/ProfileListItem';
import { MIconEdit } from '@styles/icons';

interface AddressListProps {
  addresses: Array<ProfileAddress.ProfileAddress>;
}

const AddressList: FC<AddressListProps> = ({ addresses }) => {
  const customAddresses = useMemo(
    () =>
      pipe(
        addresses,
        A.filter(address => address.type === Address.AddressType.Custom),
      ),
    [addresses],
  );

  return (
    <Stack spacing={8} mt={33}>
      <ProfileListItem title="Mon domicile" to="home" icon={<MIconEdit />} iconColor="violet.6" />
      <ProfileListItem title="Mon lieu de travail" to="work" icon={<MIconEdit />} iconColor="violet.6" />

      {customAddresses.map(address => (
        <ProfileListItem
          key={address.id}
          title={address.name}
          to={address.id}
          icon={<MIconEdit />}
          iconColor="violet.6"
        />
      ))}
    </Stack>
  );
};

export default AddressList;
