import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { WeeksService } from '@modules/weeks/service';
import { defineRoute } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Box } from '@mantine/core';
import WeekDayList from '@modules/weeks/components/list/WeekDayList';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(WeeksService.getWeeks(true)),
});

const ProfileWeeksListPage: FC = () => {
  const week = useLoader<typeof loader>();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Ma semaine type" />

      <Box mt={34}>
        <WeekDayList week={week} readonly />
      </Box>
    </Box>
  );
};

const profileWeeksListRoute = defineRoute({
  component: ProfileWeeksListPage,
  loader,
});

export default profileWeeksListRoute;
