import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Box, createPolymorphicComponent, List, Text, TextProps, Title } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import styled from '@emotion/styled';

const CGUText = createPolymorphicComponent<'p', TextProps>(styled(Text)`
  padding-top: 20px;
  font-size: 14px;

  > a {
    color: white;
    font-weight: 600;
  }
`);

const ListItem = styled(List.Item)`
  color: white;
  font-size: 14px;
`;

const CGUPage: FC = () => {
  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }} style={{ color: 'white' }}>
      <PageTitle title="Conditions générales d'utilisation" inverted />

      <Title order={3} color="white" pt={20}>
        Article 1. EDITEUR
      </Title>

      <CGUText>
        Airparif, Association interdépartementale pour la gestion du réseau automatique de surveillance de la pollution
        atmosphérique et d’alerte d’Ile de France dont le siège est situé au 7 rue Crillon, 75004 PARIS, immatriculée
        sous le numéro SIREN 316 465 236.
      </CGUText>

      <CGUText>
        L’association est joignable par téléphone au : 01 44 59 47 64 et par email à l’adresse : webmaster@airparif.fr.
      </CGUText>

      <CGUText>
        Le directeur de la publication est Monsieur Philippe Quénel, Président de l’association Airparif.
      </CGUText>

      <CGUText>
        L’application est hébergée en Allemagne par la société Digital Ocean dont le siège social est situé 101 Avenue
        of the Americas, 10th Floor, New York, NY 10013 et joignable via son site internet : www.digitalocean.com /
        e-mail : contact@digitalocean.com
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 2. DEFINITIONS
      </Title>

      <CGUText>
        Les termes débutant par une majuscule au sein des présentes CGU, qu’ils soient utilisés indifféremment au
        singulier ou au pluriel, auront la signification qui leur est donnée ci-après :
      </CGUText>

      <CGUText>
        « Application » désigne la webapplication « MODOUX » produite par Innovantic et fournie par Airparif, donnant
        accès aux Services, qui est téléchargeable gratuitement sur smartphone et consultable en ligne depuis tout
        navigateur Internet.
      </CGUText>

      <CGUText>
        L’Application comprend également les Contenus, les logiciels, les programmes, les outils (de programmation, de
        navigation, …), les bases de données, les systèmes d’exploitation, la documentation et tous autres éléments et
        services qui la compose, les mises à jour et les nouvelles versions qui peuvent être apportées à l’Application
        par Innovantic.
      </CGUText>

      <CGUText>
        « Contenu » désigne sans que cette liste soit limitative, la structure de l’Application, le contenu éditorial,
        les dessins, les illustrations, les images, les photographies, les chartes graphiques, les marques, les logos,
        les sigles, les dénominations sociales, les œuvres audiovisuelles, les œuvres multimédias, les contenus visuels,
        les contenus audio et sonores, ainsi que tout autre contenu présent au sein de l’Application et/ou tout autre
        élément composant l’Application.
      </CGUText>

      <CGUText>
        « Utilisateur » désigne une personne physique majeure ou mineure ayant préalablement obtenu l’autorisation de
        ses parents ou de la personne investie de l’autorité parentale, et utilisant l’Application pour ses besoins
        propres, dans le cadre d’un usage strictement personnel et non commercial, sans but lucratif direct ou indirect.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 3. OBJET DE L’APPLICATION
      </Title>

      <CGUText>
        L’Application a pour objet de recueillir des informations anonymisées sur les habitudes de transport et de
        donner accès à l’Utilisateur à des services d’information sur l’impact de ses pratiques de mobilité. Elle a été
        développée dans un cadre expérimental à des fins de recherche sur les leviers de changement de comportement.
        Elle ne poursuit pas de fin commerciale.
      </CGUText>

      <CGUText>
        Plusieurs Services peuvent être proposés à l’Utilisateur et notamment :
        <List pt={10}>
          <ListItem>
            L’accès à son diagnostic de mobilité (niveau de polluants émis en fonction des trajets réalisés) ;
          </ListItem>

          <ListItem>
            La comparaison de ses habitudes à celles de son équipe ainsi que celles de son organisation à d’autres
            organisations participant à l’expérimentation ;
          </ListItem>

          <ListItem>
            Des informations scientifiques sur l’impact de la mobilité quotidienne sur les émissions de polluants et
            l’impact de ces derniers sur la santé et l’environnement ;
          </ListItem>

          <ListItem>Des conseils et informations pour apprendre à se déplacer autrement ;</ListItem>

          <ListItem>
            De déclarer ses habitudes de mobilité (permettant d’avoir accès aux informations susmentionnées) ;
          </ListItem>
        </List>
      </CGUText>

      <CGUText>
        Cette liste est non exhaustive et peut être modifiée à tout moment par AIRPARIF sans que sa responsabilité ne
        puisse être engagée à ce titre par qui que ce soit.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 4. ACCES A L’APPLICATION
      </Title>

      <CGUText>
        Pour accéder et utiliser l’Application, vous devez posséder un téléphone compatible ou un terminal mobile ou
        fixe et un accès au réseau Internet.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 5. CONDITIONS D’UTILISATION DU SERVICE
      </Title>

      <CGUText>
        Les Services sont réservés aux participants volontaires inscrits préalablement auprès de leur employeur.
        L’utilisation de l’Application requiert la création d’un compte utilisateur, possible depuis la section « compte
        » de l’application.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 6. CONDITIONS FINANCIERES
      </Title>

      <CGUText>
        L’Application est proposée gratuitement aux Utilisateurs hors coûts d’abonnement auprès de l’opérateur de
        téléphonie mobile, hors coûts de connexion et d’accès au réseau Internet et hors surcoût éventuel facturé pour
        le chargement des données.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 7. APPLICATION DES CGU
      </Title>

      <CGUText>
        Les CGU sont applicables à tout Utilisateur de l’Application et des Services. L’Application pourra faire l’objet
        d’une mise à jour régulière.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 8. PROPRIETE INTELLECTUELLE DE L’APPLICATION
      </Title>

      <CGUText>
        Airparif est propriétaire exclusif de tous les droits de propriété intellectuelle afférents à l’Application et
        son Contenu. Sans préjudice des dispositions de l’article 7, aucune des dispositions des CGU ne peut être
        interprétée comme une cession, un transfert, une vente, une concession, une licence, un prêt, une location, une
        autorisation d’exploitation consentie directement ou indirectement Airparif au profit de l’Utilisateur sur
        l’Application, son Contenu et/ou les Services.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 9. PROTECTION DES DONNEES A CARACTERE PERSONNEL
      </Title>
      <Title order={4} color="white" pt={20}>
        9.1 Collecte de données à caractère personnel
      </Title>

      <CGUText>
        MODOUX, dans ses relations avec l’Utilisateur, est amené à traiter, pour son propre compte, des données à
        caractère personnel de l’Utilisateur, lesquelles données lui ont été communiquées avec son accord lors de son
        utilisation des Services.
      </CGUText>

      <Title order={4} color="white" pt={20}>
        9.2 Droits des Utilisateurs et gestion des données
      </Title>

      <CGUText>
        Toutes les données à caractère personnel que l’Utilisateur communique à l’Application sont strictement
        confidentielles. Elles sont collectées dans la finalité de l’utilisation des Services de l’application MODOUX.
        Elles seront conservées pendant une durée d’un an à compter de leur collecte.
      </CGUText>

      <CGUText>
        L’Utilisateur bénéficie du droit d’accéder à ses données, de les rectifier, les effacer, de demander la
        limitation de ce traitement, de s’y opposer ou de retirer son consentement. Pour exercer son droit d’effacer ses
        données ou tout autre droit, il suffit à l’Utilisateur d’écrire à Airparif à l’adresse suivante : 7 rue Crillon
        75004 PARIS en indiquant ses nom, prénom et adresse de courrier électronique.
      </CGUText>

      <CGUText>
        L’utilisateur pourra aussi demander la suppression de son compte en rédigeant une demande à l’adresse suivante :{' '}
        <a href="mailto:airparif-info@airparif.fr" title="">
          airparif-info@airparif.fr
        </a>{' '}
        ou directement sur le site{' '}
        <a href="https://www.airparif.asso.fr" target="_blank" rel="noreferrer noopener">
          https://www.airparif.asso.fr
        </a>{' '}
        via le formulaire de contact.
      </CGUText>

      <CGUText>
        Dans certaines circonstances particulières, Airparif peut être amenée à divulguer les données à caractère
        personnel des Utilisateurs, notamment lorsque celles-ci sont exigées par les autorités judiciaires. Les données
        à caractère personnel des Utilisateurs, et notamment les adresses de courrier électronique, ne font l’objet
        d’aucune location, vente, échange ou partage avec d’autres prestataires.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 10. RESILIATION
      </Title>

      <CGUText>
        Airparif se réserve le droit de suspendre l’accès aux Services ou de résilier le compte de l’Utilisateur de
        plein droit, sans préavis, sans formalité et sans indemnités, par courrier électronique en cas de manquement de
        la part de l’Utilisateur à ses obligations au titre des Conditions Générales, notamment dans les cas suivants :
        l’utilisation de l’Application par l’Utilisateur contraire à l’ordre du public et aux bonnes mœurs ; l’atteinte
        par l’Utilisateur aux droits de propriété intellectuelle de Airparif et/ou d’un tiers.
      </CGUText>

      <CGUText>
        En cas de résiliation du compte, l’Utilisateur ne pourra plus utiliser les Services depuis son compte qui sera
        clôturé.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 11. GARANTIE - RESPONSABILITE
      </Title>

      <CGUText>
        Airparif ne fournit aucune autre garantie que celles expressément visées par les Conditions Générales. Airparif
        ne saurait être responsable de tout dommage subi par l’Utilisateur qui résulterait du défaut d’utilisation des
        Services, du défaut du respect de tout ou partie des Conditions Générales, d’une faute de sa part, du fait d’un
        tiers ou de la survenance d’un cas de Force Majeure.
      </CGUText>

      <Title order={3} color="white" pt={20}>
        Article 12. LOI APPLICABLE
      </Title>

      <CGUText>
        Les Conditions Générales sont régies par le droit français, sans préjudice des autres dispositions légales
        impératives dont l’Utilisateur peut faire prévaloir en tant que consommateur. Si un litige devait naître à
        l’occasion de l’application directe ou indirecte de ces présentes CGU, la compétence serait donnée aux
        juridictions de Paris.
      </CGUText>

      <CGUText>Le site modoux-challenge.fr – Conditions générales d’utilisation</CGUText>

      <CGUText>
        L’utilisation du site modoux-challenge.fr implique l’acceptation pleine et entière des conditions générales
        d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à
        tout moment, les utilisateurs du site sont donc responsables de les consulter de manière régulière.
      </CGUText>

      <CGUText>
        Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour des raisons de
        maintenance technique peut toutefois survenir, nous vous invitons le cas échéant à vous reconnecter plus tard.
      </CGUText>

      <CGUText weight={600}>Accès et rectification</CGUText>

      <CGUText>
        L’accès à modoux-challenge.fr est possible sur inscription préalable auprès d’une organisation employeur ayant
        pris part à l’expérimentation Modoux, et nécessite la création d’un compte entreprise (lien vers landing page).
      </CGUText>

      <CGUText weight={600}>Accessibilité</CGUText>

      <CGUText>
        Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages
        matériels liés à l’utilisation du site. De plus, l’utilisateur s’engage à accéder au site en utilisant un
        matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.
      </CGUText>

      <CGUText weight={600}>Responsabilité des contenus</CGUText>

      <CGUText>
        Le site permet de diffuser des contenus de type textes, images, vidéos (hébergées sur YouTube) et liens
        hypertexte. Airparif n’est qu’un hébergeur de contenus.
      </CGUText>
    </Box>
  );
};

const cguRoute = defineRoute({
  component: CGUPage,
  loader: undefined,
});

export default cguRoute;
