import BackgroundLayout from '@layout/background/BackgroundLayout';
import homeRoute from '@modules/home/HomePage';
import { createRoute } from '@core/router';
import { Route } from 'react-router-dom';

const homeRoutes = (
  <Route path="/" element={<BackgroundLayout color="beige" />}>
    <Route index {...createRoute(homeRoute)} />
  </Route>
);

export default homeRoutes;
