import { FunctionComponent, SVGProps } from 'react';
import {
  MIconTransportBus,
  MIconTransportCar,
  MIconTransportCarPooling,
  MIconTransportElectric,
  MIconTransportMetro,
  MIconTransportTrain,
  MIconTransportTWoWheelers,
  MIconTransportWalk,
} from '@styles/icons';
import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';
import { Address } from '@shared/modules/address/model';
import { ProfileVehicles } from '@modules/profile/vehicules/model';

export namespace Weeks {
  export const TravelWeekId = z.string().uuid().brand<'TravelWeekId'>();
  export type TravelWeekId = z.infer<typeof TravelWeekId>;

  export const TravelDayId = z.string().uuid().brand<'TravelDayId'>();
  export type TravelDayId = z.infer<typeof TravelDayId>;

  export const TravelId = z.string().uuid().brand<'TravelId'>();
  export type TravelId = z.infer<typeof TravelId>;

  export enum TransportType {
    WalkOrBike = 'walk-or-bike',
    Car = 'car',
    CarPooling = 'carpooling',
    SubwayRerTransilien = 'subway-rer-transilien',
    Bus = 'bus',
    Train = 'train',
    TwoWheelers = 'two-wheelers',
    ScooterOrElectricBike = 'scooter-or-electric-bike',
  }

  export const transportTypeLabel: Record<TransportType, string> = {
    [TransportType.WalkOrBike]: 'Marche ou vélo',
    [TransportType.Car]: 'Voiture',
    [TransportType.CarPooling]: 'Covoiturage',
    [TransportType.SubwayRerTransilien]: 'Métro, RER, Transilien',
    [TransportType.Bus]: 'Bus',
    [TransportType.Train]: 'Train',
    [TransportType.TwoWheelers]: '2 roues motorisé',
    [TransportType.ScooterOrElectricBike]: 'Trottinette ou vélo électrique',
  };

  export const transportTypeIcon: Record<TransportType, FunctionComponent<SVGProps<SVGSVGElement>>> = {
    [TransportType.WalkOrBike]: MIconTransportWalk,
    [TransportType.Car]: MIconTransportCar,
    [TransportType.CarPooling]: MIconTransportCarPooling,
    [TransportType.SubwayRerTransilien]: MIconTransportMetro,
    [TransportType.Bus]: MIconTransportBus,
    [TransportType.Train]: MIconTransportTrain,
    [TransportType.TwoWheelers]: MIconTransportTWoWheelers,
    [TransportType.ScooterOrElectricBike]: MIconTransportElectric,
  };

  export enum TravelDays {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday',
  }

  export const travelDaysLabel: Record<TravelDays, string> = {
    [TravelDays.Monday]: 'Lundi',
    [TravelDays.Tuesday]: 'Mardi',
    [TravelDays.Wednesday]: 'Mercredi',
    [TravelDays.Thursday]: 'Jeudi',
    [TravelDays.Friday]: 'Vendredi',
    [TravelDays.Saturday]: 'Samedi',
    [TravelDays.Sunday]: 'Dimanche',
  };

  export enum TravelType {
    Leave = 'leave',
    Home = 'home',
    Office = 'office',
  }

  export enum TravelDirection {
    Outward = 'outward',
    Return = 'return',
  }

  export const UpdateTravelParams = z.object({
    id: TravelId.nullish(),
    direction: z.nativeEnum(TravelDirection),
    originAddressLabel: nonEmptyStringSchema,
    originPlaceId: Address.PlaceId,
    destinationAddressLabel: nonEmptyStringSchema,
    destinationPlaceId: Address.PlaceId,
    vehicleType: z.nativeEnum(TransportType),
    participantVehicleId: ProfileVehicles.ProfileVehicleId.nullish(),
  });

  export type UpdateTravelParams = z.infer<typeof UpdateTravelParams>;

  export const UpdateTravelDayParams = z.object({
    id: TravelDayId,
    type: z.nativeEnum(TravelType),
    isReturnTravelIdentical: z.boolean(),
    travels: z.array(UpdateTravelParams),
  });

  export type UpdateTravelDayParams = z.infer<typeof UpdateTravelDayParams>;

  export const UpdateTravelStep = z.object({
    id: TravelId.nullish(),
    address: Address.Address,
    vehicleType: z.nativeEnum(TransportType),
    participantVehicleId: ProfileVehicles.ProfileVehicleId.nullish(),
  });

  export const UpdateTravelBody = z.object({
    steps: z.array(UpdateTravelStep),
    destination: Address.Address,
  });

  export type UpdateTravelBody = z.infer<typeof UpdateTravelBody>;

  export const UpdateTravelDayBody = UpdateTravelBody.extend({
    travelName: nonEmptyStringSchema.nullish(),
  });
  export type UpdateTravelDayBody = z.infer<typeof UpdateTravelDayBody>;

  export const UpdateTravelDayOutwardBody = z.union([
    UpdateTravelDayBody.extend({ type: z.literal(TravelType.Office) }),
    z.object({ type: z.union([z.literal(TravelType.Home), z.literal(TravelType.Leave)]) }),
  ]);
  export type UpdateTravelDayOutwardBody = z.infer<typeof UpdateTravelDayOutwardBody>;

  export const UpdateTravelDayReturnBody = z.union([
    UpdateTravelDayBody.extend({ isReturnTravelIdentical: z.literal(false) }),
    z.object({ isReturnTravelIdentical: z.literal(true) }),
  ]);
  export type UpdateTravelDayReturnBody = z.infer<typeof UpdateTravelDayReturnBody>;

  export interface TravelWeek {
    id: TravelWeekId;
    weekNumber: number;
    travelDays: Record<TravelDays, TravelDay>;
  }

  export interface TravelDay {
    id: TravelDayId;
    day: TravelDays;
    type: TravelType;
    isReturnTravelIdentical: boolean;
    travels: Array<Travel>;
  }

  export type Travel = Omit<UpdateTravelParams, 'id' | 'participantVehicleId'> & {
    id: TravelId;
    index: number | null;
    participantVehicle?: ProfileVehicles.ProfileVehicle | null;
  };
}
