import React, { FC } from 'react';
import * as Styled from './HomeHeaderRealized.styles';
import * as R from 'fp-ts/Record';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import {
  HomeAdd,
  homeAddLabel,
  homeAddLink,
  homeAddTimeLabel,
  HomeInfos,
  homeInfosLabel,
  homeInfosLink,
  homeInfosTimeLabel,
  HomeStep,
  RealizedProps,
} from '@modules/home/model';
import HomeHeaderCard from '@modules/home/components/header/card/HomeHeaderCard';
import HomeHeaderAccordion from '@modules/home/components/header/accordion/HomeHeaderAccordion';

function getRealized(step1: HomeInfos, step2: HomeAdd): Array<RealizedProps> {
  const head = pipe(
    step1,
    R.toArray,
    A.map(([key, _]) => ({
      description: homeInfosLabel[key],
      time: homeInfosTimeLabel[key],
      link: homeInfosLink[key],
    })),
  );

  const tail = pipe(
    step2,
    R.toArray,
    A.filterMap(([key, value]) =>
      value === HomeStep.Done
        ? O.some({
            description: homeAddLabel[key],
            time: homeAddTimeLabel[key],
            link: homeAddLink[key],
          })
        : O.none,
    ),
  );

  return head.concat(tail);
}

interface HomeHeaderRealizedProps {
  step1: HomeInfos;
  step2: HomeAdd;
}

const HomeHeaderRealized: FC<HomeHeaderRealizedProps> = ({ step1, step2 }) => {
  const realized = getRealized(step1, step2);
  return (
    <Styled.HomeHeaderRealizedContainer>
      <HomeHeaderAccordion title="Voir les tâches réalisées">
        {realized.map((elt, index) => (
          <HomeHeaderCard
            isCompleted={true}
            description={elt.description}
            time={elt.time}
            link={elt.link}
            key={index}
          />
        ))}
      </HomeHeaderAccordion>
    </Styled.HomeHeaderRealizedContainer>
  );
};

export default HomeHeaderRealized;
