import React, { forwardRef } from 'react';
import { Group, SelectItem, Text } from '@mantine/core';
import { ProfileVehicles } from '@modules/profile/vehicules/model';

export interface CritAirItem extends SelectItem {
  value: ProfileVehicles.CritAir;
}

export const critAirOptions: Array<CritAirItem> = Object.values(ProfileVehicles.CritAir).map(critAir => ({
  value: critAir,
  label: ProfileVehicles.critAirInfos[critAir].label,
}));

const CritAirOption = forwardRef<HTMLDivElement, CritAirItem>(({ value, selected, ...others }, ref) => {
  const infos = ProfileVehicles.critAirInfos[value];

  return (
    <div ref={ref} {...others}>
      <Group noWrap spacing={7} p={6}>
        {infos.images ? (
          <img src={infos.images[0]} srcSet={`${infos.images[1]} 2x`} alt={infos.label} width={38} height={38} />
        ) : null}

        <Text size="xs" py={5}>
          {infos.label}
        </Text>
      </Group>
    </div>
  );
});

export default CritAirOption;
