import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Stack, Text, ThemeIcon } from '@mantine/core';
import { Weeks } from '@modules/weeks/model';
import { Link } from 'react-router-dom';
import {
  MIconArrowRight,
  MIconEdit,
  MIconRotate,
  MIconTransportHome,
  MIconTransportHomeOffice,
  MIconTransportMore,
  MIconTransportNotDefined,
  MIconTransportOff,
} from '@styles/icons';

import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';

import * as Styled from './WeekDayList.styles';
import { identity, pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from '@shared/utils/string';

interface WeekDayProps {
  day: Weeks.TravelDay;
  error?: boolean;
  readonly?: boolean;
}

const WeekDay: FC<WeekDayProps> = ({ day, error, readonly }) => {
  const iconsWrapperRef = useRef<HTMLDivElement | null>(null);

  const [iconsToShow, setIconsToShow] = useState<number>(0);

  const icons = useMemo(() => {
    const getTypeIcon = () => {
      switch (day.type) {
        case Weeks.TravelType.Home:
          return <MIconTransportHomeOffice />;
        case Weeks.TravelType.Leave:
          return <MIconTransportOff />;
        default:
          return <MIconTransportHome />;
      }
    };

    const getTransportIcons = () => {
      if (day.type === 'office') {
        if (A.isEmpty(day.travels)) {
          return [
            <ThemeIcon key="not-defined" variant="light" radius="xl" size={24}>
              <MIconTransportNotDefined />
            </ThemeIcon>,
          ];
        }

        const transports = pipe(
          day.travels,
          A.filterMap(travel => filterEmptyStringToOption(travel.vehicleType)),
          A.mapWithIndex((i, vehicleType) => {
            const Icon = Weeks.transportTypeIcon[vehicleType];

            return (
              <ThemeIcon key={i} variant="light" radius="xl" size={24}>
                <Icon />
              </ThemeIcon>
            );
          }),
        );

        return [...transports, ...(day.isReturnTravelIdentical ? [<MIconRotate key="rotate" />] : [])];
      }

      return [];
    };

    return [getTypeIcon(), ...getTransportIcons()];
  }, [day]);

  useEffect(() => {
    const calcIconsToShow = () => {
      if (iconsWrapperRef.current) {
        const width = iconsWrapperRef.current.clientWidth;

        const maxIcons = Math.floor(width / 28);

        if (maxIcons < icons.length) {
          setIconsToShow(maxIcons - 1);
        } else {
          setIconsToShow(maxIcons);
        }
      }
    };

    calcIconsToShow();

    window.addEventListener('resize', calcIconsToShow);

    return () => {
      window.removeEventListener('resize', calcIconsToShow);
    };
  }, [icons.length]);

  return (
    <Styled.WeekDayItemContainer
      component={Link}
      to={`update?day=${day.day}`}
      className={error ? 'error' : undefined}
      withBorder={false}
      radius="md"
      p={14}
    >
      <Text tt="uppercase" fz="sm" fw={700}>
        {Weeks.travelDaysLabel[day.day]}
      </Text>

      <Styled.WeekDayItemIcons ref={iconsWrapperRef}>
        {iconsToShow > 0 ? (
          <>
            {icons.slice(0, iconsToShow).map((icon, i) => (
              <Fragment key={i}>{icon}</Fragment>
            ))}

            {iconsToShow < icons.length ? <MIconTransportMore /> : null}
          </>
        ) : null}
      </Styled.WeekDayItemIcons>

      <Box c="orange" w={24} h={24}>
        {readonly ? <MIconArrowRight /> : <MIconEdit />}
      </Box>
    </Styled.WeekDayItemContainer>
  );
};

interface WeekDayListProps {
  week: Weeks.TravelWeek;
  withErrors?: boolean;
  readonly?: boolean;
}

const WeekDayList: FC<WeekDayListProps> = ({ week, withErrors, readonly }) => {
  const errors = useMemo<Record<Weeks.TravelDays, boolean>>(() => {
    const notTouched = pipe(
      week.travelDays,
      R.every(day => day.type === Weeks.TravelType.Office && A.isEmpty(day.travels)),
    );

    if (notTouched || !withErrors) {
      return pipe(
        Object.values(Weeks.TravelDays),
        A.map(day => [day, false] satisfies [Weeks.TravelDays, boolean]),
        R.fromEntries,
      );
    }

    return pipe(
      Object.values(Weeks.TravelDays),
      A.map(day => {
        const travelDay = week.travelDays[day];

        const hasTravel = (direction: Weeks.TravelDirection) =>
          pipe(
            travelDay.travels,
            A.some(travel => travel.direction === direction),
          );

        const hasOutwards = travelDay.type !== Weeks.TravelType.Office || hasTravel(Weeks.TravelDirection.Outward);
        const hasReturns =
          travelDay.type !== Weeks.TravelType.Office ||
          travelDay.isReturnTravelIdentical ||
          hasTravel(Weeks.TravelDirection.Return);

        return [day, !hasOutwards || !hasReturns] satisfies [Weeks.TravelDays, boolean];
      }),
      R.fromEntries,
    );
  }, [week.travelDays, withErrors]);

  const hasErrors = useMemo(() => pipe(errors, R.some(identity)), [errors]);

  return (
    <>
      <Stack spacing={8}>
        {Object.values(Weeks.TravelDays).map(day => (
          <WeekDay key={day} day={week.travelDays[day]} error={errors[day]} readonly={readonly} />
        ))}
      </Stack>

      {hasErrors ? (
        <Text c="red" mt="lg" fw={700} fz={12} align="center">
          Oups ! Tu as oublié de renseigner des trajets
        </Text>
      ) : null}
    </>
  );
};

export default WeekDayList;
