import React, { FC } from 'react';
import { To } from 'react-router-dom';
import { Stack } from '@mantine/core';
import { MIconArrowRight } from '@styles/icons';

import ProfileListItem from '@modules/profile/components/list/ProfileListItem';

const items: Array<[To, string]> = [
  ['update', 'Mes informations personnelles'],
  ['addresses', 'Mes adresses'],
  ['weeks', 'Ma semaine type de trajets'],
  ['travels', 'Mes trajets favoris'],
  ['vehicles', 'Mes véhicules motorisés'],
];

const ProfileMenuContent: FC = () => {
  return (
    <Stack px={32} spacing={13}>
      {items.map(([to, title], index) => (
        <ProfileListItem key={index} title={title} to={to} icon={<MIconArrowRight />} iconColor="orange.7" />
      ))}
    </Stack>
  );
};

export default ProfileMenuContent;
