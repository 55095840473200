import React, { FC, ReactNode } from 'react';

import * as Styled from './NavLayout.styles';
import { NavLink, Outlet, To } from 'react-router-dom';
import { MIconActions, MIconHappyFace, MIconHome, MIconImpact } from '@styles/icons';
import { Profile } from '@modules/profile/model';
import { useProfile } from '@modules/profile/profile-loader';
import ProfileStugyGroup = Profile.ProfileStugyGroup;

export { NAV_HEIGHT } from './NavLayout.styles';

interface NavItem {
  title: string;
  icon: ReactNode;
  to: To;
  canAccess?: (profile: Profile.Profile) => boolean;
}

const items: Array<NavItem> = [
  {
    title: 'Accueil',
    icon: <MIconHome width={29} height={29} />,
    to: '/',
  },
  {
    title: 'Mon impact',
    icon: <MIconImpact width={29} height={29} />,
    to: '/impact',
    canAccess: profile => profile.studyGroup !== ProfileStugyGroup.A,
  },
  {
    title: 'Mes actions',
    icon: <MIconActions width={29} height={29} />,
    to: '/actions',
    canAccess: profile => profile.studyGroup !== ProfileStugyGroup.A,
  },
  {
    title: 'Mon profil',
    icon: <MIconHappyFace width={29} height={29} />,
    to: '/profile',
  },
];

const NavLayout: FC = () => {
  const profile = useProfile();

  const entries = items.filter(item => item.canAccess === undefined || item.canAccess(profile));

  return (
    <>
      <Outlet />

      <Styled.NavContainer>
        <Styled.NavContent length={entries.length}>
          {entries.map((item, index) => (
            <Styled.NavItem key={index}>
              <NavLink to={item.to}>
                {item.icon}
                <span>{item.title}</span>
              </NavLink>
            </Styled.NavItem>
          ))}
        </Styled.NavContent>
      </Styled.NavContainer>
    </>
  );
};

export default NavLayout;
