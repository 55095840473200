import React, { FC, useMemo, useState } from 'react';
import { Weeks } from '@modules/weeks/model';
import { Carousel, Embla } from '@mantine/carousel';
import DayTravels from '@modules/weeks/components/update/day/DayTravels';
import { ProfileAddress } from '@modules/profile/address/model';
import { HttpResult } from '@core/http';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEnumQuery } from '@shared/modules/filter';
import { parseQueries } from '@shared/utils/queries';
import { WeeksUtils } from '@modules/weeks/utils';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileTravels } from '@modules/profile/travels/model';
import { Box } from '@mantine/core';

interface WeekTravelsProps {
  week: Weeks.TravelWeek;
  addresses?: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels?: Array<ProfileTravels.ProfileTravel>;
  loading?: boolean;
  readonly?: boolean;
  onSubmit?: (
    params: Weeks.UpdateTravelDayParams,
    travelToSave: ProfileTravels.ProfileTravelParams | null,
  ) => Promise<HttpResult>;
}

const WeekTravels: FC<WeekTravelsProps> = ({
  week,
  addresses = [],
  vehicles,
  travels = [],
  loading = false,
  readonly = false,
  onSubmit = TE.of(true),
}) => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const [embla, setEmbla] = useState<Embla | null>(null);

  const initialSlide = useMemo(
    () =>
      pipe(
        O.fromNullable(getEnumQuery(parseQueries(search), Weeks.TravelDays, 'day')),
        O.fold(
          () => 0,
          day => WeeksUtils.getDaySliderIndex(week, day),
        ),
      ),
    [search, week],
  );

  const handleSubmit = (
    params: Weeks.UpdateTravelDayParams,
    direction: 'prev' | 'next',
    travelToSave: ProfileTravels.ProfileTravelParams | null,
  ) =>
    pipe(
      () => onSubmit(params, travelToSave),
      TE.chainIOK(() => () => {
        setTimeout(() => {
          embla?.reInit();

          if (direction === 'prev') {
            embla?.scrollPrev();
          } else if (embla?.canScrollNext()) {
            embla?.scrollNext();
          } else {
            navigate(-1);
          }
        }, 100);
      }),
    )();

  return (
    <Box mb="xl">
      <Carousel
        slideGap={15}
        initialSlide={initialSlide}
        slideSize="calc(100% - (4 * 15px))"
        align="center"
        withControls={false}
        draggable={readonly ?? false}
        withKeyboardEvents={readonly ?? false}
        getEmblaApi={setEmbla}
      >
        {Object.values(Weeks.TravelDays).map(day => (
          <DayTravels
            key={day}
            day={week.travelDays[day]}
            addresses={addresses}
            vehicles={vehicles}
            travels={travels}
            loading={loading}
            readonly={readonly}
            onSubmit={handleSubmit}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default WeekTravels;
