import { httpService, HttpTask } from '@core/http';
import { Passwords } from '@modules/passwords/model';

export namespace PasswordsService {
  export function forgotPassword(params: Passwords.ForgotPasswordParams): HttpTask {
    return httpService.post('/password-reset/forgot', params);
  }

  export function getPasswordResetDetail(
    token: Passwords.ResetPasswordToken,
  ): HttpTask<Passwords.ForgotPasswordParams> {
    return httpService.get(`/password-reset/${token}`);
  }

  export function resetPassword(token: Passwords.ResetPasswordToken, params: Passwords.ResetPasswordParams): HttpTask {
    return httpService.post(`/password-reset/${token}`, params);
  }
}
