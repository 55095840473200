import BackgroundLayout from '@layout/background/BackgroundLayout';
import { createRoute } from '@core/router';
import { Route } from 'react-router-dom';
import actionsPage from '@modules/actions/ActionsPage';

const actionsRoutes = (
  <Route path="actions" element={<BackgroundLayout color="violet" />}>
    <Route index {...createRoute(actionsPage)} />
  </Route>
);

export default actionsRoutes;
