import React, { FC, ReactNode } from 'react';
import * as Styled from './Card.styles';
import { Text } from '@mantine/core';
import { MIconArrowRight } from '@styles/icons';

interface CardProps {
  image?: ReactNode;
  description: string;
  link: string;
}

const Card: FC<CardProps> = ({ image, description, link }) => {
  return (
    <Styled.CardContainer component="a" target="blank" rel="noopener noreferrer" href={link} p="25px 16px" bg="white">
      <Styled.CardContent>
        {image != null ? <Styled.CardImage>{image}</Styled.CardImage> : null}

        <Text pt={12} weight={400} size={12}>
          {description}
        </Text>
      </Styled.CardContent>

      <Styled.CardMore>
        <Text size={14} weight={600} underline pr={5}>
          En savoir plus
        </Text>

        <MIconArrowRight width={14} />
      </Styled.CardMore>
    </Styled.CardContainer>
  );
};

export default Card;
