import { Route } from 'react-router-dom';
import BackgroundLayout from '@layout/background/BackgroundLayout';
import { createRoute } from '@core/router';
import weekListRoute from '@modules/weeks/ListPage';
import weekDetailRoute from '@modules/weeks/DetailPage';

const weeksRoutes = (
  <Route path="weeks" element={<BackgroundLayout color="orange" />}>
    <Route index {...createRoute(weekListRoute)} />
    <Route path="update" {...createRoute(weekDetailRoute)} />
  </Route>
);

export default weeksRoutes;
