import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ProfileTravelsService } from '@modules/profile/travels/service';
import PageTitle from '@layout/title/PageTitle';
import { Box, Group, Stack } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import TravelsPlaceholder from '@modules/profile/travels/components/list/placeholder/TravelsPlaceholder';
import { MIconEdit } from '@styles/icons';
import ProfileListItem from '@modules/profile/components/list/ProfileListItem';
import { CreateButton } from '@styles/shared';
import { Link } from 'react-router-dom';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(ProfileTravelsService.getProfileTravels()),
});

const ProfileTravelsListPage: FC = () => {
  const travels = useLoader<typeof loader>();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Mes trajets favoris" />

      {renderOptional(
        NEA.fromArray(travels),
        travels => (
          <>
            <Stack spacing={8} mt={33}>
              {travels.map(travel => (
                <ProfileListItem
                  key={travel.id}
                  title={travel.name}
                  to={travel.id}
                  icon={<MIconEdit />}
                  iconColor="violet.6"
                />
              ))}
            </Stack>

            <Group position="center" pt="lg">
              <CreateButton component={Link} to="new">
                Ajouter un trajet
              </CreateButton>
            </Group>
          </>
        ),
        () => (
          <TravelsPlaceholder />
        ),
      )}
    </Box>
  );
};

const profileTravelsListRoute = defineRoute({
  component: ProfileTravelsListPage,
  loader,
});

export default profileTravelsListRoute;
