import React, { FC } from 'react';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { NavigationProgress } from '@mantine/nprogress';
import { RouterProvider } from 'react-router-dom';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

import '@innovantic/cookie-consent/dist/style.css';
import CookieConsent from '@innovantic/cookie-consent';
import { Analytics } from '@shared/modules/analytics/model';

import Modal from 'react-modal';

Modal.setAppElement('#root');

const App: FC = () => {
  return (
    <CookieConsent
      choices={Analytics.consentChoices}
      privacyLink={Analytics.PRIVACY_LINK}
      text={Analytics.CONSENT_TEXT}
    >
      <SentryProvider>
        <MantineTheme>
          <GlobalStyles />

          <ApiDownIndicator />

          <NavigationProgress color="violet" size={2} />

          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </MantineTheme>
      </SentryProvider>
    </CookieConsent>
  );
};

export default App;
