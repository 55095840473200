import React, { FC } from 'react';
import { defineRoute, useBack } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Box } from '@mantine/core';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ProfileAddressService } from '@modules/profile/address/service';
import { defineAction, useAction } from '@core/router/action';
import { ProfileTravels } from '@modules/profile/travels/model';
import { ProfileTravelsService } from '@modules/profile/travels/service';
import TravelForm from '@modules/profile/travels/components/form/TravelForm';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';

const loader = defineLoader({
  handler: () =>
    pipe(
      sequenceS(TE.ApplyPar)({
        addresses: ProfileAddressService.getProfileAddresses(),
        vehicles: ProfileVehiclesService.getProfileVehicles(),
      }),
      httpTaskToResponseTask,
    ),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: ProfileTravels.ProfileTravelParams,
    handler: ({ payload }) => ProfileTravelsService.createProfileTravel(payload),
  }),
};

const CreateProfileTravelPage: FC = () => {
  const { addresses, vehicles } = useLoader<typeof loader>();

  const goBack = useBack();

  const [loading, create] = useAction(actions.create);

  const handleSubmit = (params: ProfileTravels.ProfileTravelParams) =>
    pipe(
      () => create(params),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Ajouter un nouveau trajet" />

      <TravelForm addresses={addresses} vehicles={vehicles} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const createProfileTravelRoute = defineRoute({
  component: CreateProfileTravelPage,
  loader,
  actions,
});

export default createProfileTravelRoute;
