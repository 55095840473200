import React, { FC, useState } from 'react';
import * as O from 'fp-ts/Option';
import { Anchor, Box, Button, Center, createPolymorphicComponent, Group, Paper, Text, TextProps } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import { defineRoute } from '@core/router';
import ImpactInfos from '@modules/impact/components/info/ImpactInfos';
import { ImpactType } from '@modules/impact/model';
import { Link } from 'react-router-dom';
import { MIconArrowRight } from '@styles/icons';
import ImpactTabs from '@shared/components/impact/tabs/ImpactTabs';
import { useParentLoader } from '@core/router/loader';
import { impactLoader } from '@modules/impact/routes';
import ImpactPlaceholder from '@modules/impact/components/placeholder/ImpactPlaceholder';
import { DateFormat, formatDate, parseDate } from '@shared/modules/dates';
import { pipe } from 'fp-ts/function';
import styled from '@emotion/styled';

const ImpactPageDate = createPolymorphicComponent<'p', TextProps>(styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
`);

const ImpactPage: FC = () => {
  const { impact, home } = useParentLoader<typeof impactLoader>('impact');

  const [value, setValue] = useState<ImpactType>(ImpactType.Nox);

  const startDate = pipe(
    parseDate(impact.startDate, DateFormat.LocalDate),
    O.fold(
      () => null,
      date => formatDate(date, 'eeee dd MMMM'),
    ),
  );

  const endDate = pipe(
    parseDate(impact.endDate, DateFormat.LocalDate),
    O.fold(
      () => null,
      date => formatDate(date, 'eeee dd MMMM'),
    ),
  );

  return (
    <Box p={30} pb={100} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Mon impact" />

      {home.stepProgressionPercentage === 1 || (home.currentWeekNumber !== 0 && home.isWeekClosed) ? (
        <>
          {startDate && endDate ? (
            home.currentWeekNumber === 0 ? (
              <Text size={12}>Semaine type</Text>
            ) : (
              <Group spacing={3} pt={6}>
                <Text size={12}>S{home.currentWeekNumber} -</Text>

                <ImpactPageDate size={12}>{startDate} -</ImpactPageDate>

                <ImpactPageDate size={12}>{endDate}</ImpactPageDate>
              </Group>
            )
          ) : null}

          <ImpactTabs
            value={value}
            setValue={setValue}
            data={[
              { label: 'NOx', value: ImpactType.Nox },
              { label: 'Particules', value: ImpactType.PM },
              { label: 'CO2', value: ImpactType.CO2 },
            ]}
          />

          <ImpactInfos
            infos={impact[`${value}`]}
            value={value}
            currentWeek={home.currentWeekNumber}
            isGroupImpactAvailable={home.isGroupImpactAvailable}
          />

          <Center pt={30}>
            <Anchor component={Link} to={`/impact/detail?type=${value}`}>
              Voir le détail des émissions
            </Anchor>
          </Center>

          {home.currentWeekNumber !== 0 ? (
            <Button component={Link} to="/" w="100%" color="orange" rightIcon={<MIconArrowRight />} mt={20}>
              Je passe à l'action
            </Button>
          ) : (
            <Paper component={Link} to="/operation" bg="violet" p={15} mt={30}>
              <Text size={16} weight={700} color="white">
                Objectif réduction des émissions
              </Text>

              <Text py={8} size={12} color="white">
                La qualité de l’air est un enjeu majeur pour la santé et l'environnement. En France, la sur la période
                2016-2019, Santé publique France estime que la pollution atmosphérique est responsable chaque année de
                près de 40 000 décès prématurés. Cela nécessite des actions ambitieuses, au niveau international comme
                au niveau local, dans tous les secteurs d’activité. Et dans cette entreprise, chacun est le collectif !
              </Text>

              <Text size={14} weight={600} color="white" underline>
                En savoir plus
              </Text>
            </Paper>
          )}
        </>
      ) : (
        <ImpactPlaceholder />
      )}
    </Box>
  );
};

const impactPage = defineRoute({
  component: ImpactPage,
  loader: undefined,
});

export default impactPage;
