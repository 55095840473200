import { ProfileAddress } from '@modules/profile/address/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { Address } from '@shared/modules/address/model';

export namespace ProfileAddressUtils {
  export function getProfileAddressByType(addresses: Array<ProfileAddress.ProfileAddress>, type: Address.AddressType) {
    return pipe(
      addresses,
      A.findFirst(address => address.type === type)
    )
  }

  export function profileAddressToBody(address: ProfileAddress.ProfileAddress): ProfileAddress.ProfileAddressBody {
    return {
      type: address.type,
      name: address.name,
      address: {
        label: address.label,
        placeId: address.placeId
      }
    }
  }

  export function profileAddressBodyToParams(address: ProfileAddress.ProfileAddressBody): ProfileAddress.ProfileAddressParams {
    return {
      type: address.type,
      name: address.name,
      label: address.address.label,
      placeId: address.address.placeId
    }
  }

  export function profileAddressToAddress(address: ProfileAddress.ProfileAddress): Address.Address {
    const addressName = address.type === Address.AddressType.Custom ? address.name : Address.addressTypeName[address.type];

    return {
      label: `${addressName}, ${address.label}`,
      placeId: address.placeId
    }
  }
}