import React, { FC } from 'react';
import { Box } from '@mantine/core';
import { HomeModel } from '@modules/home/model';
import HomeInfos from '@modules/home/components/content/infos/HomeInfos';
import HomeActions from '@modules/home/components/content/actions/HomeActions';
import { Profile } from '@modules/profile/model';
import ProfileStugyGroup = Profile.ProfileStugyGroup;
import HomePlaceholder from '@modules/home/components/content/placeholder/HomePlaceholder';

interface LinkActions {
  linkActions: string | null;
  linkActionsLabel: string | null;
}

function getLinkActions(home: HomeModel): LinkActions {
  if (!home.hasHomeAddress) {
    return {
      linkActions: '/profile/addresses/home',
      linkActionsLabel: 'Je renseigne mon adresse',
    };
  } else if (!home.isSurveyAnswered) {
    return {
      linkActions: '/survey/start',
      linkActionsLabel: "Je réponds à l'enquête d'accueil",
    };
  } else if (!home.isWeekClosed) {
    return {
      linkActions: '/weeks',
      linkActionsLabel: 'Je renseigne ma semaine type',
    };
  } else {
    return {
      linkActions: null,
      linkActionsLabel: null,
    };
  }
}

interface HomeContentProps {
  home: HomeModel;
  profile: Profile.Profile;
}

const HomeContent: FC<HomeContentProps> = ({ home, profile }) => {
  const { linkActions, linkActionsLabel } = getLinkActions(home);
  return profile.studyGroup === ProfileStugyGroup.A ? (
    <HomePlaceholder home={home} />
  ) : (
    <Box p="0 30px 45px" m="0 auto" sx={{ width: '100%', maxWidth: 700 }}>
      <HomeActions currentActions={home.currentActions} linkActions={linkActions} linkActionsLabel={linkActionsLabel} />
      <HomeInfos currentInformations={home.currentInformations} />
    </Box>
  );
};

export default HomeContent;
