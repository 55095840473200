import React, { FC } from 'react';
import { HomeModel } from '@modules/home/model';
import { Text, Box } from '@mantine/core';
import { MIconHomePlaceholder } from '@styles/icons';
import styled from '@emotion/styled';

const HomePlaceholderImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface HomePlaceholderProps {
  home: HomeModel;
}

const HomePlaceholder: FC<HomePlaceholderProps> = ({ home }) => {
  return home.stepProgressionPercentage === 1 && home.currentWeekNumber !== 10 ? (
    <Box p="45px 30px 50px" m="0 auto" sx={{ maxWidth: 700 }}>
      <Text size={16} weight={700} color="violet">
        C'est tout pour l'instant
      </Text>

      <Text size={12} color="violet" pt={8}>
        Merci d’avoir renseigné les informations sur tes déplacements sur une semaine type. Tu n’as plus rien à remplir
        pour l’instant. On te recontacte à la fin de l’expérimentation pour la deuxième partie. Tous ensemble pour un
        air plus doux.
      </Text>

      <HomePlaceholderImgContainer>
        <MIconHomePlaceholder />
      </HomePlaceholderImgContainer>
    </Box>
  ) : null;
};

export default HomePlaceholder;
