import React, { FC } from 'react';
import { Profile } from '@modules/profile/model';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Group, TextInput } from '@mantine/core';

interface UpdateProfileFormProps {
  profile: Profile.Profile;
  loading: boolean;
  onSubmit: (params: Profile.UpdateProfileParams) => void;
}

const UpdateProfileForm: FC<UpdateProfileFormProps> = ({ profile, loading, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Profile.UpdateProfileParams>({
    resolver: zodResolver(Profile.UpdateProfileParams),
    defaultValues: {
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
    },
  });

  return (
    <Box mt={33}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextInput id="email" label="E-mail" value={profile.email} disabled />
        <TextInput mt="sm" id="firstName" label="Prénom" error={!!errors.firstName} {...register('firstName')} />
        <TextInput mt="sm" id="lastName" label="Nom" error={!!errors.lastName} {...register('lastName')} />

        <Group grow mt="lg">
          <Button type="submit" loading={loading} disabled={loading}>
            Valider
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default UpdateProfileForm;
