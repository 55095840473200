import React, { FC } from 'react';
import { ActionIcon, Stack, Title } from '@mantine/core';
import Seo from '@shared/modules/seo/Seo';
import { MIconArrowLeft } from '@styles/icons';
import { useBack } from '@core/router';

interface PageTitleProps {
  title: string;
  inverted?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({ title, inverted }) => {
  const goBack = useBack();

  const color = inverted ? 'white' : 'black';

  return (
    <Stack spacing={5}>
      <ActionIcon variant="transparent" onClick={goBack}>
        <MIconArrowLeft width={24} color={color} />
      </ActionIcon>

      <Title color={color}>{title}</Title>

      <Seo title={title} />
    </Stack>
  );
};

export default PageTitle;
