import { FC, useEffect } from 'react';

import ReactGA from 'react-ga4';
import { useCookieConsentChoice } from '@innovantic/cookie-consent';
import { Analytics } from '@shared/modules/analytics/model';
import config from '@root/config';
import { useLocation } from 'react-router-dom';

const AnalyticsTracker: FC = () => {
  const location = useLocation();

  const isStatisticAllowed = useCookieConsentChoice(Analytics.ConsentChoice.Statistic);

  useEffect(() => {
    if (isStatisticAllowed && config.VITE_GA_KEY) {
      if (!ReactGA.isInitialized) {
        ReactGA.initialize(config.VITE_GA_KEY);
        ReactGA.send('pageview');
      }
    } else if (ReactGA.isInitialized) {
      window.location.reload();
    }
  }, [isStatisticAllowed]);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send('pageview');
    }
  }, [location.pathname]);

  return null;
};

export default AnalyticsTracker;
