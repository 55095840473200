import { getStringQuery } from '@shared/modules/filter';
import { parseQueriesFormUrl, stringifyQueries } from '@shared/utils/queries';

export namespace AuthUtils {
  export const REFERRER_QUERY = 'referrer';

  export function getNotAuthenticatedRedirection(currentUrl: string) {
    return `/home?${stringifyQueries({ [REFERRER_QUERY]: new URL(currentUrl).pathname })}`;
  }

  export function getAuthenticatedRedirection(currentUrl: string) {
    return getStringQuery(parseQueriesFormUrl(currentUrl), REFERRER_QUERY) ?? '/';
  }
}
