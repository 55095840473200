import React, { FC } from 'react';
import * as Styled from './HomeHeader.styles';
import { HomeModel, HomeStep, HomeWeekStatus } from '@modules/home/model';
import { Profile } from '@modules/profile/model';
import { Paper, Progress, Stack, Text } from '@mantine/core';
import HomeHeaderInfos from '@modules/home/components/header/infos/HomeHeaderInfos';
import { MIconHomeEnd, MIconLike } from '@styles/icons';
import HomeHeaderAdd from '@modules/home/components/header/add/HomeHeaderAdd';
import HomeHeaderCompleted from '@modules/home/components/header/completed/HomeHeaderCompleted';
import HomeHeaderWeeks from '@modules/home/components/header/weeks/HomeHeaderWeeks';
import HomeHeaderProfile from '@modules/home/components/header/profile/HomeHeaderProfile';
import HomeHeaderCard from '@modules/home/components/header/card/HomeHeaderCard';
import { Link } from 'react-router-dom';

function getSteps(infos: HomeModel) {
  return {
    step1: {
      hasHomeAddress: infos.hasHomeAddress,
      isSurveyAnswered: infos.isSurveyAnswered,
      isWeekClosed: infos.isWeekClosed,
    },
    step2: {
      carStep: infos.carStep,
      twoWheelersStep: infos.twoWheelersStep,
    },
  };
}

interface HomeHeaderProps {
  infos: HomeModel;
  profile: Profile.Profile;
}

const HomeHeader: FC<HomeHeaderProps> = ({ infos, profile }) => {
  const { step1, step2 } = getSteps(infos);

  const step1IsCompleted = step1.hasHomeAddress && step1.isSurveyAnswered && step1.isWeekClosed;

  const step2IsCompleted =
    (step2.carStep === HomeStep.Done || step2.carStep === HomeStep.NotNeeded) &&
    (step2.twoWheelersStep === HomeStep.Done || step2.twoWheelersStep === HomeStep.NotNeeded);

  const progressPercent = infos.stepProgressionPercentage * 100;

  switch (infos.currentWeekNumber) {
    case 0:
      return (
        <Styled.HomeHeaderContainer>
          <Styled.HomeHeaderContent>
            <HomeHeaderProfile profile={profile} />

            {!(step1IsCompleted && step2IsCompleted) ? (
              <>
                {step1IsCompleted ? (
                  <Text size={16} pt={20}>
                    Super ! Tu as dit que tu utilisais
                    {step2.twoWheelersStep !== HomeStep.NotNeeded ? ' un 2 roues motorisé' : null}
                    {step2.twoWheelersStep !== HomeStep.NotNeeded && step2.carStep !== HomeStep.NotNeeded
                      ? ' et'
                      : null}
                    {step2.carStep !== HomeStep.NotNeeded ? ' une voiture' : null}
                    &nbsp;: il ne nous manque plus que cette info pour te donner ton diagnostic.
                  </Text>
                ) : (
                  <Text size={16} pt={20}>
                    Et si on posait les bases avant d'enlever les petites roues ?
                  </Text>
                )}

                <Progress value={progressPercent} mt={20} color="violet" />

                <Styled.HomeHeaderCompleted>
                  <Text size={12} pr={5}>
                    {progressPercent}% complété
                  </Text>

                  <MIconLike />
                </Styled.HomeHeaderCompleted>
              </>
            ) : null}

            {step1IsCompleted ? (
              step2IsCompleted ? (
                <HomeHeaderCompleted step1={step1} step2={step2} studyGroup={profile.studyGroup} />
              ) : (
                <HomeHeaderAdd step1={step1} step={step2} />
              )
            ) : (
              <HomeHeaderInfos step={step1} />
            )}
          </Styled.HomeHeaderContent>
        </Styled.HomeHeaderContainer>
      );

    case 10:
      return (
        <Styled.HomeHeaderContainer>
          <Styled.HomeHeaderContent>
            <HomeHeaderProfile profile={profile} />

            {infos.weekStatus === HomeWeekStatus.Validated || infos.isWeekClosed ? (
              infos.isEndSurveyAnswered ? (
                <Paper component={Link} to="operation" bg="violet" withBorder={false} p={15} mt={30}>
                  <Text size={16} lh="22px" weight={700} color="white">
                    C'est fini !
                  </Text>

                  <Text size={12} color="white" pt={8}>
                    Merci d'avoir participé au défi, au quotidien et avec ton équipe ! Tous ensemble pour un air plus
                    doux.
                  </Text>

                  <Text underline size={14} weight={600} color="white" pt={8}>
                    En savoir plus
                  </Text>

                  <Styled.HomeHeaderEndImg>
                    <MIconHomeEnd />
                  </Styled.HomeHeaderEndImg>
                </Paper>
              ) : (
                <Stack pt={20}>
                  <Text size={16}>La campagne de test est terminée merci de répondre à la dernière enquête.</Text>

                  <HomeHeaderCard
                    isCompleted={false}
                    description="Je réponds à l'enquête de fin"
                    time="<7 minutes"
                    link="/survey/end"
                  />
                </Stack>
              )
            ) : (
              <HomeHeaderWeeks infos={infos} studyGroup={profile.studyGroup} />
            )}
          </Styled.HomeHeaderContent>
        </Styled.HomeHeaderContainer>
      );

    default:
      return (
        <Styled.HomeHeaderContainer>
          <Styled.HomeHeaderContent>
            <HomeHeaderProfile profile={profile} />

            {!infos.isUserSuspended ? <HomeHeaderWeeks infos={infos} studyGroup={profile.studyGroup} /> : null}
          </Styled.HomeHeaderContent>
        </Styled.HomeHeaderContainer>
      );
  }
};

export default HomeHeader;
