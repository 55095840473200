import { Route } from 'react-router-dom';
import BackgroundLayout from '@layout/background/BackgroundLayout';
import { createRoute } from '@core/router';
import projectPage from '@modules/project/ProjectPage';

const projectRoutes = (
  <Route path="project" element={<BackgroundLayout color="violet" />}>
    <Route index {...createRoute(projectPage)} />
  </Route>
);

export default projectRoutes;
