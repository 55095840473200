import React, { FC, Fragment, useMemo } from 'react';
import { Control, useController, useFieldArray, useFormState } from 'react-hook-form';
import { Weeks } from '@modules/weeks/model';

import * as Styled from './DayTravel.styles';
import { ActionIcon, Badge, Box, Group, Text, ThemeIcon } from '@mantine/core';
import { MIconClose, MIconTransportHome, MIconTransportOffice } from '@styles/icons';
import AddressSelector from '@shared/modules/address/components/AddressSelector';
import TransportSelection from '@modules/weeks/components/update/day/transports/TransportSelection';
import { AddressUtils } from '@shared/modules/address/utils';
import { ProfileAddress } from '@modules/profile/address/model';
import { ProfileAddressUtils } from '@modules/profile/address/utils';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileVehiclesUtils } from '@modules/profile/vehicules/utils';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { ProfileTravels } from '@modules/profile/travels/model';
import { ProfileTravelsUtils } from '@modules/profile/travels/utils';
import { Address } from '@shared/modules/address/model';

interface DayTravelTransportBadgeProps {
  type: Weeks.TransportType;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  participantVehicleId?: ProfileVehicles.ProfileVehicleId | null;
  readonly: boolean;
  onDelete: () => void;
}

const DayTravelTransportBadge: FC<DayTravelTransportBadgeProps> = ({
  type,
  vehicles,
  participantVehicleId,
  onDelete,
  readonly,
}) => {
  const Icon = Weeks.transportTypeIcon[type];

  const label = useMemo(
    () =>
      pipe(
        O.fromNullable(participantVehicleId),
        O.chain(vehicleId =>
          pipe(
            vehicles,
            A.findFirst(vehicle => vehicle.id === vehicleId),
          ),
        ),
        O.fold(
          () => Weeks.transportTypeLabel[type],
          vehicle => vehicle.name,
        ),
      ),
    [participantVehicleId, type, vehicles],
  );

  return (
    <Badge
      mt={8}
      py={5}
      px={readonly ? 7 : 5}
      sx={{ position: 'relative', height: 25 }}
      rightSection={
        !readonly ? (
          <ActionIcon size="xs" color="blue" radius="xl" variant="transparent" onClick={onDelete}>
            <MIconClose width={20} height={20} color="#363636" />
          </ActionIcon>
        ) : undefined
      }
    >
      <Group noWrap spacing={3} align="center">
        <Icon />
        <Text size="xs" tt="none" c="black" fw="400">
          {label}
        </Text>
      </Group>
    </Badge>
  );
};

interface DayTravelProps {
  direction: Weeks.TravelDirection;
  control: Control<Weeks.UpdateTravelBody>;
  addresses: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels?: Array<ProfileTravels.ProfileTravel>;
  readonly?: boolean;
}

const DayTravel: FC<DayTravelProps> = ({
  direction,
  control,
  addresses,
  vehicles = [],
  travels = [],
  readonly = false,
}) => {
  const { fields, update, append, remove, replace } = useFieldArray({ control, name: 'steps', keyName: '_id' });

  const destinationController = useController({ control, name: 'destination' });

  const { errors } = useFormState({ control });

  const updateFirstStepVehicleType = (vehicleType: Weeks.TransportType) =>
    update(0, {
      ...fields[0],
      vehicleType: vehicleType,
      participantVehicleId: null,
    });

  const updateFirstStepVehicle = (vehicle: ProfileVehicles.ProfileVehicle) =>
    update(0, {
      ...fields[0],
      vehicleType: ProfileVehiclesUtils.getTransportTypeFromVehicle(vehicle),
      participantVehicleId: vehicle.id,
    });

  const handleCreateStep = (vehicleType: Weeks.TransportType) =>
    append({
      address: AddressUtils.getEmptyAddress(),
      vehicleType,
    });

  const handleCreateStepWithVehicle = (vehicle: ProfileVehicles.ProfileVehicle) =>
    append({
      address: AddressUtils.getEmptyAddress(),
      vehicleType: ProfileVehiclesUtils.getTransportTypeFromVehicle(vehicle),
      participantVehicleId: vehicle.id,
    });

  const handleDeleteVehicleType = (index: number) => () => {
    if (index === 0) {
      update(index, { ...fields[0], vehicleType: '' as any, participantVehicleId: undefined });
    } else {
      remove(index);
    }
  };

  const handleUpdateStepAddress = (index: number) => (address: Address.Address) =>
    update(index, {
      ...fields[index],
      address,
    });

  const handleSelectTravel = (travel: ProfileTravels.ProfileTravel) => {
    const { steps, destination } = ProfileTravelsUtils.profileTravelToUpdateTravelBody(travel.travels, addresses);

    replace(
      steps.map(step => ({
        ...step,
        id: null,
      })),
    );
    destinationController.field.onChange(destination);
  };

  const favoritesAddress = addresses.map(ProfileAddressUtils.profileAddressToAddress);

  return (
    <>
      <Styled.DayTravelContent mt={8}>
        <ThemeIcon variant="light" radius="xl" size={30} sx={{ position: 'relative' }}>
          {direction === Weeks.TravelDirection.Outward ? <MIconTransportHome /> : <MIconTransportOffice />}
        </ThemeIcon>

        {fields.map((field, index) => (
          <Fragment key={field._id}>
            <Box mt={8} sx={{ width: '100%' }}>
              <AddressSelector
                icon="edit"
                value={field.address}
                onChange={handleUpdateStepAddress(index)}
                favorites={favoritesAddress}
                error={!!(errors?.steps && errors.steps[index]?.address)}
                disabled={readonly}
              />
            </Box>

            {index === 0 && !field.vehicleType && !readonly ? (
              <Box mt={8} sx={{ width: '100%' }}>
                <TransportSelection
                  label="Définir le type de transport"
                  vehicles={vehicles}
                  travels={travels}
                  onChange={updateFirstStepVehicleType}
                  onSelectVehicle={updateFirstStepVehicle}
                  onSelectTravel={handleSelectTravel}
                />
              </Box>
            ) : null}

            {field.vehicleType ? (
              <DayTravelTransportBadge
                type={field.vehicleType}
                vehicles={vehicles}
                participantVehicleId={field.participantVehicleId}
                readonly={readonly}
                onDelete={handleDeleteVehicleType(index)}
              />
            ) : null}

            {field.vehicleType && index === fields.length - 1 && !readonly ? (
              <Box mt={8} sx={{ width: '100%' }}>
                <TransportSelection
                  label="Ajouter un trajet"
                  vehicles={vehicles}
                  travels={travels}
                  onChange={handleCreateStep}
                  onSelectVehicle={handleCreateStepWithVehicle}
                  onSelectTravel={handleSelectTravel}
                />
              </Box>
            ) : null}
          </Fragment>
        ))}

        <Box mt={8} sx={{ width: '100%' }}>
          <AddressSelector
            icon="edit"
            favorites={favoritesAddress}
            {...destinationController.field}
            error={!!errors.destination}
            disabled={readonly}
          />
        </Box>

        <ThemeIcon mt={8} variant="light" radius="xl" size={30} sx={{ position: 'relative' }}>
          {direction === Weeks.TravelDirection.Outward ? <MIconTransportOffice /> : <MIconTransportHome />}
        </ThemeIcon>
      </Styled.DayTravelContent>

      {!!(errors.steps && errors.steps[0]?.vehicleType) ? (
        <Text mt="sm" c="red" fw="700" fz={12} align="center">
          Veuillez sélectionner le type de transport
        </Text>
      ) : null}
    </>
  );
};

export default DayTravel;
