import {
  Anchor,
  AnchorProps,
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  createPolymorphicComponent,
} from '@mantine/core';
import React, { FC, forwardRef } from 'react';
import { MIconAdd } from '@styles/icons';
import { Link, To } from 'react-router-dom';
import { IconDeviceFloppy, IconPlus, IconTrash, IconWriting } from '@tabler/icons-react';
import { PolymorphicComponentProps } from '@mantine/utils';

export type ButtonType = 'create' | 'save' | 'cancel' | 'delete' | 'modify';

const buttonPropsByType: Record<ButtonType, MantineButtonProps> = {
  create: {
    color: 'green',
    children: 'Créer',
    leftIcon: <IconPlus />,
  },
  save: {
    color: 'green',
    children: 'Enregistrer',
    leftIcon: <IconDeviceFloppy />,
  },
  modify: {
    color: 'green',
    children: 'Modifier',
    leftIcon: <IconWriting />,
  },
  cancel: {
    children: 'Annuler',
    color: 'gray',
    variant: 'light',
    style: { color: '#696969' },
  },
  delete: {
    color: 'red',
    children: 'Supprimer',
    leftIcon: <IconTrash />,
    variant: 'light',
  },
};

export interface ButtonProps extends MantineButtonProps {
  btnType?: ButtonType;
  show?: boolean;
}

export function SharedButton<C = 'button'>({
  btnType,
  show,
  disabled,
  loading,
  className,
  ...buttonProps
}: PolymorphicComponentProps<C, ButtonProps>) {
  if (show === false) {
    return null;
  }

  const buttonTypeProps = btnType ? buttonPropsByType[btnType] : {};

  const buttonDisabled = loading || disabled;

  return (
    <MantineButton
      className={`${className ?? ''} ${btnType ?? ''}`}
      {...buttonTypeProps}
      {...buttonProps}
      disabled={buttonDisabled}
      loading={loading}
    />
  );
}

export interface SharedButtonLinkProps extends ButtonProps {
  to: To;
}

export const SharedButtonLink: FC<SharedButtonLinkProps> = ({ to, btnType, ...buttonProps }) => {
  const linkProps =
    btnType === 'cancel'
      ? {
          pathname: to,
          state: {
            ignorePrevent: true,
          },
        }
      : to;

  return <SharedButton component={Link as any} to={linkProps} btnType={btnType} {...buttonProps} />;
};

export const CreateButton = createPolymorphicComponent<'a', AnchorProps>(
  forwardRef<HTMLAnchorElement, AnchorProps>(({ children, ...others }, ref) => (
    <Anchor
      ref={ref}
      py={10}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      }}
      {...others}
    >
      <MIconAdd />

      {children}
    </Anchor>
  )),
);
