import React, { FC } from 'react';
import * as Styled from './ImpactTabs.styles';
import { ImpactType } from '@modules/impact/model';

interface ImpactTabsProps {
  value: ImpactType;
  setValue: (type: ImpactType) => void;
  data: Array<{ label: string; value: ImpactType }>;
}

const ImpactTabs: FC<ImpactTabsProps> = ({ value, setValue, data }) => {
  return (
    <Styled.ImpactTabsContainer>
      <Styled.ImpactTabsContent value={value} onChange={setValue} data={data} val={value} />
    </Styled.ImpactTabsContainer>
  );
};

export default ImpactTabs;
