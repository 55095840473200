import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import Card from '@shared/components/card/Card';
import Pages from '@shared/components/pages/Pages';
import { infosList } from '@modules/infos/list';

const InfosPage: FC = () => {
  return (
    <Pages
      title="l'info de l'air"
      description="Airparif vous propose de découvrir l’essentiel des enjeux de la pollution de l’air, les activités qui en sont à l’origine et ses conséquences sur la santé et l’environnement pour comprendre et passer à l’action."
    >
      {Object.values(infosList).map((val, index) => (
        <Card description={val.description} link={val.link} key={index} />
      ))}
    </Pages>
  );
};

const infosPage = defineRoute({
  component: InfosPage,
  loader: undefined,
});

export default infosPage;
