import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { pipe } from 'fp-ts/function';
import { sequenceS, sequenceT } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { WeeksService } from '@modules/weeks/service';
import { ProfileAddressService } from '@modules/profile/address/service';
import WeekTravels from '@modules/weeks/components/update/week/WeekTravels';
import { defineAction, useAction } from '@core/router/action';
import { Weeks } from '@modules/weeks/model';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';
import { ProfileTravelsService } from '@modules/profile/travels/service';
import { ProfileTravels } from '@modules/profile/travels/model';
import { HttpStatusCode } from '@core/http';

function getErrorMessage(status: HttpStatusCode): string {
  switch (status) {
    case HttpStatusCode.CONFLICT:
      return 'Nous avons un problème pour calculer votre distance à partir des informations renseignées. Veuillez nous contacter à partenariats-digital@airparif.fr';
    default:
      return 'Une erreur technique est survenue';
  }
}

const loader = defineLoader({
  handler: () =>
    pipe(
      sequenceS(TE.ApplyPar)({
        week: WeeksService.getWeeks(),
        addresses: ProfileAddressService.getProfileAddresses(),
        vehicles: ProfileVehiclesService.getProfileVehicles(),
        travels: ProfileTravelsService.getProfileTravels(),
      }),
      httpTaskToResponseTask,
    ),
});

const UpdatePayload = Weeks.UpdateTravelDayParams.extend({
  travelToSave: ProfileTravels.ProfileTravelParams.nullable(),
});

const actions = {
  update: defineAction({
    type: 'update',
    payload: UpdatePayload,
    handler: ({ payload }) => {
      const { travelToSave, ...params } = payload;

      const saveTravelTask = pipe(
        O.fromNullable(travelToSave),
        O.foldW(
          () => TE.right(true),
          params => ProfileTravelsService.createProfileTravel(params),
        ),
      );

      return sequenceT(TE.ApplyPar)(WeeksService.updateWeekDay(params), saveTravelTask);
    },
    flashOptions: {
      error: error => getErrorMessage(error.error.status),
    },
  }),
};

const WeekDetailPage: FC = () => {
  const { week, addresses, vehicles, travels } = useLoader<typeof loader>();

  const [loading, updateDay] = useAction(actions.update);

  const handleSubmit = (params: Weeks.UpdateTravelDayParams, travelToSave: ProfileTravels.ProfileTravelParams | null) =>
    updateDay({
      ...params,
      travelToSave,
    });

  return (
    <>
      <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
        <PageTitle title={week.weekNumber === 0 ? 'Ma semaine type' : 'Mes trajets de la semaine'} />
      </Box>

      <Box m="0 auto" sx={{ maxWidth: 700 }}>
        <WeekTravels
          week={week}
          addresses={addresses}
          vehicles={vehicles}
          travels={travels}
          loading={loading}
          onSubmit={handleSubmit}
        />
      </Box>
    </>
  );
};

const weekDetailRoute = defineRoute({
  component: WeekDetailPage,
  loader,
  actions,
});

export default weekDetailRoute;
