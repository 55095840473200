import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { HttpError, HttpStatusCode } from '@core/http';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Button, Group, Text, TextInput } from '@mantine/core';
import { Passwords } from '@modules/passwords/model';

interface ForgotPasswordFormProps {
  loading: boolean;
  error: O.Option<HttpError>;
  onSubmit: (params: Passwords.ForgotPasswordParams) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Passwords.ForgotPasswordParams>({
    resolver: zodResolver(Passwords.forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {HttpStatusCode.NOT_FOUND === error.status
              ? "L'email n'est rattaché à aucun compte."
              : 'Une erreur technique est survenue.'}
          </Text>
        </Alert>
      ))}

      <Text size="sm">Envoyer par e-mail des instructions de réinitialisation à</Text>

      <TextInput mt="md" type="email" id="email" error={!!errors.email} {...register('email')} />

      <Group mt={28} grow>
        <Button type="submit" loading={loading} disabled={loading}>
          Envoyer
        </Button>
      </Group>
    </form>
  );
};

export default ForgotPasswordForm;
