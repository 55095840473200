import React, { FC, PropsWithChildren } from 'react';
import * as Styled from './HomeHeaderAccordion.styles';
import { Accordion, Text } from '@mantine/core';

interface HomeHeaderAccordionProps {
  title: string;
}

const HomeHeaderAccordion: FC<PropsWithChildren<HomeHeaderAccordionProps>> = ({ title, children }) => {
  return (
    <Styled.HomeHeaderAccordionContainer variant="filled" transitionDuration={500}>
      <Accordion.Item value="realized" p={0}>
        <Accordion.Control>
          <Text size={10} weight={600}>
            {title}
          </Text>
        </Accordion.Control>

        <Accordion.Panel>
          <Styled.HomeHeaderAccordionItems>{children}</Styled.HomeHeaderAccordionItems>
        </Accordion.Panel>
      </Accordion.Item>
    </Styled.HomeHeaderAccordionContainer>
  );
};

export default HomeHeaderAccordion;
