import { ReactNode } from 'react';
import { z } from 'zod';

export namespace Actions {
  export const Id = z.string().uuid().brand<'ActionId'>();
  export type Id = z.infer<typeof Id>;

  export enum Name {
    MarchePratique1 = 'marche-pratique-1',
    MarchePratique2 = 'marche-pratique-2',
    MarchePratique3 = 'marche-pratique-3',
    MarchePratique4 = 'marche-pratique-4',
    MarchePratique5 = 'marche-pratique-5',
    MarcheSante1 = 'marche-sante-1',
    MarcheSante2 = 'marche-sante-2',
    MarcheSante3 = 'marche-sante-3',
    MarcheSante4 = 'marche-sante-4',
    MarcheSante5 = 'marche-sante-5',
    MarcheSante6 = 'marche-sante-6',
    MarcheSante7 = 'marche-sante-7',
    MarcheGain1 = 'marche-gain-1',
    MarcheGain2 = 'marche-gain-2',
    VeloPratique1 = 'velo-pratique-1',
    VeloPratique2 = 'velo-pratique-2',
    VeloPratique3 = 'velo-pratique-3',
    VeloPratique4 = 'velo-pratique-4',
    VeloPratique5 = 'velo-pratique-5',
    VeloPratique6 = 'velo-pratique-6',
    VeloPratique7 = 'velo-pratique-7',
    VeloPratique8 = 'velo-pratique-8',
    VeloPratique9 = 'velo-pratique-9',
    VeloPratique10 = 'velo-pratique-10',
    VeloSante1 = 'velo-sante-1',
    VeloSante2 = 'velo-sante-2',
    VeloSante3 = 'velo-sante-3', // Aucune image
    VeloSante4 = 'velo-sante-4',
    VeloGain1 = 'velo-gain-1',
    VeloGain2 = 'velo-gain-2',
    VAEPratique1 = 'vae-pratique-1',
    VAEPratique2 = 'vae-pratique-2',
    VAEPratique3 = 'vae-pratique-3',
    VAEPratique4 = 'vae-pratique-4', // Aucune image
    VAEPratique5 = 'vae-pratique-5', // Aucune image
    VAEGain1 = 'vae-gain-1',
    VAESante1 = 'vae-sante-1',
    Intermodality = 'intermodality',
    Park = 'park',
    TT1 = 'tt-1',
    TT2 = 'tt-2',
    TT3 = 'tt-3',
    TT4 = 'tt-4',
    TT5 = 'tt-5',
    TT6 = 'tt-6',
    TT7 = 'tt-7',
    TT8 = 'tt-8',
    TT9 = 'tt-9',
    TT10 = 'tt-10',
    TT11 = 'tt-11',
    Elec1 = 'elec-1',
    Elec2 = 'elec-2',
    Elec3 = 'elec-3',
    Elec4 = 'elec-4',
    Elec5 = 'elec-5',
    Elec6 = 'elec-6',
    Elec7 = 'elec-7',
    Elec8 = 'elec-8',
    Elec9 = 'elec-9',
    Carpooling1 = 'carpooling-1',
    Carpooling2 = 'carpooling-2',
    Carpooling3 = 'carpooling-3',
    Carpooling4 = 'carpooling-4',
    Carpooling5 = 'carpooling-5',
    Carpooling6 = 'carpooling-6',
    Carpooling7 = 'carpooling-7',
    Carpooling8 = 'carpooling-8',
    Carpooling9 = 'carpooling-9',
    Carpooling10 = 'carpooling-10',
    Carpooling11 = 'carpooling-11',
    Carpooling12 = 'carpooling-12',
    Coworking = 'coworking',
    EcoDriving1 = 'eco-driving-1',
    EcoDriving2 = 'eco-driving-2',
    EcoDriving3 = 'eco-driving-3',
    EcoDriving4 = 'eco-driving-4',
    EcoDriving5 = 'eco-driving-5',
    EcoDriving6 = 'eco-driving-6',
    EcoDriving7 = 'eco-driving-7',
    Mobility1 = 'mobility-1',
    Mobility2 = 'mobility-2',
    Mobility3 = 'mobility-3',
    Mobility4 = 'mobility-4',
    Mobility5 = 'mobility-5',
    Mobility6 = 'mobility-6',
    Mobility7 = 'mobility-7',
    Mobility8 = 'mobility-8',
    Mobility9 = 'mobility-9',
  }

  export interface Detail {
    id: Id;
    name: Name;
    image?: ReactNode;
    description: ReactNode;
    answer?: ActionAnswer | null;
    // Cas si on vient juste de cliquer sur `Bof`
    pendingNotOk?: boolean;
  }

  export enum ActionAnswer {
    Ok = 'ok',
    NotOk = 'nok',
  }

  export interface ApiDetail {
    id: Id;
    name: Name;
    answer: ActionAnswer | null;
  }
}
