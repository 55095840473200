import { httpService, HttpTask } from '@core/http';
import { Weeks } from '@modules/weeks/model';

export namespace WeeksService {
  const URI = '/weeks';

  export function getWeeks(model?: boolean): HttpTask<Weeks.TravelWeek> {
    return httpService.get(`${URI}${model ? '/model' : ''}`);
  }

  export function updateWeekDay({ id, ...params }: Weeks.UpdateTravelDayParams) {
    return httpService.post(`${URI}/days/${id}`, params);
  }

  export function validateWeek() {
    return httpService.post(`${URI}/validate`);
  }
}
