import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { SurveyService } from '@modules/survey/service';
import { defineRoute } from '@core/router';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(SurveyService.validateEndSurvey()),
  redirect: () => '/',
});

const surveyEndCompletedRoute = defineRoute({
  loader,
});

export default surveyEndCompletedRoute;
