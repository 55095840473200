import React, { FC } from 'react';
import * as Styled from './HomeHeaderCard.styles';
import { Text } from '@mantine/core';
import checkIcon from '@assets/icons/home/check.svg';
import { MIconArrowRight } from '@styles/icons';

interface HomeHeaderCardProps {
  isCompleted: boolean;
  description: string;
  time: string;
  link: string;
}

const HomeHeaderCard: FC<HomeHeaderCardProps> = ({ isCompleted, description, time, link }) => {
  return (
    <Styled.HomeHeaderCardContainer to={isCompleted ? '' : link}>
      {isCompleted ? (
        <Styled.HomeHeaderCardCheck>
          <Styled.HomeHeaderCardImg src={checkIcon} alt="Check" />
        </Styled.HomeHeaderCardCheck>
      ) : (
        <Styled.HomeHeaderCardCheck />
      )}

      <Styled.HomeHeaderCardText>
        <Text size={12} color="black" lh="16px">
          {description}
        </Text>

        <Text size={10} color="darkgray" lh="16px">
          {time}
        </Text>
      </Styled.HomeHeaderCardText>

      <MIconArrowRight color={isCompleted ? 'pink' : 'orange'} />
    </Styled.HomeHeaderCardContainer>
  );
};

export default HomeHeaderCard;
