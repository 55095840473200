import styled from '@emotion/styled';
import { NAV_HEIGHT } from '@layout/nav/NavLayout';

export const HomeFooterContainer = styled.div`
  padding: 25px 50px ${NAV_HEIGHT + 20}px 45px;
  background-color: ${props => props.theme.colors.violet[6]};
  border-radius: 18px 0 0 0;

  a {
    margin-left: 5px;
    color: white;
  }
`;

export const HomeFooterContent = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-column-gap: 50px;
  align-items: center;
  width: 100%;
  max-width: 700px;
`;
