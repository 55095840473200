import React, { FC } from 'react';
import * as Styled from './HomeHeaderWeeks.styles';
import { Button, Group, Paper, Stack, Text } from '@mantine/core';
import { Profile } from '@modules/profile/model';
import {
  HomeAddEnum,
  homeAddLabel,
  homeAddLink,
  homeAddTimeLabel,
  HomeModel,
  HomeStep,
  HomeWeekStatus,
} from '@modules/home/model';
import HomeHeaderCard from '@modules/home/components/header/card/HomeHeaderCard';
import { Link } from 'react-router-dom';
import { MIconArrowRight, MIconCompleted, MIconLike } from '@styles/icons';
import HomeHeaderAccordion from '@modules/home/components/header/accordion/HomeHeaderAccordion';

interface HomeHeaderWeeksSteps {
  carStep: HomeStep;
  twoWheelersStep: HomeStep;
}

interface HomeHeaderWeeksCardsProps {
  steps: HomeHeaderWeeksSteps;
}

const HomeHeaderWeeksCards: FC<HomeHeaderWeeksCardsProps> = ({ steps }) => {
  return (
    <Styled.HomeHeaderWeeksCards>
      <Text>
        Super ! Tu as dit que tu utilisais
        {steps.twoWheelersStep !== HomeStep.NotNeeded && steps.twoWheelersStep !== HomeStep.Done
          ? ' un 2 roues motorisé'
          : null}
        {steps.twoWheelersStep !== HomeStep.NotNeeded &&
        steps.twoWheelersStep !== HomeStep.Done &&
        steps.carStep !== HomeStep.NotNeeded &&
        steps.carStep !== HomeStep.Done
          ? ' et'
          : null}
        {steps.carStep !== HomeStep.NotNeeded && steps.carStep !== HomeStep.Done ? ' une voiture' : null}
        &nbsp;: il ne nous manque plus que cette info pour te donner ton diagnostic.
      </Text>
      {Object.entries(steps).map(([key, value], index) =>
        value === HomeStep.Todo ? (
          <HomeHeaderCard
            isCompleted={false}
            description={homeAddLabel[key as HomeAddEnum]}
            time={homeAddTimeLabel[key as HomeAddEnum]}
            link={homeAddLink[key as HomeAddEnum]}
          />
        ) : null,
      )}
    </Styled.HomeHeaderWeeksCards>
  );
};

const HomeHeaderWeeksPlaceholder: FC = () => {
  return (
    <Paper component={Link} to="operation" bg="violet" withBorder={false} p={15} mt={30}>
      <Text size={16} lh="22px" weight={700} color="white">
        Objectif réduction des émissions !
      </Text>

      <Text size={12} pt={8} color="white">
        La qualité de l’air est un enjeu majeur pour la santé et l'environnement. En France, la sur la période
        2016-2019, Santé publique France estime que la pollution atmosphérique est responsable chaque année de près de
        40 000 décès prématurés. Cela nécessite des actions ambitieuses, au niveau international comme au niveau local,
        dans tous les secteurs d’activité. Et dans cette entreprise, chacun est le collectif !
      </Text>

      <Text underline size={14} weight={600} pt={8} color="white">
        En savoir plus
      </Text>
    </Paper>
  );
};

interface HomeHeaderWeeksProps {
  infos: HomeModel;
  studyGroup: Profile.ProfileStugyGroup;
}

const HomeHeaderWeeks: FC<HomeHeaderWeeksProps> = ({ infos, studyGroup }) => {
  const steps = {
    carStep: infos.carStep,
    twoWheelersStep: infos.twoWheelersStep,
  };

  switch (studyGroup) {
    case Profile.ProfileStugyGroup.A:
      switch (infos.weekStatus) {
        case HomeWeekStatus.Todo:
          return (
            <Group pt={20}>
              <Text size={16} lh="22px">
                Alors c'était comment cette semaine ?
              </Text>

              <HomeHeaderCard
                isCompleted={false}
                description="Je renseigne mes trajets de la semaine écoulée"
                time="<7 minutes"
                link="/weeks"
              />
            </Group>
          );

        case HomeWeekStatus.Validated:
        case HomeWeekStatus.Error:
        default:
          return (
            <Stack pt={20}>
              {infos.stepProgressionPercentage === 1 ? (
                <>
                  <Text size={16} lh="22px">
                    <MIconLike style={{ marginRight: '5px' }} />
                    Merci d'avoir pris le temps d'aider ce projet d'intérêt général, pour un air plus doux !
                  </Text>

                  <Text size={16} lh="22px" pt={10}>
                    On a encore un peu besoin de toi pour renseigner ta semaine de déplacement domicile-travail. Pour
                    les semaines où rien n'aurait changé, tu peux reprendre la saisie déjà enregistrée de la semaine
                    précédente.
                  </Text>

                  <Text size={16} lh="22px" pt={10}>
                    On reviendra vers toi avec une nouvelle enquête fin juin - début juillet...et après, VACANCES !
                  </Text>

                  <MIconCompleted />
                </>
              ) : (
                <HomeHeaderWeeksCards steps={steps} />
              )}

              <HomeHeaderAccordion title="Voir les tâches réalisées">
                <HomeHeaderCard
                  isCompleted
                  description="Je renseigne ma semaine écoulée"
                  time="<7 minutes"
                  link="/weeks"
                />
              </HomeHeaderAccordion>
            </Stack>
          );
      }

    default:
      if (infos.isWeekClosed) {
        return <HomeHeaderWeeksPlaceholder />;
      } else {
        switch (infos.weekStatus) {
          case HomeWeekStatus.Todo:
            return (
              <Group pt={20}>
                <Text size={16} lh="22px">
                  Alors c'était comment cette semaine ? Quels défis as tu relevés ?
                </Text>

                <HomeHeaderCard
                  isCompleted={false}
                  description="Je renseigne mes trajets de la semaine écoulée"
                  time="<7 minutes"
                  link="/weeks"
                />

                <Styled.HomeHeaderWeeksDisabledButton
                  component={Link}
                  to="/impact"
                  w="100%"
                  disabled
                  rightIcon={<MIconArrowRight />}
                >
                  <Text size={14} weight={700}>
                    Je découvre mon impact
                  </Text>
                </Styled.HomeHeaderWeeksDisabledButton>
              </Group>
            );

          case HomeWeekStatus.Validated:
            return (
              <Group>
                {infos.stepProgressionPercentage === 1 ? (
                  <>
                    <Text size={16} lh="22px" pt={20}>
                      Bravo ! Être ici c'est déjà une victoire à fêter ! Tu vas voir tes résultats et ceux de ton équipe
                      ?
                    </Text>

                    <Button component={Link} to="/impact" w="100%" color="orange" rightIcon={<MIconArrowRight />}>
                      <Text size={14} weight={700}>
                        Je découvre mon impact
                      </Text>
                    </Button>

                    <HomeHeaderAccordion title="Voir les tâches réalisées">
                      <HomeHeaderCard
                        isCompleted
                        description="Je renseigne ma semaine écoulée"
                        time="<7 minutes"
                        link="/weeks"
                      />
                    </HomeHeaderAccordion>
                  </>
                ) : (
                  <HomeHeaderWeeksCards steps={steps} />
                )}
              </Group>
            );

          case HomeWeekStatus.Error:
            return <HomeHeaderWeeksPlaceholder />;
        }
      }
  }
};

export default HomeHeaderWeeks;
