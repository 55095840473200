import React, { FC } from 'react';
import * as Styled from './ImpactRanking.styles';
import { Col, Grid, Paper, Text, ThemeIcon } from '@mantine/core';
import { impactChallengeLabel, ImpactChallengeText, ImpactType } from '@modules/impact/model';

import noxIllu from '@assets/icons/impact/nox.svg';
import pmIllu from '@assets/icons/impact/pm.svg';
import co2Illu from '@assets/icons/impact/co2.svg';
import { MIconImpactTeam, MIconImpactUser } from '@styles/icons';

function getIllu(impactType: ImpactType) {
  switch (impactType) {
    case ImpactType.Nox:
      return <img src={noxIllu} alt="Nox" />;
    case ImpactType.PM:
      return <img src={pmIllu} alt="PM" />;
    case ImpactType.CO2:
      return <img src={co2Illu} alt="Co2" />;
  }
}

function getColor(impactType: ImpactType): string {
  switch (impactType) {
    case ImpactType.Nox:
      return 'violet';
    case ImpactType.PM:
      return 'orange';
    case ImpactType.CO2:
      return 'blue';
  }
}

interface ImpactRankingProps {
  impactType: ImpactType;
  team: number;
  teamTotal: number;
  organization: number;
  organizationTotal: number;
  challengeText: ImpactChallengeText | null;
  isGroupImpactAvailable: boolean;
  color: string;
  currentWeek: number;
}

const ImpactRanking: FC<ImpactRankingProps> = ({
  impactType,
  team,
  teamTotal,
  organization,
  organizationTotal,
  challengeText,
  isGroupImpactAvailable,
  color,
  currentWeek,
}) => {
  const colorLight = getColor(impactType);

  return (
    <>
      <Grid gutter={10} pt={20}>
        <Col span={6}>
          <Paper px={5} py={10} withBorder={false}>
            <Styled.ImpactRankingTitle>
              <ThemeIcon
                color={colorLight}
                variant="light"
                radius="xl"
                style={{
                  width: '20px',
                  minWidth: '20px',
                  height: '20px',
                  minHeight: '20px',
                }}
              >
                <MIconImpactUser width={20} height={20} />
              </ThemeIcon>

              <div>
                <Text transform="uppercase" size={9}>
                  Ma place dans l'équipe
                </Text>

                {isGroupImpactAvailable ? (
                  <Styled.ImpactRankingValues>
                    <Text size={46} weight={600} color={color} lh={1} pr={5}>
                      {team}
                    </Text>
                    <Text size={24} weight={400} color={color} lh={1}>
                      /{teamTotal}
                    </Text>
                  </Styled.ImpactRankingValues>
                ) : (
                  <Styled.ImpactRankingValues>
                    <Text size={46} weight={600} color="#b0a3bc" lh={1} pr={5}>
                      X
                    </Text>
                    <Text size={24} weight={400} color="#b0a3bc" lh={1}>
                      /X
                    </Text>
                  </Styled.ImpactRankingValues>
                )}
              </div>
            </Styled.ImpactRankingTitle>
          </Paper>
        </Col>

        <Col span={6}>
          <Paper px={5} py={10} withBorder={false}>
            <Styled.ImpactRankingTitle>
              <ThemeIcon
                color={colorLight}
                variant="light"
                radius="xl"
                style={{
                  width: '20px',
                  minWidth: '20px',
                  height: '20px',
                  minHeight: '20px',
                }}
              >
                <MIconImpactTeam width={20} height={20} />
              </ThemeIcon>

              <div>
                <Text transform="uppercase" size={9}>
                  Mon organisation parmi les autres
                </Text>

                {isGroupImpactAvailable ? (
                  <Styled.ImpactRankingValues>
                    <Text size={46} weight={600} color={color} lh={1} pr={5}>
                      {organization}
                    </Text>
                    <Text size={24} weight={400} color={color} lh={1}>
                      /{organizationTotal}
                    </Text>
                  </Styled.ImpactRankingValues>
                ) : (
                  <Styled.ImpactRankingValues>
                    <Text size={46} weight={600} color="#b0a3bc" lh={1} pr={5}>
                      X
                    </Text>
                    <Text size={24} weight={400} color="#b0a3bc" lh={1}>
                      /X
                    </Text>
                  </Styled.ImpactRankingValues>
                )}
              </div>
            </Styled.ImpactRankingTitle>
          </Paper>
        </Col>
      </Grid>

      {challengeText != null ? (
        <Styled.ImpactRankingText>
          <Text size={16} weight={400} lh="22px">
            {impactChallengeLabel[challengeText]}
          </Text>

          <Styled.ImpactRankingImage>{getIllu(impactType)}</Styled.ImpactRankingImage>
        </Styled.ImpactRankingText>
      ) : currentWeek !== 0 ? (
        <Styled.ImpactRankingText>
          <Text size={16} weight={400} lh="22px" color="#b0a3bc">
            Le classement d'équipe est bientôt prêt ! Presque tous les participants ont répondu nous allons bientôt
            créer le compte rendu de classement.
          </Text>

          <Styled.ImpactRankingImage>{getIllu(impactType)}</Styled.ImpactRankingImage>
        </Styled.ImpactRankingText>
      ) : null}
    </>
  );
};

export default ImpactRanking;
