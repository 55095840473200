import React, { FC } from 'react';
import * as Styled from './HomeHeaderAdd.styles';
import {
  HomeAdd,
  HomeAddEnum,
  homeAddLabel,
  homeAddLink,
  homeAddTimeLabel,
  HomeInfos,
  HomeStep,
} from '@modules/home/model';
import HomeHeaderCard from '@modules/home/components/header/card/HomeHeaderCard';
import { Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { MIconArrowRight } from '@styles/icons';
import HomeHeaderRealized from '@modules/home/components/header/realized/HomeHeaderRealized';

interface HomeHeaderAddProps {
  step1: HomeInfos;
  step: HomeAdd;
}

const HomeHeaderAdd: FC<HomeHeaderAddProps> = ({ step1, step }) => {
  return (
    <Styled.HomeHeaderAddContainer>
      <Styled.HomeHeaderAddCards>
        {Object.entries(step).map(([key, value], index) =>
          value === HomeStep.Todo ? (
            <HomeHeaderCard
              isCompleted={false}
              description={homeAddLabel[key as HomeAddEnum]}
              time={homeAddTimeLabel[key as HomeAddEnum]}
              link={homeAddLink[key as HomeAddEnum]}
              key={index}
            />
          ) : null,
        )}
      </Styled.HomeHeaderAddCards>

      <Styled.HomeHeaderAddButton
        component={Link}
        to="/impact"
        w="100%"
        disabled
        my={8}
        rightIcon={<MIconArrowRight />}
      >
        <Text size={14} weight={700}>
          Je découvre mon impact
        </Text>
      </Styled.HomeHeaderAddButton>

      <HomeHeaderRealized step1={step1} step2={step} />
    </Styled.HomeHeaderAddContainer>
  );
};

export default HomeHeaderAdd;
