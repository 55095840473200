import { Actions } from '@modules/actions/model';
import ProcessedImage from '@shared/components/processed-image/ProcessedImage';

export const actionsList: Record<Actions.Name, Omit<Actions.Detail, 'id' | 'name'>> = {
  [Actions.Name.MarchePratique1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarchePratique1} />,
    description: (
      <p>
        Marcher en découvrant sans rater ce qui nous entoure, c'est le pari de &nbsp;
        <a
          href="https://www.iledefrance.fr/bougeott-lappli-des-balades-sport-culture-innove-encore"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bougeott
        </a>
        &nbsp; l'appli qui te fait lever la tête !
      </p>
    ),
  },
  [Actions.Name.MarchePratique2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarchePratique2} />,
    description: (
      <p>
        La marche c'est une vitesse moyenne de 3,6 km/h, tu fais le calcul de ton temps de trajet ? (indice pour l'avoir
        en minutes la distance en kilomètre /3.6 x 60) .
      </p>
    ),
  },
  [Actions.Name.MarchePratique3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarchePratique3} />,
    description: (
      <p>
        Explorer, écouter, collectionner : avec l'application &nbsp;
        <a href="https://whatizis.com/" target="_blank" rel="noreferrer noopener">
          Whatizis
        </a>
        , ton trajet quotidien à Paris devient un cours d'histoire
      </p>
    ),
  },
  [Actions.Name.MarchePratique4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarchePratique4} />,
    description: (
      <p>
        La bonne cause prend le chemin du boulot ! L'application &nbsp;
        <a href="https://charitymiles.org/#intro" target="_blank" rel="noreferrer noopener">
          Charity Miles
        </a>
        &nbsp; qui convertit tes pas en dons caritatifs
      </p>
    ),
  },
  [Actions.Name.MarchePratique5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarchePratique5} />,
    description: (
      <p>
        Je te propose un nouveau défi : marcher plus ! Avec un &nbsp;
        <a
          href="https://play.google.com/store/apps/details?id=pedometer.steptracker.calorieburner.stepcounter&hl=fr&gl=US"
          target="_blank"
          rel="noreferrer noopener"
        >
          podomètre
        </a>
        &nbsp; suis ta progression et tes itinéraires
      </p>
    ),
  },
  [Actions.Name.MarcheSante1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante1} />,
    description: (
      <p>
        L'OMS préconise 30 minutes de &nbsp;
        <a
          href="https://www.who.int/fr/news-room/fact-sheets/detail/physical-activity#:~:text=Les%20recommandations%20mondiales%20pr%C3%A9conisent%20au,d'intensit%C3%A9%20soutenue%20par%20semaine."
          target="_blank"
          rel="noreferrer noopener"
        >
          marche quotidienne.
        </a>
        &nbsp; Aller au boulot à pied, ça compte !
      </p>
    ),
  },
  [Actions.Name.MarcheSante2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante2} />,
    description: (
      <p>
        40% des cancers sont évitables grâce à la pratique régulière d’une &nbsp;
        <a href="https://www.youtube.com/watch?v=YPuMtK42H6A&t=38s" target="_blank" rel="noreferrer noopener">
          activité physique
        </a>
        &nbsp; comme la marche.
      </p>
    ),
  },
  [Actions.Name.MarcheSante3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante3} />,
    description: (
      <p>
        <a
          href="https://www.mangerbouger.fr/bouger-plus/avoir-un-mode-de-vie-actif/catalogue-d-activites-physiques/marche-a-pied"
          target="_blank"
          rel="noreferrer noopener"
        >
          Marcher de 30 à 60 minutes par jour à une intensité modérée à élevée
        </a>
        &nbsp; réduit le risque de développer un cancer du sein.
      </p>
    ),
  },
  [Actions.Name.MarcheSante4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante4} />,
    description: (
      <p>
        Celui qui est le plus exposé à une mauvaise &nbsp;
        <a
          href="https://airparif.fr/index.php/en/node/75&sa=D&source=editors&ust=1676366009315119&usg=AOvVaw0_ShIeZ5Rqg3ejOxgkwIfu"
          target="_blank"
          rel="noreferrer noopener"
        >
          qualité de l'air
        </a>
        &nbsp; c’est toujours celui qui est en voiture. Marre de tousser? Il faut marcher !
      </p>
    ),
  },
  [Actions.Name.MarcheSante5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante5} />,
    description: (
      <p>
        Pour être en bonne santé, il faut essayer d'atteindre au moins &nbsp;
        <a
          href="https://www.mangerbouger.fr/l-essentiel/les-recommandations-sur-l-alimentation-l-activite-physique-et-la-sedentarite/augmenter/augmenter-l-activite-physique"
          target="_blank"
          rel="noreferrer noopener"
        >
          30 minutes d’activités physiques
        </a>
        &nbsp; dynamiques par jour
      </p>
    ),
  },
  [Actions.Name.MarcheSante6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante6} />,
    description: (
      <p>
        Marcher régulièrement améliore l'état de santé général et la longévité, pour vivre vieux, mieux vaut marcher !
      </p>
    ),
  },
  [Actions.Name.MarcheSante7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheSante7} />,
    description: (
      <p>
        8% de concentration en plus pour les enfants qui ne viennent pas à l'école en voiture. Et si l'éducation
        commençait à la maison ?
      </p>
    ),
  },
  [Actions.Name.MarcheGain1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheGain1} />,
    description: (
      <p>
        Le trafic routier est responsable de plus de la &nbsp;
        <a
          href="https://www.cerema.fr/fr/actualites/emissions-routieres-polluants-atmospheriques-courbes"
          target="_blank"
          rel="noreferrer noopener"
        >
          moitié des émissions de NOx
        </a>
        . Pas envie d'y contribuer? teste la marche à pied!
      </p>
    ),
  },
  [Actions.Name.MarcheGain2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.MarcheGain2} />,
    description: <p>Et si marcher t'offrait 10 minutes de pause pour écouter un podcast ou la radio en flânant ?</p>,
  },
  [Actions.Name.VeloPratique1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique1} />,
    description: (
      <p>
        Pas de vélo ? &nbsp;
        <a href="https://www.velib-metropole.fr/map#/" target="_blank" rel="noreferrer noopener">
          Vélib
        </a>
        &nbsp; est là pour toi, pour 1€ pour 30 min en Vélo mécanique, et 3€ pour 45min en vélo électrique. Pour trouver
        la station la plus proche, c'est sur le lien
      </p>
    ),
  },
  [Actions.Name.VeloPratique2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique2} />,
    description: (
      <p>
        Si tu veux éviter de finir sur le périph' à vélo, choisis un itinéraire cycliste adapté avec &nbsp;
        <a
          href="https://geovelo.fr/fr/route?bike-type=own&c=1.500550%2C47.008249&e-bike=false&z=5.51"
          target="_blank"
          rel="noreferrer noopener"
        >
          geovelo.fr
        </a>
      </p>
    ),
  },
  [Actions.Name.VeloPratique3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique3} />,
    description: (
      <p>
        En ville, un vélo roule en moyenne à 15km/h contre 14km/h pour une voiture. Compare les temps de parcours avec
        &nbsp;
        <a href="https://me-deplacer.iledefrance-mobilites.fr/itineraire" target="_blank" rel="noreferrer noopener">
          ViaNavigo
        </a>
      </p>
    ),
  },
  [Actions.Name.VeloPratique4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique4} />,
    description: (
      <p>
        Non mais Allô, t'as pas de vélo ? Découvre les prochaines &nbsp;
        <a href="https://mdb-idf.org/bourses-aux-velos/" target="_blank" rel="noreferrer noopener">
          bourses à vélo
        </a>
        &nbsp; pour t'équiper !
      </p>
    ),
  },
  [Actions.Name.VeloPratique5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique5} />,
    description: (
      <a href="https://www.fub.fr/reseau-fub/velo-ecoles" target="_blank" rel="noreferrer noopener">
        Il n'y pas d'âge pour apprendre à pédaler grâce aux associations de mise en selle !
      </a>
    ),
  },
  [Actions.Name.VeloPratique6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique6} />,
    description: (
      <a
        href="https://www.abicyclette-voyages.com/recits-velo-voyage/nos-conseils-pour-faire-du-velo-meme-quand-il-pleut/"
        target="_blank"
        rel="noreferrer noopener"
      >
        Il n'y pas de mauvais temps, juste des mauvais équipements, les bons conseils pour faire du vélo sous la pluie
        sont ici
      </a>
    ),
  },
  [Actions.Name.VeloPratique7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique7} />,
    description: (
      <a
        href="https://www.airparif.asso.fr/sites/default/files/documents/2020-03/synthese_expovoituredomtra.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        Le plus exposé à la pollution, c'est l'automobiliste. A vélo, réduire son exposition c'est pédaler sur les
        pistes cyclables. A feu rouge, le sas vélo sécurise ta pratique et ta respiration.
      </a>
    ),
  },
  [Actions.Name.VeloPratique8]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique8} />,
    description: (
      <p>
        Tu savais qu'il existe des vélos pouvant transporter jusqu'à 4 enfants ? Plus d'infos &nbsp;
        <a
          href="https://www.2raventure.com/fr/blog/le-velo-et-famille-xxl-incompatible--n111"
          target="_blank"
          rel="noreferrer noopener"
        >
          ici
        </a>
      </p>
    ),
  },
  [Actions.Name.VeloPratique9]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique9} />,
    description: (
      <p>
        <a
          href="https://www.fub.fr/sites/fub/files/fub/Enquete/velocommerce03rapfin.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          Faire ses courses à vélo?
        </a>
        &nbsp; Facile et il y a toujours un stationnement vélo tout proche!
      </p>
    ),
  },
  [Actions.Name.VeloPratique10]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloPratique10} />,
    description: (
      <p>
        Un vélo dans un garage? Et si tu apprenais à le réparer dans un &nbsp;
        <a href="https://www.fub.fr/moi-velo/services/ateliers-reparation" target="_blank" rel="noreferrer noopener">
          atelier d'autoréparation
        </a>
        &nbsp; à proximité ?
      </p>
    ),
  },
  [Actions.Name.VeloSante1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloSante1} />,
    description: (
      <p>
        Une activité physique modérée telle qu'un trajet domicile / travail à vélo réduit le &nbsp;
        <a
          href="https://ecf.com/policy-areas/cycling-economy/economic-benefits"
          target="_blank"
          rel="noreferrer noopener"
        >
          risque d'Alzheimer
        </a>
        &nbsp;de 29%
      </p>
    ),
  },
  [Actions.Name.VeloSante2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloSante2} />,
    description: (
      <p>
        Pas le temps pour le yoga ? Le vélotaf (comme la marche à pied) &nbsp;
        <a href="https://pubmed.ncbi.nlm.nih.gov/25152507/" target="_blank" rel="noreferrer noopener">
          réduit de 40% le niveau de stress.
        </a>
      </p>
    ),
  },
  [Actions.Name.VeloSante3]: {
    description: (
      <p>
        Le vélo est le mode de transport qui &nbsp;
        <a
          href="https://ecf.com/policy-areas/cycling-economy/economic-benefits"
          target="_blank"
          rel="noreferrer noopener"
        >
          rend le plus heureux
        </a>
        &nbsp; pour aller travailler selon des études réalisées à Londres, Montréal et aux USA !
      </p>
    ),
  },
  [Actions.Name.VeloSante4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloSante4} />,
    description: (
      <p>
        Faire du vélo rend heureux. L'activité physique telle que le vélotaf réduit le &nbsp;
        <a
          href="https://ecf.com/policy-areas/cycling-economy/economic-benefits"
          target="_blank"
          rel="noreferrer noopener"
        >
          risque de dépression
        </a>
        &nbsp; de 17%
      </p>
    ),
  },
  [Actions.Name.VeloGain1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloGain1} />,
    description: (
      <p>
        Les collectivités sont à tes côtés avec des &nbsp;
        <a href="https://metropolegrandparis.fr/fr/metropoleroulepropre" target="_blank" rel="noreferrer noopener">
          primes à l'achat de vélo personnalisées
        </a>
        . L'Etat, la Métropole du Grand Paris, la Région Ile-de-France, la Ville de Paris et d'autres municipalités ont
        mis en place des aides complémentaires.
      </p>
    ),
  },
  [Actions.Name.VeloGain2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VeloGain2} />,
    description: (
      <p>
        Rouler à vélo coûte 10 fois moins cher que rouler en voiture. &nbsp;
        <a
          href="https://expertises.ademe.fr/sites/default/files/assets/documents/ecocalculator.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tu calcules tes économies ?
        </a>
      </p>
    ),
  },
  [Actions.Name.VAEPratique1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VAEPratique1} />,
    description: (
      <p>
        Non, le vélo électrique, ce n'est pas que pour les non-sportifs! Tu veux vérifier?
        <a
          href="https://www.velib-metropole.fr/subscription/V1_OFFRE_CD_PRATIQUE/1"
          target="_blank"
          rel="noreferrer noopener"
        >
          Découvre le vélib électrique pour un test !
        </a>
      </p>
    ),
  },
  [Actions.Name.VAEPratique2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VAEPratique2} />,
    description: (
      <p>
        Curieux mais pas encore prêt à investir? &nbsp;
        <a href="https://www.veligo-location.fr/" target="_blank" rel="noreferrer noopener">
          Veligo
        </a>
        &nbsp;te permet de louer un vélo électrique pendant quelques mois
      </p>
    ),
  },
  [Actions.Name.VAEPratique3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VAEPratique3} />,
    description: (
      <p>
        Le vélo électrique, c'est bien. Mais en pratique, je passe par où ? Découvre le calculateur &nbsp;
        <a
          href="https://geovelo.fr/fr/route?bike-type=own&c=1.500550%2C47.008249&e-bike=false&z=5.51"
          target="_blank"
          rel="noreferrer noopener"
        >
          geovelo
        </a>
        &nbsp;pour simuler ton itinéraire!
      </p>
    ),
  },
  [Actions.Name.VAEPratique4]: {
    description: (
      <p>
        A vélo, on peut même porter des charges (y compris des enfants) avec un vélo cargo (électrique ou pas). Il
        existe des aides et on peut le tester avec &nbsp;
        <a href="https://www.veligo-location.fr/" target="_blank" rel="noreferrer noopener">
          Véligo cargo.
        </a>
      </p>
    ),
  },
  [Actions.Name.VAEPratique5]: {
    description: (
      <p>
        Difficile de stationner ton VAE chez toi? Il existe de plus en plus de &nbsp;
        <a
          href="https://www.isabelleetlevelo.fr/2021/03/17/stationnement-des-velos-panorama-paris-france/#more-37909"
          target="_blank"
          rel="noreferrer noopener"
        >
          solutions de stationnement
        </a>
        &nbsp; (parkings indigo, Yespark, 12.5, vélostations...)
      </p>
    ),
  },
  [Actions.Name.VAEGain1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VAEGain1} />,
    description: (
      <p>
        Les collectivités sont à tes côtés avec des &nbsp;
        <a href="metropolegrandparis.fr/fr/metropoleroulepropre" target="_blank" rel="noreferrer noopener">
          primes à l'achat de vélo personnalisées
        </a>
        . L'Etat, la Métropole du Grand Paris, la Région Ile-de-France, la Ville de Paris et d'autres municipalités ont
        mis en place des aides complémentaires.
      </p>
    ),
  },
  [Actions.Name.VAESante1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.VAESante1} />,
    description: (
      <p>
        <a href="https://pubmed.ncbi.nlm.nih.gov/35263497/" target="_blank" rel="noreferrer noopener">
          Le vélo électrique rend heureux!
        </a>
        &nbsp; C'est le résultat d'une étude australienne sur les personnes inactives et en surpoids.
      </p>
    ),
  },
  [Actions.Name.Intermodality]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Intermodality} />,
    description: (
      <p>
        Intermodalité? C'est l'utilisation de plusieurs modes de transport pour relier un point A à un point B ? Par
        exemple, pour aller prendre le RER, vous pouvez vous rendre à la gare à pied, à vélo... et c'est
        particulièrement efficace
      </p>
    ),
  },
  [Actions.Name.Park]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Park} />,
    description: (
      <p>
        24.000, c'est le nombre de places de parking relais pour déposer ta voiture avant de prendre le train. &nbsp;
        <a
          href="https://www.iledefrance-mobilites.fr/le-reseau/services-de-mobilite/parcs-relais/quest-ce-que-cest-parcs-relais"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tu testes ?
        </a>
      </p>
    ),
  },
  [Actions.Name.TT1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT1} />,
    description: <p>Le télétravail c'est gagner du temps, de la flexibilité et de l'autonomie. Envie de tester ?</p>,
  },
  [Actions.Name.TT2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT2} />,
    description: (
      <p>
        Le télétravail, ça ne s'improvise pas, &nbsp;
        <a
          href="https://www.francenum.gouv.fr/guides-et-conseils/gestion-des-ressources-humaines/teletravail/teletravail-12-conseils-pratiques"
          target="_blank"
          rel="noreferrer noopener"
        >
          voici 12 conseils pour télétravailler dans les meilleures conditions.
        </a>
      </p>
    ),
  },
  [Actions.Name.TT3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT3} />,
    description: <p>Télétravailler, c'est être ponctuel et ne pas stresser dans les transports !</p>,
  },
  [Actions.Name.TT4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT4} />,
    description: (
      <p>
        Eviter les transports, mais garder le lien social avec son équipe, c'est possible en télétravaillant. L'astuce :
        créer une salle de pause virtuelle où l'on partage les dernières nouvelles autour d'une café.
      </p>
    ),
  },
  [Actions.Name.TT5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT5} />,
    description: (
      <p>
        <a href="https://datagir.ademe.fr/apps/mon-impact-transport/" target="_blank" rel="noreferrer noopener">
          Télétravailler, c'est éviter les émissions de polluants de ses transports
        </a>
        &nbsp; (519kg de CO2 en 2023 en moyenne pour les automobilistes d'Ile de France)
      </p>
    ),
  },
  [Actions.Name.TT6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT6} />,
    description: (
      <a href="https://www.neo-nomade.com/" target="_blank" rel="noreferrer noopener">
        Télétravailler, oui mais pas chez moi, découvre les espaces à proximité avec neonomade
      </a>
    ),
  },
  [Actions.Name.TT7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT7} />,
    description: (
      <p>
        Télétravailler, c'est aussi profiter d'une pause pour découvrir les environs à pied, pour faire du sport, etc
      </p>
    ),
  },
  [Actions.Name.TT8]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT8} />,
    description: (
      <p>
        Le cours de yoga du bureau te manque, découvre &nbsp;
        <a href="https://www.petitbambou.com/" target="_blank" rel="noreferrer noopener">
          Petit Bambou
        </a>
        &nbsp; pour méditer simplement même à la maison
      </p>
    ),
  },
  [Actions.Name.TT9]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT9} />,
    description: (
      <p>
        Et si le télétravail t'aidait à planter des arbres? Découvre &nbsp;
        <a href="https://forestapp.cc/" target="_blank" rel="noreferrer noopener">
          Forest
        </a>
        &nbsp;et fais grandir des arbres virtuels pour valoriser ta concentration !
      </p>
    ),
  },
  [Actions.Name.TT10]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT10} />,
    description: (
      <p>
        L'ambiance sonore de l'openspace te manque en télétravail? Découvre &nbsp;
        <a href="https://www.noisli.com/" target="_blank" rel="noreferrer noopener">
          Noisli
        </a>
        &nbsp; pour un environnement sonore sur mesure
      </p>
    ),
  },
  [Actions.Name.TT11]: {
    image: <ProcessedImage type="actions" name={Actions.Name.TT11} />,
    description: (
      <p>
        Marre d'alterner entre le tabouret de la cuisine et le canapé du salon ? Et si tu pensais seconde vie ? &nbsp;
        <a
          href="https://www.label-emmaus.co/fr/catalogue/bureaux-secretaires-coiffeuses/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Emmaüs
        </a>
        a maintenant une plateforme digitale.
      </p>
    ),
  },
  [Actions.Name.Elec1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec1} />,
    description: (
      <a href="https://jechangemavoiture.gouv.fr/jcmv/" target="_blank" rel="noreferrer noopener">
        La voiture électrique te branche ? Découvre les aides de la région, de l'Etat et de la Métropole du Grand Paris
        pour t'aider et rapproche toi de ta commune pour savoir si elle a mis en place un dispositif local.
      </a>
    ),
  },
  [Actions.Name.Elec2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec2} />,
    description: (
      <p>
        Budget serré ? Découvre les &nbsp;
        <a
          href="https://www.je-roule-en-electrique.fr/combien-coute-la-recharge-dun-vehicule-electrique-au-quotidien-704"
          target="_blank"
          rel="noreferrer noopener"
        >
          économies que la voiture électrique te permet de réaliser
        </a>
      </p>
    ),
  },
  [Actions.Name.Elec3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec3} />,
    description: (
      <p>
        L'électrique, c'est bien mais une nuit entière pour la recharge moins.... &nbsp;
        <a
          href="https://www.je-roule-en-electrique.fr/combien-de-temps-faut-il-pour-recharger-mon-vehicule-au-quotidien-703#Test%204"
          target="_blank"
          rel="noreferrer noopener"
        >
          simule le temps de recharge des voitures
        </a>
      </p>
    ),
  },
  [Actions.Name.Elec4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec4} />,
    description: (
      <p>
        Recharger en électrique, c'est facile, &nbsp;
        <a href="https://fr.chargemap.com/map" target="_blank" rel="noreferrer noopener">
          localise les bornes à proximité
        </a>
      </p>
    ),
  },
  [Actions.Name.Elec5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec5} />,
    description: (
      <a
        href="https://www.je-roule-en-electrique.fr/des-avantages-economiques-au-quotidien-15"
        target="_blank"
        rel="noreferrer noopener"
      >
        Cou-cou-courroie ? Passe à l'électrique et économise 4000€ d'entretien (en moyenne)
      </a>
    ),
  },
  [Actions.Name.Elec6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec6} />,
    description: (
      <a
        href="https://www.je-roule-en-electrique.fr/voitures-electriques?field_number_target_id=47&field_car_type_target_id=35"
        target="_blank"
        rel="noreferrer noopener"
      >
        L'électrique, c'est gagné mais je choisis comment ?
      </a>
    ),
  },
  [Actions.Name.Elec7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec7} />,
    description: <p>Envie d'entendre ce qui t'entoure, découvre le silence d'une voiture électrique</p>,
  },
  [Actions.Name.Elec8]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec8} />,
    description: <p>La qualité de l'air te concerne ? Réduis ton impact et passe à l'électrique</p>,
  },
  [Actions.Name.Elec9]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Elec9} />,
    description: (
      <p>
        Le démarrage en côte n'est pas ton ami ? Et si tu testais la voiture électrique qui démarre au quart de tour !
      </p>
    ),
  },
  [Actions.Name.Carpooling1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling1} />,
    description: (
      <p>
        Tu connais le covoit' pour partir en vacances et si tu le testais pour aller travailler? Découvre les trajets
        disponibles sur &nbsp;
        <a
          href="https://www.iledefrance-mobilites.fr/titres-et-tarifs/supports/smartphone"
          target="_blank"
          rel="noreferrer noopener"
        >
          l'application Ile de France Mobilités / covoiturage
        </a>
      </p>
    ),
  },
  [Actions.Name.Carpooling2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling2} />,
    description: (
      <a
        href="https://www.ecologie.gouv.fr/covoiturage-en-france-avantages-et-reglementation-en-vigueur"
        target="_blank"
        rel="noreferrer noopener"
      >
        100€ à gagner en testant le covoiturage, ça te dit ?
      </a>
    ),
  },
  [Actions.Name.Carpooling3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling3} />,
    description: (
      <a
        href="https://www.ecologie.gouv.fr/sites/default/files/DE_4p_covoiturage-v4-050722_SH_OK.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        Plutôt que d'être 1.43 (taux d'occupation moyen en France) par voiture, être 2 ou 3 divise les coûts! Tu
        m'emmènes ?
      </a>
    ),
  },
  [Actions.Name.Carpooling4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling4} />,
    description: (
      <p>Être plusieurs en voiture, c'est réduire d'autant les voitures qui circulent et désengorger le trafic !</p>
    ),
  },
  [Actions.Name.Carpooling5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling5} />,
    description: <p>Covoiturer, c'est partager un moment convivial et le matin, ça n'engage à rien !</p>,
  },
  [Actions.Name.Carpooling6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling6} />,
    description: (
      <p>
        Covoiturer, c'est limiter le nombre de voitures sur les routes et &nbsp;
        <a
          href="https://www.interieur.gouv.fr/actualites/communiques/chiffres-de-securite-routiere-en-2020-baisse-exceptionnelle-de-mortalite"
          target="_blank"
          rel="noreferrer"
        >
          réduire le nombre d'accidents!
        </a>
      </p>
    ),
  },
  [Actions.Name.Carpooling7]: {
    description: <p>Avec Klaxit ou Karos, le retour maison est garanti.</p>,
  },
  [Actions.Name.Carpooling8]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling8} />,
    description: (
      <a
        href="https://barometremobilites-quotidien.org/wp-content/uploads/2022/03/TT-rapport-mobilite-2022_VF29mars.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        Covoiturer, c'est aussi offrir un trajet à celui qui n'en a pas. En France, 1 personne sur 4 a refusé un emploi
        ou une formation pour des raisons de mobiité.
      </a>
    ),
  },
  [Actions.Name.Carpooling9]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling9} />,
    description: <p>Près d'un Français sur 3 covoiture. Tu t'y mets demain ?</p>,
  },
  [Actions.Name.Carpooling10]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling10} />,
    description: <p>En covoiturant, tu divises tes émissions de polluants et tu améliores la qualité de l'air !</p>,
  },
  [Actions.Name.Carpooling11]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling11} />,
    description: (
      <p>
        Covoiturer, c'est aussi se faciliter le stationnement et réduire la place de la voiture dans l'espace public !
      </p>
    ),
  },
  [Actions.Name.Carpooling12]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Carpooling12} />,
    description: <p>Covoiturer, c'est adopter la flexibilité et ne pas attendre le bus !</p>,
  },
  [Actions.Name.Coworking]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Coworking} />,
    description: (
      <p>
        Et si ton nouveau bureau était à proximité ? Les espaces de co-working sont dispo
        <a href="https://www.neo-nomade.com/" target="_blank" rel="noreferrer noopener">
          https://www.neo-nomade.com/
        </a>
      </p>
    ),
  },
  [Actions.Name.EcoDriving1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving1} />,
    description: (
      <p>
        L'éco-conduite, c'est réduire sa consommation (et son budget) mais aussi ses émissions. &nbsp;
        <a href="https://www.wenow.com/nos-offres/solution-connectee/" target="_blank" rel="noreferrer noopener">
          Wenow
        </a>
        &nbsp; est un boîtier d'écoconduite pour agir au volant et compenser vos émissions (déjà réduites)
      </p>
    ),
  },
  [Actions.Name.EcoDriving2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving2} />,
    description: (
      <p>
        Réduire sa consommation, c'est ne pas démarrer la voiture à froid. Avant le départ, laisser le moteur tourner un
        peu. Ainsi, éviter une surconsommation de carburant sur les premiers kilomètres parcourus.
      </p>
    ),
  },
  [Actions.Name.EcoDriving3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving3} />,
    description: (
      <p>
        Au volant, limiter ses émissions, c'est éviter d'avoir une conduite trop brutale et nerveuse. L'air te dit
        merci. Ton porte-monnaire aussi.
      </p>
    ),
  },
  [Actions.Name.EcoDriving4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving4} />,
    description: (
      <p>La vitesse constante au volant, c'est la meilleure façon de limiter sa consommation et son budget</p>
    ),
  },
  [Actions.Name.EcoDriving5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving5} />,
    description: (
      <p>
        Envie de tester de rouler en électrique? Découvre les offres
        <a
          href="https://www.paris.fr/pages/mobilib-une-voiture-quand-j-en-ai-besoin-17318"
          target="_blank"
          rel="noreferrer noopener"
        >
          d'autopartage électrique !
        </a>
        &nbsp; En plus de libérer de l’espace public auparavant dédié au stationnement, cela réduit tes émissions et te
        permet de changer de voiture en toute liberté !
      </p>
    ),
  },
  [Actions.Name.EcoDriving6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving6} />,
    description: <p>Réduire ta vitesse, c'est aussi une façon de réduire tes risques au volant !</p>,
  },
  [Actions.Name.EcoDriving7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.EcoDriving7} />,
    description: <p>Pour une minute d'arrêt, couper le moteur, c'est éviter de polluer à l'arrêt.</p>,
  },
  [Actions.Name.Mobility1]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility1} />,
    description: (
      <p>
        <a href="https://citymapper.com/webapp" target="_blank" rel="noreferrer noopener">
          Citymapper
        </a>
        , l'app pour aller d'un point A à un point B sans lever le nez
      </p>
    ),
  },
  [Actions.Name.Mobility2]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility2} />,
    description: (
      <a
        href="https://www.statistiques.developpement-durable.gouv.fr/comment-les-francais-se-deplacent-ils-en-2019-resultats-de-lenquete-mobilite-des-personnes#:~:text=Cela%20repr%C3%A9sente%20en%20moyenne%203,de%20plus%20qu'en%202008."
        target="_blank"
        rel="noreferrer noopener"
      >
        3 déplacements par jour, c'est la moyenne en France, et s'ils ne polluaient plus ?
      </a>
    ),
  },
  [Actions.Name.Mobility3]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility3} />,
    description: (
      <p>
        La voiture individuelle représente à elle seule plus de 15% des émissions de gaz à effet de serre en France. Se
        déplacer autrement, c'est polluer moins.
      </p>
    ),
  },
  [Actions.Name.Mobility4]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility4} />,
    description: (
      <p>
        25% des français ont refusé un emploi ou une formation pour des problématiques de mobilité.&nbsp;
        <a
          href="https://barometremobilites-quotidien.org/wp-content/uploads/2022/03/TT-rapport-mobilite-2022_VF29mars.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          Covoiturer c'est aussi offrir une mobilité à ceux qui n'en ont pas.
        </a>
      </p>
    ),
  },
  [Actions.Name.Mobility5]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility5} />,
    description: (
      <p>
        <a
          href="https://www.latribune.fr/economie/france/en-14-ans-la-distance-domicile-travail-s-est-allongee-de-deux-kilometres-583520.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          En 14 ans, la distance domicile / travail a augmenté de 2 km.
        </a>
        &nbsp; A pied, ça fait loin, à vélo c'est 8 minutes
      </p>
    ),
  },
  [Actions.Name.Mobility6]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility6} />,
    description: (
      <a
        href="https://www.ecologie.gouv.fr/sites/default/files/DE_4p_covoiturage-v4-050722_SH_OK.pdf"
        target="_blank"
        rel="noreferrer noopener"
      >
        Le nombre moyen de passagers est de 1.43 par véhicule pour les déplacements locaux, se déplacer autrement, c'est
        limiter la bitumisation des sols pour la voiture.
      </a>
    ),
  },
  [Actions.Name.Mobility7]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility7} />,
    description: (
      <p>Ton temps de trajet ne serait-il pas le temps d'un podcast ou le temps de changer de mode de déplacement ?</p>
    ),
  },
  [Actions.Name.Mobility8]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility8} />,
    description: (
      <a href="https://pubmed.ncbi.nlm.nih.gov/25152507/" target="_blank" rel="noreferrer">
        Pour être 40% moins stressé en arrivant au boulot, mieux vaut pédaler !
      </a>
    ),
  },
  [Actions.Name.Mobility9]: {
    image: <ProcessedImage type="actions" name={Actions.Name.Mobility9} />,
    description: (
      <p>
        Le trafic routier est responsable de la
        <a
          href="https://www.cerema.fr/fr/actualites/emissions-routieres-polluants-atmospheriques-courbes"
          target="_blank"
          rel="noreferrer noopener"
        >
          moitié des émissions de NOx
        </a>
        . Pas envie d'y contribuer? Teste la marche à pied!
      </p>
    ),
  },
};
