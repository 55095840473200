import { Route } from 'react-router-dom';
import * as TE from 'fp-ts/TaskEither';
import impactPage from '@modules/impact/ImpactPage';
import impactDetail from '@modules/impact/ImpactDetail';
import { createRoute, defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { HomeService } from '@modules/home/service';
import { ImpactService } from '@modules/impact/service';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';

export const impactLoader = defineLoader({
  id: 'impact',
  handler: () =>
    pipe(
      sequenceS(TE.ApplyPar)({
        impact: ImpactService.getImpacts(),
        home: HomeService.getHome(),
      }),
      httpTaskToResponseTask,
    ),
  //handler: () => httpTaskToResponseTask(ImpactService.getImpacts()),
});

const impactRoute = defineRoute({
  loader: impactLoader,
});

const impactRoutes = (
  <Route path="impact" {...createRoute(impactRoute)}>
    <Route index {...createRoute(impactPage)} />
    <Route path="detail" {...createRoute(impactDetail)} />
  </Route>
);

export default impactRoutes;
