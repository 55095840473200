import React, { FC, PropsWithChildren } from 'react';
import { Weeks } from '@modules/weeks/model';
import { ActionIcon, Paper, Text } from '@mantine/core';

import * as Styled from './DayTravelCard.styles';
import { MChevronsLeft, MChevronsRight } from '@styles/icons';
import { WeeksUtils } from '@modules/weeks/utils';

interface DayTravelCardProps {
  day: Weeks.TravelDays;
  direction: Weeks.TravelDirection;
  type?: Weeks.TravelType;
  loading: boolean;
  onNavigateNext: () => void;
  onNavigatePrev: () => void;
}

const DayTravelCard: FC<PropsWithChildren<DayTravelCardProps>> = ({
  day,
  direction,
  type,
  loading,
  onNavigateNext,
  onNavigatePrev,
  children,
}) => {
  const { canGoPrev, canGoNext } = WeeksUtils.getAllowedNavigation(day, direction, type);

  return (
    <Paper py={25} px={15} radius={8} withBorder={false}>
      <Styled.DayTravelHeader>
        <div>
          {canGoPrev ? (
            <ActionIcon size={24} onClick={onNavigatePrev} disabled={loading}>
              <MChevronsLeft />
            </ActionIcon>
          ) : null}
        </div>
        <Text fz="lg" fw={700} color="violet.6" tt="uppercase" align="center">
          {Weeks.travelDaysLabel[day]}
        </Text>
        <div>
          {canGoNext ? (
            <ActionIcon size={24} onClick={onNavigateNext} disabled={loading}>
              <MChevronsRight />
            </ActionIcon>
          ) : null}
        </div>
      </Styled.DayTravelHeader>

      <Text fz="xl" mt="lg" fw={300}>
        {direction === Weeks.TravelDirection.Outward ? 'Trajet aller' : 'Trajet retour'}
      </Text>

      {children}
    </Paper>
  );
};

export default DayTravelCard;
