import { httpService } from '@core/http';

export namespace SurveyService {
  export function validateSurvey() {
    return httpService.post('/home/survey');
  }

  export function validateEndSurvey() {
    return httpService.post('/home/end-survey');
  }
}
