import { HttpError, httpService, HttpStatusCode, HttpTask } from '@core/http';
import { Auth } from '@modules/auth/model';
import { pipe } from 'fp-ts/function';
import * as localforage from 'localforage';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';

export namespace AuthService {
  const tokenStorage = localforage.createInstance({
    name: 'modoux',
    storeName: 'auth',
  });

  const TOKENS_STORAGE_KEY = 'tokens';

  function getAuthTokens(): TO.TaskOption<Auth.Tokens.Tokens> {
    return pipe(() => tokenStorage.getItem<Auth.Tokens.Tokens>(TOKENS_STORAGE_KEY), T.chain(TO.fromNullable));
  }

  function saveAuthTokens(tokens: Auth.Tokens.Tokens): T.Task<Auth.Tokens.Tokens> {
    return () => tokenStorage.setItem(TOKENS_STORAGE_KEY, tokens);
  }

  function deleteAuthTokens(): T.Task<void> {
    return () => tokenStorage.removeItem(TOKENS_STORAGE_KEY);
  }

  export function getBearerAuthTokens(): TO.TaskOption<Auth.Tokens.Bearer> {
    return pipe(
      getAuthTokens(),
      TO.map(({ token }) => token),
    );
  }

  export function authenticate(params: Auth.AuthenticateParams): HttpTask {
    return pipe(httpService.post<Auth.Tokens.Tokens>('/authenticate', params), TE.chainFirstTaskK(saveAuthTokens));
  }

  export function register(params: Auth.RegisterParams): HttpTask {
    return pipe(httpService.post<Auth.Tokens.Tokens>('/sign-up', params), TE.chainFirstTaskK(saveAuthTokens));
  }

  export function refreshToken(): HttpTask {
    return pipe(
      getAuthTokens(),
      TO.chainNullableK(({ refreshToken }) => refreshToken),
      TE.fromTaskOption(() => HttpError.fromStatusCode(HttpStatusCode.UNAUTHORIZED)),
      TE.chain(refreshToken => httpService.post<Auth.Tokens.Tokens>('/authenticate/refresh', { token: refreshToken })),
      TE.orElseFirstTaskK(deleteAuthTokens),
      TE.chainFirstTaskK(saveAuthTokens),
    );
  }

  export function logout(): T.Task<void> {
    return pipe(httpService.delete('/authenticate'), T.chain(deleteAuthTokens));
  }
}
