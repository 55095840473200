import { FC } from 'react';
import { HomeService } from '@modules/home/service';
import { defineRoute } from '@core/router';
import SurveyContent from '@modules/survey/components/SurveyContent';
import config from '@root/config';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { Profile } from '@modules/profile/model';
import { useProfile } from '@modules/profile/profile-loader';

function getEndFormId(profile: Profile.Profile): string {
  switch (profile.studyGroup) {
    case Profile.ProfileStugyGroup.A:
      return config.VITE_TALLY_END_GROUP_A_FORM_ID;
    case Profile.ProfileStugyGroup.B:
      return config.VITE_TALLY_END_GROUP_B_FORM_ID;
    case Profile.ProfileStugyGroup.C:
      return config.VITE_TALLY_END_GROUP_C_FORM_ID;
  }
}

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(HomeService.getHome()),
});

const SurveyEndPage: FC = () => {
  const home = useLoader<typeof loader>();
  const profile = useProfile();

  return <SurveyContent title="Titre" formId={getEndFormId(profile)} isAnswered={home.isEndSurveyAnswered} />;
};

export const surveyEndPage = defineRoute({
  component: SurveyEndPage,
  loader,
});

export default surveyEndPage;
