import React, { FC } from 'react';
import { HomeService } from '@modules/home/service';
import { defineRoute } from '@core/router';
import config from '@root/config';
import SurveyContent from '@modules/survey/components/SurveyContent';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { Navigate } from 'react-router-dom';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(HomeService.getHome()),
});

const SurveyStartPage: FC = () => {
  const home = useLoader<typeof loader>();

  if (home.isSurveyAnswered) return <Navigate to="/" />;
  else
    return (
      <SurveyContent
        title="Vos habitudes de mobilité"
        formId={config.VITE_TALLY_START_FORM_ID}
        isAnswered={home.isSurveyAnswered}
      />
    );
};

export const surveyRoute = defineRoute({
  component: SurveyStartPage,
  loader,
});

export default surveyRoute;
