import { Actions } from '@modules/actions/model';
import { useState } from 'react';
import { ActionsUtils } from '@modules/actions/utils';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { useSendTask } from '@core/http/hooks';
import { ActionsService } from '@modules/actions/service';
import { useProfile } from '@modules/profile/profile-loader';

export function useActionsInteractions(apiActions: Array<Actions.ApiDetail>) {
  const profile = useProfile();

  const [actions, setActions] = useState(() => ActionsUtils.apiActionsToActionsDetail(apiActions));

  const [, submitAnswer] = useSendTask(ActionsService.answerAction);

  const handleAnswerAction = (action: Actions.Detail, answer: Actions.ActionAnswer) => {
    setActions(old =>
      pipe(
        old,
        A.map(a => {
          if (a.id === action.id) {
            return {
              ...a,
              answer,
              pendingNotOk: answer === Actions.ActionAnswer.NotOk,
            };
          }

          return a;
        }),
      ),
    );

    submitAnswer(action, answer, profile);
  };

  return {
    actions,
    handleAnswerAction,
  };
}
