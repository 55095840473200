import { FC, forwardRef } from 'react';
import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { MIconEye, MIconEyeOff } from '@styles/icons';

const CustomPasswordInputVisibilityIcon: FC<{ reveal: boolean; size: number }> = ({ reveal }) =>
  reveal ? <MIconEyeOff color="black" /> : <MIconEye color="black" />;

export const CustomPasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => (
  <PasswordInput ref={ref} {...props} visibilityToggleIcon={CustomPasswordInputVisibilityIcon} />
));
