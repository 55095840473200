import React, { FC } from 'react';
import { defineRoute, useBack } from '@core/router';
import { Box } from '@mantine/core';
import { defineAction, useAction } from '@core/router/action';
import { Profile } from '@modules/profile/model';
import { ProfileService } from '@modules/profile/service';

import PageTitle from '@layout/title/PageTitle';
import UpdateProfileForm from '@modules/profile/components/form/UpdateProfileForm';
import { useProfile } from '@modules/profile/profile-loader';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const actions = {
  update: defineAction({
    type: 'update',
    payload: Profile.UpdateProfileParams,
    handler: ({ payload }) => ProfileService.updateProfile(payload),
  }),
};

const UpdateProfilePage: FC = () => {
  const profile = useProfile();

  const goBack = useBack();

  const [loading, update] = useAction(actions.update);

  const handleSubmit = (params: Profile.UpdateProfileParams) =>
    pipe(
      () => update(params),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Mes informations personnelles" />

      <UpdateProfileForm profile={profile} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const updateProfileRoute = defineRoute({
  component: UpdateProfilePage,
  actions,
});

export default updateProfileRoute;
