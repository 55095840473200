import React, { FC, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { MantineColor, SystemProp, Text } from '@mantine/core';

import * as Styled from './ProfileListItem.styles';

interface ProfileListItemProps {
  title: string;
  to: To;
  icon: ReactNode;
  iconColor?: SystemProp<MantineColor>;
}

const ProfileListItem: FC<ProfileListItemProps> = ({ title, to, icon, iconColor }) => {
  return (
    <Styled.ProfileListItemContainer component={Link} to={to} radius={8} bg="pink.0" withBorder={false}>
      <Text size="xs">{title}</Text>

      <Styled.ProfileListItemIconWrapper component="span" c={iconColor}>
        {icon}
      </Styled.ProfileListItemIconWrapper>
    </Styled.ProfileListItemContainer>
  );
};

export default ProfileListItem;
