import styled from '@emotion/styled';

export const HomeActionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeActionsItems = styled.div`
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  ${props => props.theme.fn.smallerThan('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const HomeActionsEmpty = styled.div`
  margin-top: 15px;
  padding: 15px;
  background-color: rgba(224, 224, 224, 0.28);
  border-radius: 8px;
`;
