import React, { FC, useMemo } from 'react';

const images = import.meta.glob(['../../../assets/processed/(actions|projects)/*.{jpg,webp}'], {
  eager: true,
  as: 'url',
});

interface ProcessedImageProps {
  type: 'actions' | 'projects';
  name: string;
  alt?: string;
  className?: string;
}

const ProcessedImage: FC<ProcessedImageProps> = ({ type, name, alt, className }) => {
  const { webp, webp2, jpg, jpg2 } = useMemo(() => {
    const getImage = (fm: 'jpg' | 'webp', dpr: 1 | 2) => {
      const imagePath = `../../../assets/processed/${type}/${name}${dpr === 2 ? '-2x' : ''}.${fm}`;

      const image = images[imagePath];

      if (!image) {
        throw new Error(`Cannot find processed image ${imagePath}`);
      }

      return image;
    };

    return {
      webp: getImage('webp', 1),
      webp2: getImage('webp', 2),
      jpg: getImage('jpg', 1),
      jpg2: getImage('jpg', 2),
    };
  }, [name, type]);

  return (
    <picture className={className}>
      <source type="image/webp" srcSet={`${webp} 1x, ${webp2} 2x`} />
      <img src={jpg} srcSet={`${jpg} 1x, ${jpg2} 2x`} alt={alt} loading="lazy" />
    </picture>
  );
};

export default ProcessedImage;
