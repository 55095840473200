import React, { forwardRef, useDeferredValue, useMemo, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { Box, Group, Loader as LoaderComponent, Select, SelectItem, SelectProps, Text } from '@mantine/core';
import { useDebouncedCallback } from 'use-debounce';
import { constVoid, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';
import * as S from 'fp-ts/string';
import * as O from 'fp-ts/Option';
import { useDisclosure } from '@mantine/hooks';
import config from '@root/config';
import { Address } from '@shared/modules/address/model';
import { MIconEdit, MIconSearch, MIconStar } from '@styles/icons';
import { some } from 'fp-ts/Option';

function getTransportType(type: unknown, prediction: google.maps.places.AutocompletePrediction): O.Option<string> {
  switch (type) {
    case 'bus_station':
      return some(`${prediction.description} (bus)`);
    case 'light_rail_station':
      return some(`${prediction.description} (tramway)`);
    case 'subway_station':
      return some(`${prediction.description} (métro)`);
    case 'transit_station':
      return some(`${prediction.description} (station)`);
    case 'train_station':
      return some(`${prediction.description} (gare)`);
    default:
      return some(prediction.description);
  }
}

function getDescription(prediction: google.maps.places.AutocompletePrediction): AddressSelectorItem {
  const label = pipe(
    A.findFirstMap(val => getTransportType(val, prediction))(prediction.types),
    O.fold(
      () => prediction.description,
      label => label,
    ),
  );

  return {
    value: prediction.place_id,
    label: label,
  };
}

const loader = new Loader({
  apiKey: config.VITE_GOOGLE_MAPS_KEY,
  language: 'fr',
  region: 'FR',
  libraries: ['places'],
});

interface AddressSelectorItem extends SelectItem {
  label: string;
  favorite?: boolean;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  favorite?: string;
  selected?: boolean;
}

const CustomSelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, favorite, selected, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap spacing={7}>
        {favorite ? (
          <Box
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.violet[6],
            })}
          >
            <MIconStar />
          </Box>
        ) : null}

        <Text>{label}</Text>
      </Group>
    </div>
  );
});

const eqAddressSelectorItem = pipe(
  S.Eq,
  Eq.contramap((item: AddressSelectorItem) => item.value),
);

interface AddressSelectorProps extends Omit<SelectProps, 'value' | 'onChange' | 'data'> {
  value?: Address.Address | null;
  favorites?: Array<Address.Address>;
  onChange?: (item: Address.Address) => void;
  icon?: 'search' | 'edit';
}

const AddressSelector = forwardRef<HTMLInputElement, AddressSelectorProps>(
  ({ value, favorites = [], onChange = constVoid, icon, disabled, ...selectProps }, ref) => {
    const [loading, loadingHandler] = useDisclosure(false);

    const [places, setPlaces] = useState<Array<AddressSelectorItem>>([]);

    const [search, setSearch] = useState<string>(value?.label ?? '');

    const handleQueryGoogleMapsPlaces = (search: string) => {
      if (search && search !== value?.label) {
        loadingHandler.open();

        pipe(
          () => loader.load(),
          T.chain(
            () => () =>
              new google.maps.places.AutocompleteService().getPlacePredictions({
                input: search,
                language: 'fr',
                region: 'FR',
                componentRestrictions: { country: 'fr' },
              }),
          ),
          T.chainIOK(res => () => {
            setPlaces(res.predictions.map(prediction => getDescription(prediction)));

            loadingHandler.close();
          }),
        )();
      } else {
        setPlaces([]);
      }
    };

    const debouncedHandleSearch = useDebouncedCallback(handleQueryGoogleMapsPlaces, 400);

    const handleSearchChange = (search: string) => {
      const newSearch = search.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');

      setSearch(newSearch);
      debouncedHandleSearch(newSearch);
    };

    const debouncedLoading = useDeferredValue(loading);

    const data = useMemo(
      () =>
        pipe(
          [
            ...favorites.map<AddressSelectorItem>(item => ({
              value: item.placeId,
              label: item.label,
              favorite: true,
            })),
            ...(value && value.label && value.placeId ? [{ value: value.placeId, label: value.label }] : []),
            ...places,
          ],
          A.uniq(eqAddressSelectorItem),
        ),
      [favorites, places, value],
    );

    const handleChange = (value: string) => {
      const item = pipe(
        data,
        A.findFirst(item => item.value === value),
        O.toNullable,
      );

      if (item) {
        onChange({ label: item.label, placeId: Address.PlaceId.parse(item.value) });
      }
    };

    const handleFilterTest = (value: string, item: SelectItem) => {
      const normalizedSearch = value
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();
      const normalizedValue =
        item.label
          ?.normalize('NFD')
          .replace(/\p{Diacritic}/gu, '')
          .toLowerCase() ?? '';
      return normalizedValue.includes(normalizedSearch);
    };

    return (
      <Select
        {...selectProps}
        ref={ref}
        value={value?.placeId ?? null}
        styles={{
          item: { padding: '10px' },
          input: { background: '#fff !important', opacity: '1 !important', color: '#000 !important' },
        }}
        disabled={disabled}
        data={data}
        searchable
        searchValue={search}
        onSearchChange={handleSearchChange}
        onChange={handleChange}
        itemComponent={CustomSelectItem}
        withinPortal
        filter={handleFilterTest}
        filterDataOnExactSearchMatch={false}
        rightSection={
          debouncedLoading ? (
            <LoaderComponent size={20} />
          ) : icon === 'edit' && !disabled ? (
            <MIconEdit />
          ) : (
            <MIconSearch />
          )
        }
      />
    );
  },
);

export default AddressSelector;
