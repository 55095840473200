import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import surveyStartRoute from '@modules/survey/SurveyStartPage';
import surveyStartCompletedRoute from '@modules/survey/SurveyStartCompletedPage';
import surveyEndRoute from '@modules/survey/SurveyEndPage';
import surveyEndCompletedRoute from '@modules/survey/SurveyEndCompletedPage';

const surveyRoutes = (
  <Route path="survey">
    <Route path="start" {...createRoute(surveyStartRoute)} />
    <Route path="start/completed" {...createRoute(surveyStartCompletedRoute)} />
    <Route path="end" {...createRoute(surveyEndRoute)} />
    <Route path="end/completed" {...createRoute(surveyEndCompletedRoute)} />
  </Route>
);

export default surveyRoutes;
