import { httpService, HttpTask } from '@core/http';
import { Actions } from '@modules/actions/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import ReactGA from 'react-ga4';
import { Profile } from '@modules/profile/model';

export namespace ActionsService {
  const URI = '/actions';

  export function getActions(): HttpTask<Array<Actions.ApiDetail>> {
    return httpService.get(URI);
  }

  export function answerAction({ id, name }: Actions.Detail, answer: Actions.ActionAnswer, profile: Profile.Profile) {
    return pipe(
      httpService.post(`${URI}/${id}/answer`, { answer }),
      TE.chainFirstIOK(() => () => {
        ReactGA.event({
          category: name,
          action: answer === Actions.ActionAnswer.Ok ? 'like' : 'dislike',
          label: profile.studyId,
        });
      }),
    );
  }
}
