import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { WeeksService } from '@modules/weeks/service';
import { defineRoute } from '@core/router';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import WeekTravels from '@modules/weeks/components/update/week/WeekTravels';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';

const loader = defineLoader({
  handler: () =>
    pipe(
      sequenceS(TE.ApplyPar)({
        week: WeeksService.getWeeks(true),
        vehicles: ProfileVehiclesService.getProfileVehicles(),
      }),
      httpTaskToResponseTask,
    ),
});

const ProfileWeeksDetailPage: FC = () => {
  const { week, vehicles } = useLoader<typeof loader>();

  return (
    <>
      <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
        <PageTitle title="Ma semaine type" />
      </Box>

      <Box m="0 auto" sx={{ maxWidth: 700 }}>
        <WeekTravels week={week} vehicles={vehicles} readonly />
      </Box>
    </>
  );
};

const profileWeeksDetailRoute = defineRoute({
  component: ProfileWeeksDetailPage,
  loader,
});

export default profileWeeksDetailRoute;
