import { httpService, HttpTask } from '@core/http';
import { ProfileTravels } from '@modules/profile/travels/model';

export namespace ProfileTravelsService {
  const URI = '/travels';

  export function getProfileTravels(): HttpTask<Array<ProfileTravels.ProfileTravel>> {
    return httpService.get(URI);
  }

  export function createProfileTravel(
    params: ProfileTravels.ProfileTravelParams,
  ): HttpTask<ProfileTravels.ProfileTravel> {
    return httpService.post(URI, params);
  }

  export function getProfileTravel(id: ProfileTravels.ProfileTravelId): HttpTask<ProfileTravels.ProfileTravel> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateProfileTravel(
    id: ProfileTravels.ProfileTravelId,
    params: ProfileTravels.ProfileTravelParams,
  ): HttpTask<ProfileTravels.ProfileTravel> {
    return httpService.post(`${URI}/${id}`, params);
  }
}
