import React, { FC } from 'react';
import { Actions } from '@modules/actions/model';
import { Anchor, Box, Text, Title } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Styled from './HomeActions.styles';
import ActionCard from '@shared/components/card/ActionCard';
import { Link } from 'react-router-dom';
import { useActionsInteractions } from '@modules/actions/hooks';

interface HomeActionsProps {
  currentActions: Array<Actions.ApiDetail>;
  linkActions: string | null;
  linkActionsLabel: string | null;
}

const HomeActions: FC<HomeActionsProps> = ({ currentActions, linkActions, linkActionsLabel }) => {
  const { actions, handleAnswerAction } = useActionsInteractions(currentActions);

  return (
    <Box pt={45}>
      <Title order={2}>
        Je passe à{' '}
        <Box component="span" c="violet.6">
          l'action
        </Box>
      </Title>

      {renderOptional(
        NEA.fromArray(actions),
        actions => (
          <Styled.HomeActionsContent>
            <Styled.HomeActionsItems>
              {actions.map(action => (
                <ActionCard key={action.id} action={action} onAnswer={handleAnswerAction} />
              ))}
            </Styled.HomeActionsItems>

            <Anchor component={Link} mt={15} to="actions">
              Retrouver toutes les idées
            </Anchor>
          </Styled.HomeActionsContent>
        ),
        () => (
          <Styled.HomeActionsEmpty>
            <Text size={12} weight={400} mb={8}>
              Oups ! Nous n'avons pas encore assez d'informations pour te proposer des actions personnalisées :)
            </Text>

            {linkActions && linkActionsLabel ? (
              <Anchor component={Link} c="black" fz={14} fw={600} to={linkActions}>
                {linkActionsLabel}
              </Anchor>
            ) : null}
          </Styled.HomeActionsEmpty>
        ),
      )}
    </Box>
  );
};

export default HomeActions;
