import styled from '@emotion/styled';

export const VehiclesPlaceholderImageContainer = styled.div`
  padding-top: 40px;
  text-align: center;

  img {
    max-width: 100%;
  }
`;
