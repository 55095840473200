import { httpService, HttpTask } from '@core/http';
import { ProfileVehicles } from '@modules/profile/vehicules/model';

export namespace ProfileVehiclesService {
  const URI = '/vehicles';

  export function getProfileVehicles(): HttpTask<Array<ProfileVehicles.ProfileVehicle>> {
    return httpService.get(URI);
  }

  export function createProfileVehicle(
    params: ProfileVehicles.ProfileVehicleParams,
  ): HttpTask<ProfileVehicles.ProfileVehicle> {
    return httpService.post(URI, params);
  }

  export function getProfileVehicle(id: ProfileVehicles.ProfileVehicleId): HttpTask<ProfileVehicles.ProfileVehicle> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateProfileVehicle(
    id: ProfileVehicles.ProfileVehicleId,
    params: ProfileVehicles.ProfileVehicleParams,
  ): HttpTask<ProfileVehicles.ProfileVehicle> {
    return httpService.post(`${URI}/${id}`, params);
  }
}
