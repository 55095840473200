import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import profileTravelsListRoute from '@modules/profile/travels/ListPage';
import createProfileTravelRoute from '@modules/profile/travels/CreatePage';
import profileTravelDetailRoute from '@modules/profile/travels/DetailPage';

const profileTravelsRoutes = (
  <Route path="travels">
    <Route index {...createRoute(profileTravelsListRoute)} />
    <Route path="new" {...createRoute(createProfileTravelRoute)} />
    <Route path=":id" {...createRoute(profileTravelDetailRoute)} />
  </Route>
);

export default profileTravelsRoutes;
