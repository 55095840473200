import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import profileWeeksListRoute from '@modules/profile/weeks/ListPage';
import profileWeeksDetailRoute from '@modules/profile/weeks/DetailPage';

const profileWeeksRoutes = (
  <Route path="weeks">
    <Route index {...createRoute(profileWeeksListRoute)} />
    <Route path="update" {...createRoute(profileWeeksDetailRoute)} />
  </Route>
);

export default profileWeeksRoutes;
