import { Route } from 'react-router-dom';
import BackgroundLayout from '@layout/background/BackgroundLayout';
import { createRoute } from '@core/router';
import infosPage from '@modules/infos/InfosPage';

const infosRoutes = (
  <Route path="infos" element={<BackgroundLayout color="blue" />}>
    <Route index {...createRoute(infosPage)} />
  </Route>
);

export default infosRoutes;
