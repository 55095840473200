import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent, Paper, PaperProps } from '@mantine/core';

export const ActionCardContainer = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  border-radius: 8px;
  text-decoration: none;
  color: ${props => props.theme.colors.violet[6]};
  border: none;
  overflow: hidden;

  &.active {
    border: 2px solid ${props => props.theme.colors.orange[7]};
  }
`);

export const ActionCardTop = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionCardImage = styled.div`
  position: relative;
  flex: 0 0 104px;
  width: 104px;
  height: 104px;

  > picture > img {
    border-radius: 8px 0 0 0;
  }
`;

export const ActionCardImageMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 104px;
  height: 104px;
  background-color: ${props => props.theme.fn.rgba('#965CCF', 0.5)};
`;

export const ActionCardDescription = styled.div`
  padding: 10px 15px;
  font-size: 12px;

  p {
    margin: 0;
    line-height: 16px;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const ActionCardBottom = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const ActionCardBottomItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionCardBottomVioletButton = createPolymorphicComponent<'button', ButtonProps>(styled(Button)`
  padding: 6px 12px;
  width: max-content;
  height: 30px;
  color: ${props => props.theme.colors.violet[6]};
  background-color: ${props => props.theme.white};
  text-decoration: underline;

  &.active {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.colors.violet[6]};
    text-decoration: none;
  }

  &:not(.active):disabled {
    color: ${props => props.theme.colors.violet[6]};
    background-color: ${props => props.theme.white};
    text-decoration: underline;
    opacity: 0.4;
  }
`);

export const ActionCardBottomOrangeButton = createPolymorphicComponent<'button', ButtonProps>(styled(Button)`
  padding: 6px 12px;
  width: max-content;
  height: 30px;
  color: ${props => props.theme.colors.orange[7]};
  background-color: ${props => props.theme.white};
  text-decoration: underline;

  &.active {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.colors.orange[7]};
    text-decoration: none;
  }

  &:not(.active):disabled {
    color: ${props => props.theme.colors.orange[7]};
    background-color: ${props => props.theme.white};
    text-decoration: underline;
    opacity: 0.4;
  }
`);

export const ActionCardNotShow = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.fn.darken(props.theme.colors.violet[6], 0.28)};
  border-radius: 8px;
`;
