import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { ProjectList, ProjectName } from '@modules/project/model';
import Card from '@shared/components/card/Card';
import Pages from '@shared/components/pages/Pages';
import ProcessedImage from '@shared/components/processed-image/ProcessedImage';

const projectList: ProjectList = {
  [ProjectName.Ademe]: {
    image: <ProcessedImage type="projects" name="ademe" />,
    description:
      "L’ADEME : L'Agence de transition écologique participe à la construction des politiques nationales et " +
      'locales de transition écologique et a pour mission d’accélérer le passage vers une société plus sobre et ' +
      'solidaire, créatrice d’emplois, plus humaine et harmonieuse.',
    link: 'https://ademe.fr',
  },
  [ProjectName.Paris]: {
    image: <ProcessedImage type="projects" name="paris" />,
    description:
      'Ville de Paris : La Ville de Paris est la ville française la plus peuplée et son territoire compte parmi ' +
      'les plus denses en termes de population. ' +
      'Elle a engagé depuis plusieurs années une politique publique volontariste en faveur de l’amélioration ' +
      'de la qualité de l’air et de la diminution des impacts sur le climat. Celle-ci prend notamment en compte ' +
      'la sensibilisation des Parisiennes et Parisiens.',
    link: 'https://www.paris.fr',
  },
  [ProjectName.GrandParis]: {
    image: <ProcessedImage type="projects" name="grand-paris" />,
    description:
      'Métropole du Grand Paris : Créée le 1er janvier 2016, la Métropole du Grand Paris est une intercommunalité ' +
      'de 7,2 millions d’habitants qui regroupe 131 communes. ' +
      'Elle relève le défi du développement et de l’aménagement économique, social et culturel, de la protection ' +
      'de l’environnement et de l’attractivité internationale.',
    link: 'https://www.metropolegrandparis.fr',
  },
  [ProjectName.AirParif]: {
    image: <ProcessedImage type="projects" name="airparif" />,
    description:
      'Airparif : Airparif est une association agréée de surveillance de la qualité de l’air. ' +
      'Elle a pour mission de surveiller la pollution atmosphérique sur le territoire de l’Ile-de-France, ' +
      'd’informer les citoyens et d’accompagner les décideurs dans leurs politiques d’amélioration de ' +
      'la qualité de l’air.',
    link: 'https://www.airparif.asso.fr',
  },
};

const ProjectPage: FC = () => {
  return (
    <Pages
      title="le projet modoux"
      description="Modoux est une application et un programme qui vise à faciliter les changements de mobilité individuelle afin d’accompagner la baisse des émissions de polluants dans l'air, créée et pilotée par une équipe d’acteurs de la métropole parisienne."
      inverted
    >
      {Object.values(projectList).map((val, index) => (
        <Card image={val.image} description={val.description} link={val.link} key={index} />
      ))}
    </Pages>
  );
};

const projectPage = defineRoute({
  component: ProjectPage,
  loader: undefined,
});

export default projectPage;
