import React, { FC } from 'react';

import * as Styled from './VehiclesPlaceholder.styles';
import placeholderImage from '@assets/images/vehicles-placeholder.svg';
import { Button, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { MIconAdd } from '@styles/icons';
import { ProfileVehicles } from '@modules/profile/vehicules/model';

const VehiclesPlaceholder: FC = () => {
  return (
    <>
      <Styled.VehiclesPlaceholderImageContainer>
        <img width={154} height={154} src={placeholderImage} alt="placeholder" />
      </Styled.VehiclesPlaceholderImageContainer>

      <Text mt="xl">
        Oups, il n’y a rien par ici :) Si tu utilises un véhicule à moteur, à 2 ou 4 roues, c’est ici que tu peux
        renseigner ses caractéristiques.
      </Text>

      <Stack mt="lg" spacing="sm">
        <Button component={Link} to={`new/${ProfileVehicles.ProfileVehicleType.Car}`} leftIcon={<MIconAdd />}>
          Ajouter une voiture
        </Button>
        <Button component={Link} to={`new/${ProfileVehicles.ProfileVehicleType.TwoWheelers}`} leftIcon={<MIconAdd />}>
          Ajouter un 2 roues motorisé
        </Button>
      </Stack>
    </>
  );
};

export default VehiclesPlaceholder;
