import React, { FC, ReactNode } from 'react';
import * as Styled from './ImpactProgress.styles';
import { Text, ThemeIcon } from '@mantine/core';
import { ImpactType, ImpactUnit, impactUnitLabel } from '@modules/impact/model';
import { MIconPinkStar, MIconVerified } from '@styles/icons';
import { formatPercent } from '@shared/utils/percent';

function getColorLight(impactType: ImpactType) {
  switch (impactType) {
    case ImpactType.Nox:
      return 'violet';
    case ImpactType.PM:
      return 'orange';
    case ImpactType.CO2:
      return 'blue';
  }
}

interface ImpactProgressProps {
  title: string;
  current: number;
  currentPercentage: number;
  typical: number;
  objective: number;
  bestWeek: number;
  bestWeekValue: number;
  impactType: ImpactType;
  unit: ImpactUnit;
  icon: ReactNode;
  currentWeek: number;
  isGroupImpactAvailable: boolean;
  color: string;
}

function getValues(current: number, typical: number, color: string) {
  return current > typical
    ? {
        baseValue: current,
        baseColor: color,
        currentValue: typical,
        currentColor: 'white',
        percent: current !== 0 ? (typical * 100) / current : typical * 100,
      }
    : {
        baseValue: typical,
        baseColor: 'white',
        currentValue: current,
        currentColor: color,
        percent: typical !== 0 ? (current * 100) / typical : current * 100,
      };
}

const ImpactProgress: FC<ImpactProgressProps> = ({
  title,
  current,
  currentPercentage,
  typical,
  objective,
  bestWeek,
  bestWeekValue,
  impactType,
  unit,
  icon,
  currentWeek,
  isGroupImpactAvailable,
  color,
}) => {
  const { baseValue, baseColor, currentValue, currentColor, percent } = getValues(current, typical, color);

  const bestValue = (bestWeekValue * 100) / baseValue;

  return isGroupImpactAvailable ? (
    <Styled.ImpactProgressContainer>
      <Styled.ImpactProgressBase color={baseColor}>
        <Styled.ImpactProgressLabel size={18}>
          {baseValue}
          {impactUnitLabel[unit]}
        </Styled.ImpactProgressLabel>

        {bestWeek != null && bestWeek !== 0 ? (
          <Styled.ImpactProgressBest best={bestValue}>
            <Styled.ImpactProgressValue>
              <MIconPinkStar width={10} height={10} />

              <Text size={9} weight={400} py={1}>
                {formatPercent(bestWeek)}
              </Text>
            </Styled.ImpactProgressValue>
          </Styled.ImpactProgressBest>
        ) : null}

        {typical >= current ? (
          <>
            <Styled.ImpactProgressObjective objective={objective} background="#ffffff">
              <Styled.ImpactProgressValue>
                <MIconVerified />

                <Text size={9} weight={400} py={1}>
                  - {objective * 100}%
                </Text>
              </Styled.ImpactProgressValue>
            </Styled.ImpactProgressObjective>
          </>
        ) : null}
      </Styled.ImpactProgressBase>

      <Styled.ImpactProgressCurrent color={currentColor} width={percent}>
        <Styled.ImpactProgressLabel size={18}>
          {currentValue}
          {impactUnitLabel[unit]}
        </Styled.ImpactProgressLabel>

        {current > typical ? (
          <>
            <Styled.ImpactProgressObjective objective={objective} background="lightgray">
              <Styled.ImpactProgressValue>
                <MIconVerified />

                <Text size={9} weight={400} py={1}>
                  - {objective * 100}%
                </Text>
              </Styled.ImpactProgressValue>
            </Styled.ImpactProgressObjective>
          </>
        ) : null}
      </Styled.ImpactProgressCurrent>

      <Styled.ImpactProgressTop>
        <ThemeIcon color={getColorLight(impactType)} variant="light" radius="xl" size={20}>
          {icon}
        </ThemeIcon>

        <Text pl={5} transform="uppercase" size={9} weight={400} color="#363636">
          {title}
        </Text>

        {currentWeek !== 0 ? (
          <Styled.ImpactCurrentProgressPercent positive={currentPercentage > 0}>
            <Text size={9} px={2}>
              {currentPercentage <= 0 ? '👌' : '⚠️'} {formatPercent(currentPercentage)}
            </Text>
          </Styled.ImpactCurrentProgressPercent>
        ) : null}
      </Styled.ImpactProgressTop>
    </Styled.ImpactProgressContainer>
  ) : (
    <Styled.ImpactProgressContainer>
      <Styled.ImpactProgressPlaceholder>
        <Text size={16}>?</Text>
      </Styled.ImpactProgressPlaceholder>

      <Styled.ImpactProgressObjective objective={objective} background="white">
        <Styled.ImpactProgressValue>
          <MIconVerified />

          <Text size={9} weight={400} py={1}>
            - {objective * 100}%
          </Text>
        </Styled.ImpactProgressValue>
      </Styled.ImpactProgressObjective>

      <Styled.ImpactProgressTop>
        <ThemeIcon color={getColorLight(impactType)} variant="light" radius="xl" size={20}>
          {icon}
        </ThemeIcon>

        <Text pl={5} transform="uppercase" size={9} weight={400} color="#363636">
          {title}
        </Text>
      </Styled.ImpactProgressTop>
    </Styled.ImpactProgressContainer>
  );
};

export default ImpactProgress;
