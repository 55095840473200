import { Weeks } from '@modules/weeks/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as N from 'fp-ts/number';
import * as Ord from 'fp-ts/Ord';
import { ProfileAddressUtils } from '@modules/profile/address/utils';
import { Address } from '@shared/modules/address/model';
import { fpTsNonEmptyArrayToZodNonEmptyArray } from '@shared/utils/fp';
import { ProfileAddress } from '@modules/profile/address/model';

export namespace WeeksUtils {
  export const ordTravel = pipe(
    N.Ord,
    Ord.contramap((travel: Weeks.Travel) => travel.index ?? 0),
  );

  export function travelToUpdateTravelParams({ participantVehicle, ...rest }: Weeks.Travel): Weeks.UpdateTravelParams {
    return {
      ...rest,
      participantVehicleId: participantVehicle?.id,
    };
  }

  export function travelsToUpdateTravelBody(
    travels: Array<Weeks.Travel>,
    direction: Weeks.TravelDirection,
    addresses: Array<ProfileAddress.ProfileAddress>,
  ): Weeks.UpdateTravelBody {
    return pipe(
      travels,
      A.filter(travel => travel.direction === direction),
      A.sort(ordTravel),
      NEA.fromArray,
      O.fold<NEA.NonEmptyArray<Weeks.Travel>, Weeks.UpdateTravelBody>(
        () => {
          const originAddress = pipe(
            ProfileAddressUtils.getProfileAddressByType(
              addresses,
              direction === Weeks.TravelDirection.Outward ? Address.AddressType.Home : Address.AddressType.Work,
            ),
            O.map(ProfileAddressUtils.profileAddressToAddress),
            O.toNullable,
          );

          const destinationAddress = pipe(
            ProfileAddressUtils.getProfileAddressByType(
              addresses,
              direction === Weeks.TravelDirection.Outward ? Address.AddressType.Work : Address.AddressType.Home,
            ),
            O.map(ProfileAddressUtils.profileAddressToAddress),
            O.toNullable,
          );

          return {
            steps: [
              {
                address: {
                  label: originAddress?.label ?? '',
                  placeId: originAddress?.placeId ?? ('' as Address.PlaceId),
                },
                vehicleType: '' as Weeks.TransportType,
              },
            ],
            destination: {
              label: destinationAddress?.label ?? '',
              placeId: destinationAddress?.placeId ?? ('' as Address.PlaceId),
            },
          };
        },
        travels => {
          return {
            steps: pipe(
              travels,
              NEA.map(travel => ({
                id: travel.id,
                address: {
                  label: travel.originAddressLabel,
                  placeId: travel.originPlaceId,
                },
                vehicleType: travel.vehicleType,
                participantVehicleId: travel.participantVehicle?.id,
              })),
              fpTsNonEmptyArrayToZodNonEmptyArray,
            ),
            destination: {
              label: NEA.last(travels).destinationAddressLabel,
              placeId: NEA.last(travels).destinationPlaceId,
            },
          };
        },
      ),
    );
  }

  export function updateTravelBodyToUpdateTravelParams(
    body: Weeks.UpdateTravelBody,
    direction: Weeks.TravelDirection,
  ): Array<Weeks.UpdateTravelParams> {
    return pipe(
      body.steps,
      A.reduceWithIndex([] as Array<Weeks.UpdateTravelParams>, (index, travels, step) => {
        const destinationAddress = pipe(
          body.steps,
          A.lookup(index + 1),
          O.fold(
            () => body.destination,
            nextStep => nextStep.address,
          ),
        );

        return [
          ...travels,
          {
            id: step.id,
            direction,
            originAddressLabel: step.address.label,
            originPlaceId: step.address.placeId,
            destinationAddressLabel: destinationAddress.label,
            destinationPlaceId: destinationAddress.placeId,
            vehicleType: step.vehicleType,
            participantVehicleId: step.participantVehicleId,
          },
        ];
      }),
    );
  }

  export function getDaySliderIndex(week: Weeks.TravelWeek, day: Weeks.TravelDays): number {
    return pipe(
      Object.values(Weeks.TravelDays),
      // On prend les jours avant notre jour
      A.takeLeftWhile(d => d !== day),
      // On compte le nombre de slide par jours
      A.map(d => (week.travelDays[d].type === Weeks.TravelType.Office ? 2 : 1)),
      // Sum de ces nombres
      A.reduce(0, (sum, current) => sum + current),
    );
  }

  export function getAllowedNavigation(
    day: Weeks.TravelDays,
    direction: Weeks.TravelDirection,
    type?: Weeks.TravelType,
  ) {
    return {
      canGoPrev: day !== Weeks.TravelDays.Monday || direction === Weeks.TravelDirection.Return,
      canGoNext:
        day !== Weeks.TravelDays.Sunday ||
        (direction === Weeks.TravelDirection.Outward && type === Weeks.TravelType.Office),
    };
  }
}
