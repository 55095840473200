import { Actions } from '@modules/actions/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { actionsList } from '@modules/actions/list';

export namespace ActionsUtils {
  export function apiActionsToActionsDetail(actions: Array<Actions.ApiDetail>): Array<Actions.Detail> {
    return pipe(
      actions,
      A.filterMap(action =>
        pipe(
          O.fromNullable(actionsList[action.name]),
          O.map(detail => {
            const newAction: Actions.Detail = {
              ...detail,
              id: action.id,
              name: action.name,
              answer: action.answer,
            };

            return newAction;
          }),
        ),
      ),
    );
  }
}
