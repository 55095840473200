import React, { FC } from 'react';

import placeholderImage from '@assets/images/travels-placeholder.svg';

import * as Styled from './TravelsPlaceholder.styles';
import { Button, Group, Text } from '@mantine/core';
import { MIconAdd } from '@styles/icons';
import { Link } from 'react-router-dom';

const TravelsPlaceholder: FC = () => {
  return (
    <>
      <Styled.TravelsPlaceholderImageContainer>
        <img width={161} height={152} src={placeholderImage} alt="placeholder" />
      </Styled.TravelsPlaceholderImageContainer>

      <Text mt="xl">
        Oups, il n’y a rien par ici :) Tu peux ajouter des trajets fréquents pour gagner du temps au prochain diagnostic
        !
      </Text>

      <Group grow mt="lg">
        <Button component={Link} to="new" leftIcon={<MIconAdd />}>
          Ajouter un trajet
        </Button>
      </Group>
    </>
  );
};

export default TravelsPlaceholder;
