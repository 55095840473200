import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';
import PageTitle from '@layout/title/PageTitle';
import { Box, Stack } from '@mantine/core';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import VehiclesPlaceholder from '@modules/profile/vehicules/components/list/placeholder/VehiclesPlaceholder';
import ProfileListItem from '@modules/profile/components/list/ProfileListItem';
import { MIconEdit } from '@styles/icons';
import { CreateButton } from '@styles/shared';
import { Link } from 'react-router-dom';
import { ProfileVehicles } from '@modules/profile/vehicules/model';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(ProfileVehiclesService.getProfileVehicles()),
});

const ProfileVehiclesListPage: FC = () => {
  const vehicles = useLoader<typeof loader>();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Mes véhicules motorisés" />

      {renderOptional(
        NEA.fromArray(vehicles),
        vehicles => (
          <>
            <Stack spacing={8} mt={33}>
              {vehicles.map(vehicle => (
                <ProfileListItem
                  key={vehicle.id}
                  title={vehicle.name}
                  to={vehicle.id}
                  icon={<MIconEdit />}
                  iconColor="violet.6"
                />
              ))}
            </Stack>

            <Stack pt="lg" align="center" spacing={3}>
              <CreateButton component={Link} to={`new/${ProfileVehicles.ProfileVehicleType.Car}`}>
                Ajouter une voiture
              </CreateButton>

              <CreateButton component={Link} to={`new/${ProfileVehicles.ProfileVehicleType.TwoWheelers}`}>
                Ajouter un 2 roues motorisé
              </CreateButton>
            </Stack>
          </>
        ),
        () => (
          <VehiclesPlaceholder />
        ),
      )}
    </Box>
  );
};

const profileVehiclesListRoutes = defineRoute({
  component: ProfileVehiclesListPage,
  loader,
});

export default profileVehiclesListRoutes;
