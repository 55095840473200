import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={theme => ({
        body: {
          background: theme.colors.beige[0],
        },
        '#root': {
          minHeight: '100%',
        },
        '.cookie-consent': {
          '--color-primary': theme.colors.violet[6],
        },
      })}
    />
  );
};

export { GlobalStyles };
