import React, { FC, useMemo } from 'react';
import { Weeks } from '@modules/weeks/model';
import { useForm } from 'react-hook-form';
import { ProfileAddress } from '@modules/profile/address/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { WeeksUtils } from '@modules/weeks/utils';
import DayTravelCard from '@modules/weeks/components/update/day/card/DayTravelCard';
import DayTravel from '@modules/weeks/components/update/day/travel/DayTravel';
import { Anchor, Button, Group, Modal, Stack, Switch, Text, ThemeIcon } from '@mantine/core';
import { MIconTransportHomeOffice, MIconTransportOff } from '@styles/icons';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileTravels } from '@modules/profile/travels/model';
import DayTravelFavoriteSave from '@modules/weeks/components/update/day/favorite-save/DayTravelFavoriteSave';
import { useDisclosure } from '@mantine/hooks';

function getFormValues(
  day: Weeks.TravelDay,
  addresses: Array<ProfileAddress.ProfileAddress>,
): Weeks.UpdateTravelDayOutwardBody {
  const { steps, destination } = WeeksUtils.travelsToUpdateTravelBody(
    day.travels,
    Weeks.TravelDirection.Outward,
    addresses,
  );

  return {
    type: day.type,
    steps,
    destination,
  };
}

interface DayTravelOutwardSwitchProps {
  value: Weeks.TravelType;
  type: Weeks.TravelType.Home | Weeks.TravelType.Leave;
  onChange: (type: Weeks.TravelType) => void;
  readonly: boolean;
}

const DayTravelOutwardSwitch: FC<DayTravelOutwardSwitchProps> = ({ value, type, onChange, readonly }) => {
  const [confirmModalOpen, confirmTrigger] = useDisclosure(false);

  const handleChange = () => {
    if (!readonly) {
      if (value === type) {
        onChange(Weeks.TravelType.Office);
      } else {
        if (value === Weeks.TravelType.Office) {
          confirmTrigger.open();
        } else {
          onChange(type);
        }
      }
    }
  };

  const handleValidateType = () => {
    onChange(type);
    confirmTrigger.close();
  };

  return (
    <>
      <Switch
        checked={value === type}
        onChange={handleChange}
        label={
          <Group spacing={7} noWrap>
            <ThemeIcon variant="light" radius="xl">
              {type === Weeks.TravelType.Home ? <MIconTransportHomeOffice /> : <MIconTransportOff />}
            </ThemeIcon>

            <Text>{type === Weeks.TravelType.Home ? 'Je suis en télétravail' : 'Je ne travaille pas'}</Text>
          </Group>
        }
        styles={{ body: { alignItems: 'center' } }}
      />

      {confirmModalOpen ? (
        <Modal opened onClose={confirmTrigger.close} centered>
          <Text align="center">
            Êtes-vous sûr de vouloir marquer ce jour comme{' '}
            {type === Weeks.TravelType.Home ? 'télétravaillé' : 'non travaillé'} ? Vos saisies de trajet sur cette
            journée seront effacées ?
          </Text>

          <Group mt="lg" position="center">
            <Anchor component="button" onClick={confirmTrigger.close}>
              Annuler
            </Anchor>
            <Button compact onClick={handleValidateType}>
              Oui
            </Button>
          </Group>
        </Modal>
      ) : null}
    </>
  );
};

interface DayTravelOutwardProps {
  day: Weeks.TravelDay;
  addresses: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels: Array<ProfileTravels.ProfileTravel>;
  loading: boolean;
  readonly: boolean;
  onSubmit: (body: Weeks.UpdateTravelDayOutwardBody, navDirection: 'prev' | 'next') => void;
}

const DayTravelOutward: FC<DayTravelOutwardProps> = ({
  day,
  addresses,
  vehicles,
  travels,
  loading,
  readonly,
  onSubmit,
}) => {
  const values = useMemo(() => getFormValues(day, addresses), [addresses, day]);

  const { handleSubmit, watch, setValue, control } = useForm<Weeks.UpdateTravelDayOutwardBody>({
    resolver: readonly ? undefined : zodResolver(Weeks.UpdateTravelDayOutwardBody),
    values,
  });

  const type = watch('type');

  const onSubmitForm = (navDirection: 'prev' | 'next') => (body: Weeks.UpdateTravelDayOutwardBody) =>
    onSubmit(body, navDirection);

  const handleNavigateNext = handleSubmit(onSubmitForm('next'));
  const handleNavigatePrev = handleSubmit(onSubmitForm('prev'));

  const handleTypeChange = (type: Weeks.TravelType) => setValue('type', type);

  return (
    <DayTravelCard
      day={day.day}
      direction={Weeks.TravelDirection.Outward}
      type={type}
      loading={loading}
      onNavigateNext={handleNavigateNext}
      onNavigatePrev={handleNavigatePrev}
    >
      <form onSubmit={handleSubmit(onSubmitForm('next'))} noValidate>
        {type === Weeks.TravelType.Office ? (
          <DayTravel
            direction={Weeks.TravelDirection.Outward}
            control={control as any}
            addresses={addresses}
            vehicles={vehicles}
            travels={travels}
            readonly={readonly}
          />
        ) : null}

        <Stack spacing={8} mt="md">
          <DayTravelOutwardSwitch
            value={type}
            type={Weeks.TravelType.Home}
            onChange={handleTypeChange}
            readonly={readonly}
          />
          <DayTravelOutwardSwitch
            value={type}
            type={Weeks.TravelType.Leave}
            onChange={handleTypeChange}
            readonly={readonly}
          />
        </Stack>

        {type === Weeks.TravelType.Office && !readonly ? <DayTravelFavoriteSave control={control as any} /> : null}

        {!readonly ? (
          <Group grow my="lg">
            <Button type="submit" loading={loading} disabled={loading}>
              {!WeeksUtils.getAllowedNavigation(day.day, Weeks.TravelDirection.Outward, type).canGoNext
                ? 'Valider'
                : 'Trajet suivant'}
            </Button>
          </Group>
        ) : null}
      </form>
    </DayTravelCard>
  );
};

export default DayTravelOutward;
