import React, { FC } from 'react';
import LoginForm from '@modules/auth/components/login/LoginForm';
import { Auth } from '@modules/auth/model';
import * as EI from 'fp-ts/Either';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { constFalse } from 'fp-ts/function';
import AuthLayout from '@layout/auth/AuthLayout';
import { AuthService } from '@modules/auth/service';
import { renderOptional } from '@shared/utils/render';
import { Alert, Text } from '@mantine/core';
import { HttpStatusCode } from '@core/http';
import { AuthUtils } from '@modules/auth/utils';

function getErrorMessage(errorStatus: HttpStatusCode): string {
  switch (errorStatus) {
    case HttpStatusCode.UNAUTHORIZED:
      return 'Email ou mot de passe incorrect';
    case HttpStatusCode.FORBIDDEN:
      return "Vous n'avez pas rempli les étapes de la semaine type. Vous ne pouvez pas vous connecter";
    default:
      return 'Une erreur technique est survenue';
  }
}

const actions = {
  authenticate: defineAction({
    type: 'authenticate',
    payload: Auth.AuthenticateParams,
    handler: ({ payload }) => AuthService.authenticate(payload),
    flashOptions: {
      error: constFalse,
    },
    redirect: ({ request, result }) => {
      if (EI.isRight(result)) {
        return AuthUtils.getAuthenticatedRedirection(request.url);
      }

      return null;
    },
  }),
};

const LoginPage: FC = () => {
  const [loading, authenticate, error] = useAction(actions.authenticate);

  return (
    <AuthLayout title="se connecter">
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {getErrorMessage(error.status)}
          </Text>
        </Alert>
      ))}

      <LoginForm loading={loading} onSubmit={authenticate} />
    </AuthLayout>
  );
};

const loginRoute = defineRoute({
  component: LoginPage,
  actions,
});

export default loginRoute;
