import React, { FC, useMemo } from 'react';
import { useProfile } from '@modules/profile/profile-loader';
import { Navigate, useLocation } from 'react-router-dom';
import { getStringQuery } from '@shared/modules/filter';
import { parseQueries, stringifyQueries } from '@shared/utils/queries';
import SurveyForm from '@modules/survey/components/SurveyForm';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';

const PARTICIPANT_ID_QUERY_KEY = 'participant';

interface SurveyContentProps {
  title: string;
  formId: string;
  isAnswered: boolean;
}

const SurveyContent: FC<SurveyContentProps> = ({ title, formId, isAnswered }) => {
  const profile = useProfile();

  const { search } = useLocation();

  const participantId = useMemo(() => getStringQuery(parseQueries(search), PARTICIPANT_ID_QUERY_KEY), [search]);

  if (isAnswered) <Navigate to="/" />;
  // ParticipantId doit obligatoirement être en query pour être envoyé à Tally
  if (participantId !== profile.studyId) {
    return <Navigate to={{ search: stringifyQueries({ [PARTICIPANT_ID_QUERY_KEY]: profile.studyId }) }} replace />;
  }

  return (
    <Box p={30} pb={100} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title={title} />

      <SurveyForm formId={formId} title={title} />
    </Box>
  );
};

export default SurveyContent;
