import styled from '@emotion/styled';
import { createPolymorphicComponent, Paper, PaperProps } from '@mantine/core';

export const WeekDayItemContainer = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  display: grid;
  grid-template-columns: auto 1fr 24px;
  grid-column-gap: 10px;
  align-items: center;
  border: 1px solid transparent;

  &.error {
    border: 1px solid #be0000;
  }
`);

export const WeekDayItemIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 24px);
  grid-column-gap: 4px;
  color: ${props => props.theme.colors.violet[6]};

  svg {
    width: 24px;
    height: 24px;
  }
`;
