import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';

import critAir0 from '@assets/images/crit-air/crit-air-0.png';
import critAir02x from '@assets/images/crit-air/crit-air-0@2x.png';
import critAir1 from '@assets/images/crit-air/crit-air-1.png';
import critAir12x from '@assets/images/crit-air/crit-air-1@2x.png';
import critAir2 from '@assets/images/crit-air/crit-air-2.png';
import critAir22x from '@assets/images/crit-air/crit-air-2@2x.png';
import critAir3 from '@assets/images/crit-air/crit-air-3.png';
import critAir32x from '@assets/images/crit-air/crit-air-3@2x.png';
import critAir4 from '@assets/images/crit-air/crit-air-4.png';
import critAir42x from '@assets/images/crit-air/crit-air-4@2x.png';
import critAir5 from '@assets/images/crit-air/crit-air-5.png';
import critAir52x from '@assets/images/crit-air/crit-air-5@2x.png';

export namespace ProfileVehicles {
  export const ProfileVehicleId = z.string().uuid().brand<'ProfileVehicleId'>();
  export type ProfileVehicleId = z.infer<typeof ProfileVehicleId>;

  export interface ProfileVehicle {
    id: ProfileVehicleId;
    name: string;
    type: CarType | TwoWheelersType;
    critAir: CritAir | null;
  }

  export enum CarType {
    GasolineCar = 'voiture essence',
    DieselCar = 'voiture diesel',
    ElectricCar = 'voiture électrique',
    UtilityVehicle = 'véhicule utilitaire léger (<3.5T) diesel',
  }

  export const carTypeLabel: Record<CarType, string> = {
    [CarType.GasolineCar]: 'Voiture essence',
    [CarType.DieselCar]: 'Voiture diesel',
    [CarType.ElectricCar]: 'Voiture électrique',
    [CarType.UtilityVehicle]: 'Véhicule utilitaire léger',
  };

  export enum TwoWheelersType {
    GasolineTwoWheelers = 'deux-roues motorisé essence',
  }

  export enum CritAir {
    C0 = 'C0',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    NC = 'NC',
  }

  export interface CritAirInfo {
    label: string;
    images?: [string, string];
  }

  export const critAirInfos: Record<CritAir, CritAirInfo> = {
    [CritAir.C0]: {
      label: 'CRIT’Air Hybride et électrique',
      images: [critAir0, critAir02x],
    },
    [CritAir.C1]: {
      label: 'CRIT’Air 1',
      images: [critAir1, critAir12x],
    },
    [CritAir.C2]: {
      label: 'CRIT’Air 2',
      images: [critAir2, critAir22x],
    },
    [CritAir.C3]: {
      label: 'CRIT’Air 3',
      images: [critAir3, critAir32x],
    },
    [CritAir.C4]: {
      label: 'CRIT’Air 4',
      images: [critAir4, critAir42x],
    },
    [CritAir.C5]: {
      label: 'CRIT’Air 5',
      images: [critAir5, critAir52x],
    },
    [CritAir.NC]: {
      label: 'Non classé',
    },
  };

  export const ProfileVehicleParams = z.object({
    name: nonEmptyStringSchema,
    type: z.union([z.nativeEnum(CarType), z.nativeEnum(TwoWheelersType)]),
    critAir: z.nativeEnum(CritAir),
  });

  export type ProfileVehicleParams = z.infer<typeof ProfileVehicleParams>;

  export enum ProfileVehicleType {
    Car = 'car',
    TwoWheelers = 'two-wheelers',
  }
}
