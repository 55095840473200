import { z } from 'zod';
import { nonEmptyStringSchema } from '@shared/schemas';

export namespace Profile {
  export const ProfileStudyId = z.string().brand<'ProfileStudyId'>();
  export type ProfileStudyId = z.infer<typeof ProfileStudyId>;

  export enum ProfileStugyGroup {
    A = 'A',
    B = 'B',
    C = 'C',
  }

  export interface Profile {
    email: string;
    firstName: string | null;
    lastName: string | null;
    studyId: ProfileStudyId;
    studyGroup: ProfileStugyGroup;
  }

  export const UpdateProfileParams = z.object({
    firstName: nonEmptyStringSchema,
    lastName: nonEmptyStringSchema,
  });

  export type UpdateProfileParams = z.infer<typeof UpdateProfileParams>;
}
