import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Button, Group, Text, TextInput } from '@mantine/core';
import { CustomPasswordInput } from '@styles/shared/Inputs';
import { Passwords } from '@modules/passwords/model';

interface PasswordsFormProps {
  email: string;
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (params: Passwords.PasswordsFormBody) => void;
}

const PasswordsForm: FC<PasswordsFormProps> = ({ email, loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Passwords.PasswordsFormBody>({
    resolver: zodResolver(Passwords.passwordsSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {error}
          </Text>
        </Alert>
      ))}

      <TextInput mt={10} value={email} label="E-mail" disabled />

      <CustomPasswordInput
        id="password"
        mt="sm"
        error={errors.password?.message}
        label="Noveau mot de passe"
        required
        {...register('password')}
      />

      <CustomPasswordInput
        id="confirmPassword"
        mt="sm"
        error={errors.confirmPassword?.message}
        label="Confirmer le mot de passe"
        required
        {...register('confirmPassword')}
      />

      <Group position="center" mt={20}>
        <Button type="submit" loading={loading} disabled={loading}>
          Valider
        </Button>
      </Group>
    </form>
  );
};

export default PasswordsForm;
