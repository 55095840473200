import React, { FC } from 'react';
import * as Styled from './HomeHeaderCompleted.styles';
import { Button, Text } from '@mantine/core';
import { MIconArrowRight, MIconCompleted, MIconLike } from '@styles/icons';
import { Link } from 'react-router-dom';
import { HomeAdd, HomeInfos } from '@modules/home/model';
import HomeHeaderRealized from '@modules/home/components/header/realized/HomeHeaderRealized';
import { Profile } from '@modules/profile/model';

interface HomeHeaderCompletedProps {
  step1: HomeInfos;
  step2: HomeAdd;
  studyGroup: Profile.ProfileStugyGroup;
}

const HomeHeaderCompleted: FC<HomeHeaderCompletedProps> = ({ step1, step2, studyGroup }) => {
  return (
    <Styled.HomeHeaderCompletedContainer>
      {studyGroup === Profile.ProfileStugyGroup.A ? (
        <>
          <Text size={16} lh="22px">
            <MIconLike style={{ marginRight: '5px' }} />
            Merci d'avoir pris le temps d'aider ce projet d'intérêt général, pour un air plus doux !
          </Text>

          <Text size={16} lh="22px" pt={10}>
            On a encore un peu besoin de toi pour renseigner ta semaine de déplacement domicile-travail. Pour les
            semaines où rien n'aurait changé, tu peux reprendre la saisie déjà enregistrée de la semaine précédente.
          </Text>

          <Text size={16} lh="22px" pt={10}>
            On reviendra vers toi avec une nouvelle enquête fin juin - début juillet...et après, VACANCES !
          </Text>
        </>
      ) : (
        <Text size={16} lh="22px">
          <MIconLike style={{ marginRight: '5px' }} />
          Profil 100% complété. On peut te dévoiler ton premier diagnostic "Mon impact sur l'air" personnalisé !
        </Text>
      )}

      <MIconCompleted />

      {studyGroup !== Profile.ProfileStugyGroup.A ? (
        <Button component={Link} to="impact" color="orange" rightIcon={<MIconArrowRight />} w="100%" mt={10}>
          <Text size={14}>Je découvre mon impact</Text>
        </Button>
      ) : null}

      <HomeHeaderRealized step1={step1} step2={step2} />
    </Styled.HomeHeaderCompletedContainer>
  );
};

export default HomeHeaderCompleted;
