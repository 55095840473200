import React, { FC, useMemo } from 'react';
import { Infos } from '@modules/infos/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { infosList } from '@modules/infos/list';
import { Anchor, Box, Text, Title } from '@mantine/core';
import * as Styled from './HomeInfos.styles';
import { Link } from 'react-router-dom';

interface HomeInfosProps {
  currentInformations: Array<Infos.ApiDetail>;
}

const HomeInfos: FC<HomeInfosProps> = ({ currentInformations }) => {
  const infos = useMemo(
    () =>
      pipe(
        currentInformations,
        A.filterMap(info => O.fromNullable(infosList[info.name])),
      ),
    [currentInformations],
  );

  return (
    <Box pt={45}>
      <Title order={2}>
        L'info de{' '}
        <Box component="span" c="violet.6">
          l'air
        </Box>
      </Title>

      <Styled.HomeInfosContent>
        <Styled.HomeInfosItems>
          {infos.map((info, index) => (
            <Styled.HomeInfosItem key={index}>
              <Text size={12} weight={400}>
                {info.description}
              </Text>

              <Anchor component={Link} to={info.link}>
                <Text underline size={14} weight={600} pt={8} color="black">
                  En savoir plus
                </Text>
              </Anchor>
            </Styled.HomeInfosItem>
          ))}
        </Styled.HomeInfosItems>

        <Anchor component={Link} mt={15} to="/infos">
          Voir toutes les brèves de l'air
        </Anchor>
      </Styled.HomeInfosContent>
    </Box>
  );
};

export default HomeInfos;
