import React, { FC } from 'react';
import * as Styled from './HomeFooter.styles';
import { Text, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';
import { MIconArrowRight } from '@styles/icons';

const HomeFooter: FC = () => {
  return (
    <Styled.HomeFooterContainer>
      <Styled.HomeFooterContent>
        <Text size={14} weight={600} color="white">
          Tu veux en savoir plus sur le projet Modoux ?
          <Anchor component={Link} to="project">
            Clique ici
          </Anchor>
        </Text>

        <Anchor component={Link} to="project">
          <MIconArrowRight color="white" width={24} height={24} />
        </Anchor>
      </Styled.HomeFooterContent>
    </Styled.HomeFooterContainer>
  );
};

export default HomeFooter;
