import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { Anchor, Box, Button, Stack } from '@mantine/core';
import { Link } from 'react-router-dom';
import Seo from '@shared/modules/seo/Seo';
import AuthHomeSlider from '@modules/auth/components/slider/AuthHomeSlider';
import { defineLoader, useLoader } from '@core/router/loader';
import * as T from 'fp-ts/Task';
import { AuthUtils } from '@modules/auth/utils';
import { stringifyQueries } from '@shared/utils/queries';

const loader = defineLoader({
  handler: ({ request }) =>
    T.of({
      referrer: AuthUtils.getAuthenticatedRedirection(request.url),
    }),
});

const AuthHome: FC = () => {
  const { referrer } = useLoader<typeof loader>();

  const referrerQueries = stringifyQueries({ [AuthUtils.REFERRER_QUERY]: referrer });

  return (
    <Stack py="lg" sx={{ minHeight: '100vh' }} justify="center">
      <Seo title="Accueil" />

      <Box sx={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
        <AuthHomeSlider />

        <Stack align="center" px={40}>
          <Button
            component={Link}
            to={{
              pathname: '/register',
              search: referrerQueries,
            }}
            sx={{ width: '100%' }}
          >
            S'inscrire
          </Button>

          <Anchor component={Link} to={{ pathname: '/login', search: referrerQueries }}>
            Se connecter
          </Anchor>
        </Stack>
      </Box>
    </Stack>
  );
};

const authHomeRoute = defineRoute({
  component: AuthHome,
  loader,
});

export default authHomeRoute;
