import styled from '@emotion/styled';
import { SegmentedControl } from '@mantine/core';
import { ImpactType } from '@modules/impact/model';
import { css } from '@emotion/react';

function getStyle(value: ImpactType, impactType: ImpactType, color: string) {
  return css`
    color: ${value === impactType ? 'white' : color};
    background-color: ${value === impactType ? color : 'white'};

    > label {
      color: ${value === impactType ? 'white' : color};
    }
  `;
}

export const ImpactTabsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ImpactTabsContent = styled(SegmentedControl)<{ val: ImpactType }>`
  background-color: transparent;

  > span {
    box-shadow: none;
  }

  .mantine-SegmentedControl-control {
    margin: 0 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border-radius: 5px;

    // Bordure des items pas sélectionnés
    &.mantine-s28z26:not(:first-of-type) {
      border-style: none;
    }

    > label {
      line-height: 1;
      font-size: 12px;
      font-weight: 700;
    }

    &:nth-of-type(1) {
      ${props => getStyle(props.val, ImpactType.Nox, props.theme.colors.violet[6])};
    }

    &:nth-of-type(2) {
      ${props => getStyle(props.val, ImpactType.PM, props.theme.colors.orange[6])};
    }

    &:nth-of-type(3) {
      ${props => getStyle(props.val, ImpactType.CO2, props.theme.colors.blue[6])};
    }
  }
`;
