import React, { FC, useEffect } from 'react';
import { Box } from '@mantine/core';

interface SurveyFormProps {
  formId: string;
  title: string;
}

const SurveyForm: FC<SurveyFormProps> = ({ formId, title }) => {
  useEffect(() => {
    window.Tally?.loadEmbeds();
  }, []);

  return (
    <Box mt="lg">
      <iframe
        data-tally-src={`https://tally.so/embed/${formId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`}
        loading="lazy"
        width="100%"
        height="200"
        frameBorder={0}
        title={title}
      />
    </Box>
  );
};

export default SurveyForm;
