import { defineLoader, httpTaskToResponseTask } from '@core/router/loader';
import { defineRoute } from '@core/router';
import { SurveyService } from '@modules/survey/service';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(SurveyService.validateSurvey()),
  redirect: () => '/',
});

const surveyStartCompletedRoute = defineRoute({
  loader,
});

export default surveyStartCompletedRoute;
