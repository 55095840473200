import React, { FC } from 'react';
import * as Styled from './ImpactPlaceholder.styles';
import { MIconArrowRight, MIconImpactPlaceholder } from '@styles/icons';
import { Button, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

interface ImpactPlaceholderProps {}

const ImpactPlaceholder: FC<ImpactPlaceholderProps> = () => {
  return (
    <Styled.ImpactPlaceholderContainer>
      <MIconImpactPlaceholder />

      <Text pt={20} size={16}>
        Oups, il n'y a pas de diagnostic d'impact par ici pour l'instant :) Pour connaître ton impact, il faut remplir
        les missions présentées en page d'accueil
      </Text>

      <Button component={Link} to="/" color="violet" w="100%" rightIcon={<MIconArrowRight />} mt={30}>
        Ok, j'y retourne
      </Button>
    </Styled.ImpactPlaceholderContainer>
  );
};

export default ImpactPlaceholder;
