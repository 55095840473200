import React, { FC, useRef } from 'react';

import * as Styled from './TransportSelection.styles';
import { MIconMinus, MIconPlus, MIconStar } from '@styles/icons';
import { useDisclosure } from '@mantine/hooks';
import { Weeks } from '@modules/weeks/model';
import { Anchor, Button, Group, Modal, Text } from '@mantine/core';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import { ProfileVehiclesUtils } from '@modules/profile/vehicules/utils';
import { ProfileTravels } from '@modules/profile/travels/model';

interface SelectionItemProps<T> {
  value: T;
  onSelect: (value: T) => void;
}

const TravelSelectionItem: FC<SelectionItemProps<ProfileTravels.ProfileTravel>> = ({ value, onSelect }) => {
  const [modalOpen, modalTriggers] = useDisclosure(false);

  const handleValidate = () => {
    onSelect(value);
    modalTriggers.close();
  };

  return (
    <Styled.TransportSelectionItem px={15} py={8} spacing={6} noWrap onClick={modalTriggers.open}>
      <MIconStar />

      <Text size="xs" fw={700} color="violet.6">
        {value.name}
      </Text>

      {modalOpen ? (
        <Modal opened onClose={modalTriggers.close} centered>
          <Text align="center">
            Êtes-vous sûr de vouloir charger le trajet {value.name} ? Vos saisies en cours seront remplacées par ce
            dernier
          </Text>

          <Group mt="lg" position="center">
            <Anchor component="button" onClick={modalTriggers.close}>
              Annuler
            </Anchor>
            <Button compact onClick={handleValidate}>
              Oui
            </Button>
          </Group>
        </Modal>
      ) : null}
    </Styled.TransportSelectionItem>
  );
};

const VehicleSelectionItem: FC<SelectionItemProps<ProfileVehicles.ProfileVehicle>> = ({ value, onSelect }) => {
  const Icon = Weeks.transportTypeIcon[ProfileVehiclesUtils.getTransportTypeFromVehicle(value)];

  const handleClick = () => onSelect(value);

  return (
    <Styled.TransportSelectionItem px={15} py={8} spacing={6} noWrap onClick={handleClick}>
      <Icon />

      <Text size="xs" fw={700} color="violet.6">
        {value.name}
      </Text>
    </Styled.TransportSelectionItem>
  );
};

const TransportTypeSelectionItem: FC<SelectionItemProps<Weeks.TransportType>> = ({ value, onSelect }) => {
  const Icon = Weeks.transportTypeIcon[value];

  const handleClick = () => onSelect(value);

  return (
    <Styled.TransportSelectionItem px={15} py={8} spacing={6} noWrap onClick={handleClick}>
      <Icon />

      <Text size="xs">{Weeks.transportTypeLabel[value]}</Text>
    </Styled.TransportSelectionItem>
  );
};

interface TransportSelectionProps {
  label: string;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels: Array<ProfileTravels.ProfileTravel>;
  onChange: (vehicle: Weeks.TransportType) => void;
  onSelectVehicle: (vehicle: ProfileVehicles.ProfileVehicle) => void;
  onSelectTravel: (travel: ProfileTravels.ProfileTravel) => void;
}

const TransportSelection: FC<TransportSelectionProps> = ({
  label,
  vehicles,
  travels,
  onChange,
  onSelectVehicle,
  onSelectTravel,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [open, trigger] = useDisclosure(false);

  const className = open ? 'open' : undefined;

  const handleSelectTransport = (type: Weeks.TransportType) => {
    onChange(type);
    trigger.close();
  };

  return (
    <Styled.TransportSelectionContainer className={className}>
      <Styled.TransportSelectionButton
        unstyled={open}
        className={className}
        size="xs"
        fz={12}
        radius="lg"
        leftIcon={open ? undefined : <MIconPlus />}
        rightIcon={open ? <MIconMinus /> : undefined}
        pl={0}
        onClick={trigger.toggle}
      >
        {label}
      </Styled.TransportSelectionButton>

      <Styled.TransportSelectionContent
        aria-hidden={!open}
        className={className}
        ref={contentRef}
        style={{ maxHeight: open ? contentRef.current?.scrollHeight : 0 }}
      >
        {renderOptional(NEA.fromArray(travels), travels => (
          <Styled.TransportSelectionSection>
            {travels.map(travel => (
              <TravelSelectionItem key={travel.id} value={travel} onSelect={onSelectTravel} />
            ))}
          </Styled.TransportSelectionSection>
        ))}

        {renderOptional(NEA.fromArray(vehicles), vehicles => (
          <Styled.TransportSelectionSection>
            {vehicles.map(vehicle => (
              <VehicleSelectionItem key={vehicle.id} value={vehicle} onSelect={onSelectVehicle} />
            ))}
          </Styled.TransportSelectionSection>
        ))}

        {Object.values(Weeks.TransportType).map(type => (
          <TransportTypeSelectionItem key={type} value={type} onSelect={handleSelectTransport} />
        ))}
      </Styled.TransportSelectionContent>
    </Styled.TransportSelectionContainer>
  );
};

export default TransportSelection;
