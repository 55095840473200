import React, { FC, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  ScrollRestoration,
  useNavigation,
} from 'react-router-dom';

import { createRoute } from '@core/router';

import { resetNavigationProgress, startNavigationProgress } from '@mantine/nprogress';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import loginRoute from '@modules/auth/LoginPage';
import forgotPasswordRoute from '@modules/passwords/ForgotPasswordPage';
import resetPasswordRoute from '@modules/passwords/ResetPasswordPage';
import profileLoaderRoute from '@modules/profile/profile-loader';
import logoutRoute from '@modules/auth/logout';
import authHomeRoute from '@modules/auth/AuthHome';
import registerRoute from '@modules/auth/RegisterPage';
import NavLayout from '@layout/nav/NavLayout';
import profileRoutes from '@modules/profile/routes';

import projectRoutes from '@modules/project/routes';
import infosRoutes from '@modules/infos/routes';
import actionsRoutes from '@modules/actions/routes';
import operationRoutes from '@modules/operation/routes';
import impactRoutes from '@modules/impact/routes';
import cguRoute from '@modules/cgu/CGUPage';
import BackgroundLayout from '@layout/background/BackgroundLayout';
import weeksRoutes from '@modules/weeks/routes';
import surveyRoutes from '@modules/survey/routes';
import homeRoutes from '@modules/home/routes';
import AnalyticsTracker from '@shared/modules/analytics/components/AnalyticsTracker';

const MainRoute: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    if (navigation.state !== 'idle') {
      startNavigationProgress();
    } else {
      resetNavigationProgress();
    }
  }, [navigation.state]);

  return (
    <>
      <ScrollRestoration />
      <AnalyticsTracker />
      <Outlet />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<MainRoute />}>
      <Route path="/home" {...createRoute(authHomeRoute)} />

      <Route path="/login" {...createRoute(loginRoute)} />
      <Route path="/register" {...createRoute(registerRoute)} />

      <Route path="/password-reset/forgot" {...createRoute(forgotPasswordRoute)} />
      <Route path="/password-reset/:token" {...createRoute(resetPasswordRoute)} />

      <Route element={<BackgroundLayout color="violet" />}>
        <Route path="/cgu" {...createRoute(cguRoute)} />
      </Route>

      <Route {...createRoute(profileLoaderRoute)}>
        <Route element={<NavLayout />}>
          {homeRoutes}
          {actionsRoutes}
        </Route>

        {surveyRoutes}
        {weeksRoutes}
        {profileRoutes}
        {projectRoutes}
        {infosRoutes}
        {operationRoutes}

        <Route element={<NavLayout />}>{impactRoutes}</Route>

        <Route path="/logout" {...createRoute(logoutRoute)} />

        <Route path="*" element={<ErrorPage />} />
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ),
);

export default router;
