import React, { FC } from 'react';
import ForgotPasswordForm from '@modules/passwords/components/forgot/ForgotPasswordForm';
import * as EI from 'fp-ts/Either';
import { defineRoute } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { constFalse } from 'fp-ts/function';
import AuthLayout from '@layout/auth/AuthLayout';
import { PasswordsService } from '@modules/passwords/service';
import { Passwords } from '@modules/passwords/model';

const actions = {
  forgotPassword: defineAction({
    type: 'forgot',
    payload: Passwords.forgotPasswordSchema,
    handler: ({ payload }) => PasswordsService.forgotPassword(payload),
    flashOptions: {
      error: constFalse,
      success: () => 'Un e-mail vous a été envoyé avec la procédure à suivre pour choisir un nouveau mot de passe.',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/login' : null),
  }),
};

const ForgotPasswordPage: FC = () => {
  const [loading, send, error] = useAction(actions.forgotPassword);

  return (
    <AuthLayout title="mot de passe oublié">
      <ForgotPasswordForm loading={loading} error={error} onSubmit={send} />
    </AuthLayout>
  );
};

const forgotPasswordRoute = defineRoute({
  component: ForgotPasswordPage,
  actions,
});

export default forgotPasswordRoute;
