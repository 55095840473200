import { Infos } from './model';

export const infosList: Record<Infos.Name, Infos.Detail> = {
  [Infos.Name.Car]: {
    description:
      "La voiture n'est pas un cocon ni un bouclier anti-pollution. Les prises d'air sont proches des pots d'échappement. L'automobiliste est ainsi la première victime de la pollution liée au trafic routier.",
    link: 'https://www.airparif.asso.fr/en-voiture',
  },
  [Infos.Name.Pollution]: {
    description: 'La pollution est responsable de 7900 décès prématurés par an en Ile-de-France chaque année.',
    link: 'https://www.airparif.asso.fr/actualite/2022/avec-les-recommandations-de-loms-7-900-deces-pourraient-etre-evites-en-idf',
  },
  [Infos.Name.Atmosphere]: {
    description:
      "Les polluants dans l’atmosphère peuvent être d’origine naturelle (ils sont alors émis par la végétation, l’érosion du sol etc..) ou d’origine anthropique, c'est-à-dire qu'ils sont \"émis\" par les activités humaines. C'est sur ces derniers que nos déplacements jouent un rôle !",
    link: 'https://www.airparif.asso.fr/comprendre-la-pollution/les-sources-de-pollution',
  },
  [Infos.Name.Particles]: {
    description:
      'Les particules et le NO2 sont des polluants de l’air et impactent notre santé. Le CO2 est un gaz à effet de serre qui impacte le climat. ',
    link: 'https://www.airparif.asso.fr/comprendre-la-pollution/les-sources-de-pollution',
  },
  [Infos.Name.Traffic]: {
    description:
      "Le trafic routier représente en 2019 : la moitié des émissions d'oxydes d'azote et plus d’un tiers des émissions directes de gaz à effet de serre",
    link: 'https://www.airparif.asso.fr/comprendre-la-pollution/les-sources-de-pollution',
  },
  [Infos.Name.Transport]: {
    description:
      'Le transport routier est responsable de 31% des émissions de gaz à effet de serre d’Île-de-France. Ces émissions proviennent essentiellement des véhicules avec des moteurs à combustion (diesel, essence ou thermique).',
    link: 'https://www.airparif.asso.fr/sites/default/files/pdf/Bilan2019.pdf',
  },
  [Infos.Name.Measure]: {
    description:
      "On mesure 2 à 5 fois plus de particules ultrafines dans l’air à proximité des axes routiers, soit jusqu'à 10 000 particules par dés à coudre d'air",
    link: 'https://www.airparif.asso.fr/sites/default/files/pdf/2022-10%20-%20CP%20-%20Les%20particules%20ultrafines%20%C3%A0%20proximit%C3%A9%20des%20axes%20routiers.pdf',
  },
  [Infos.Name.Size]: {
    description:
      "Inférieures à 0,1 micron (100 nanomètres) ce qui correspond à la taille d'un virus ou d'une molécule d'ADN, les particules ultrafines issues des moteurs thermiques font l'objet d'inquiétudes sanitaires croissantes de la part de l'ANSES et de l'OMS. Elles auraient un impact sur l'augmentation de la morbidité, de la mortalité respiratoire et cardiovasculaire. Elles seraient également la cause probable de maladies cognitives.",
    link: 'https://www.airparif.asso.fr/actualite/2022/les-particules-ultrafines-dans-le-viseur-dairparif',
  },
  [Infos.Name.PM]: {
    description:
      "Les particules appelées PM2.5 sont des particules dont le diamètre est de 2.5 micron (μm). La moitié des émissions de PM2.5 sont dues au secteur résidentiel et plus d'un quart au transport routier. Les particules fines, inférieures à 2.5 µm, impactent à long terme la santé cardiovasculaire. Les particules PM2.5 issues du trafic routier altèrent aussi  la santé neurologique (performances cognitives) et la santé périnatale.",
    link: 'https://www.airparif.asso.fr/les-particules-fines',
  },
  [Infos.Name.Fuel]: {
    description:
      "Issu de l'utilisation des combustibles fossiles, le dioxyde de carbone (CO2) est un des principaux gaz à effet de serre. Mais il n'est pas le seul (méthane, protoxyde d'azote…). Certains polluants de l'air comme l'ozone  et les particules agissent également sur le changement climatique : l'ozone a tendance à réchauffer l'atmosphère.",
    link: 'https://www.airparif.asso.fr/gaz-effet-de-serre',
  },
  [Infos.Name.Rejection]: {
    description:
      "Le rejet massif par les activités humaines de gaz à effet de serre accentue le réchauffement climatique: +1,1 à +6,4°C d'ici la fin du siècle selon le GIEC et met gravement en péril l'équilibre de la planète. 6°, c'est la différence entre la température terrestre actuelle et celle de l'ère glaciaire !",
    link: 'https://www.airparif.asso.fr/gaz-effet-de-serre',
  },
  [Infos.Name.NO2]: {
    description:
      "Le dioxyde d’azote a pour source principale le trafic routier. Les concentrations observées en Île-de-France dépassent les seuils réglementaires et les recommandations de l'OMS. Le NO2 a des effets sur la santé respiratoire à court terme et à long terme.",
    link: 'https://www.airparif.asso.fr/le-dioxyde-dazote',
  },
  [Infos.Name.Datas]: {
    description:
      'Les dernières données épidémiologiques montrent que la pollution de l’air a des effets néfastes sur la santé à des concentrations plus faibles qu’on ne le croyait auparavant. Depuis septembre 2021, l’OMS a donc abaissé les seuils de pollution de l’air recommandé pour protéger la santé des populations.',
    link: 'https://www.airparif.asso.fr/actualite/2022/avec-les-recommandations-de-loms-7-900-deces-pourraient-etre-evites-en-idf',
  },
  [Infos.Name.IARC]: {
    description:
      "En 2013, le Centre International de Recherche sur le Cancer (IARC), agence spécialisée de l'OMS, a classé la pollution de l'air extérieur et plus spécifiquement les particules comme cancérigènes.",
    link: 'https://www.airparif.asso.fr/effets-de-la-pollution',
  },
  [Infos.Name.Necrosis]: {
    description:
      'De fortes concentrations de certains polluants peuvent conduire à des nécroses et à une réduction de la croissance des végétaux et affectent les rendements agricoles. ',
    link: 'https://www.airparif.asso.fr/effets-de-la-pollution',
  },
  [Infos.Name.Gas]: {
    description:
      "Les polluants sont des gaz ou des particules irritants et agressifs qui pénètrent plus ou moins loin dans l'appareil respiratoire et qui peuvent induire des effets respiratoires ou cardiovasculaires",
    link: 'https://www.airparif.asso.fr/effets-de-la-pollution',
  },
  [Infos.Name.Problems]: {
    description:
      "A priori différentes par leurs effets, les problématiques liées à la pollution atmosphérique et au changement climatique sont pourtant étroitement liées. Ainsi, les changements climatiques ont un impact sur la pollution de l'air puisqu'ils pourraient induire des canicules plus fréquentes, comme celles de 2003, 2006, 2018 et 2019 et auront donc un impact sur les niveaux d'ozone. ",
    link: 'https://www.airparif.asso.fr/gaz-effet-de-serre',
  },
};
