import { httpService, HttpTask } from '@core/http';
import { Profile } from '@modules/profile/model';

export namespace ProfileService {
  export function getProfile(): HttpTask<Profile.Profile> {
    return httpService.get('/profile');
  }

  export function updateProfile(params: Profile.UpdateProfileParams): HttpTask<Profile.Profile> {
    return httpService.post('/profile', params);
  }
}
