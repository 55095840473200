import React, { FC, useMemo } from 'react';
import { ImpactType, impactTypeLabel } from '@modules/impact/model';
import { useMantineTheme, Text } from '@mantine/core';
import styled from '@emotion/styled';

const ImpactTitleContainer = styled.div`
  padding-top: 30px;
  display: flex;
`;

interface ImpactTitleProps {
  title: string;
  impactType: ImpactType;
}

const ImpactTitle: FC<ImpactTitleProps> = ({ title, impactType }) => {
  const theme = useMantineTheme();

  const color = useMemo(() => {
    switch (impactType) {
      case ImpactType.Nox:
        return theme.colors.violet[6];
      case ImpactType.PM:
        return theme.colors.orange[6];
      case ImpactType.CO2:
        return theme.colors.blue[6];
    }
  }, [impactType, theme.colors]);

  return (
    <ImpactTitleContainer>
      <Text color={color} size={18} weight={700}>
        {impactTypeLabel[impactType]}
      </Text>

      <Text size={18} weight={700} pl={5}>
        {title}
      </Text>
    </ImpactTitleContainer>
  );
};

export default ImpactTitle;
