import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import profileVehiclesListRoutes from '@modules/profile/vehicules/ListPage';
import createProfileVehicleRoute from '@modules/profile/vehicules/CreatePage';
import profileVehicleDetailRoute from '@modules/profile/vehicules/DetailPage';

const profileVehiclesRoutes = (
  <Route path="vehicles">
    <Route index {...createRoute(profileVehiclesListRoutes)} />
    <Route path="new/:type" {...createRoute(createProfileVehicleRoute)} />
    <Route path=":id" {...createRoute(profileVehicleDetailRoute)} />
  </Route>
);

export default profileVehiclesRoutes;
