import React, { FC } from 'react';
import * as Styled from './ActionCard.styles';
import { Text } from '@mantine/core';
import { MIconDown, MIconUp } from '@styles/icons';
import { Actions } from '@modules/actions/model';
import { renderNullable } from '@shared/utils/render';
import { ClassNames } from '@emotion/react';
import ActionAnswer = Actions.ActionAnswer;

interface ActionCardProps {
  action: Actions.Detail;
  onAnswer: (action: Actions.Detail, answer: Actions.ActionAnswer) => void;
}

const ActionCard: FC<ActionCardProps> = ({ action, onAnswer }) => {
  const handleNotOkClick = () => onAnswer(action, Actions.ActionAnswer.NotOk);
  const handleOkClick = () => onAnswer(action, Actions.ActionAnswer.Ok);

  return !action.pendingNotOk ? (
    <Styled.ActionCardContainer bg="white" className={action.answer === ActionAnswer.Ok ? 'active' : undefined}>
      <Styled.ActionCardTop>
        {renderNullable(action.image, image => (
          <Styled.ActionCardImage>
            {image}
            <Styled.ActionCardImageMask />
          </Styled.ActionCardImage>
        ))}

        <Styled.ActionCardDescription>{action.description}</Styled.ActionCardDescription>
      </Styled.ActionCardTop>

      <Styled.ActionCardBottom>
        <Styled.ActionCardBottomItem>
          <ClassNames>
            {({ cx }) => (
              <Styled.ActionCardBottomVioletButton
                className={cx({ active: action.answer === ActionAnswer.NotOk })}
                disabled={action.answer != null}
                variant="white"
                onClick={handleNotOkClick}
              >
                <Text size={12} weight={700} mr={5}>
                  Bof
                </Text>
                <MIconDown width={15} height={15} />
              </Styled.ActionCardBottomVioletButton>
            )}
          </ClassNames>
        </Styled.ActionCardBottomItem>

        <Styled.ActionCardBottomItem>
          <ClassNames>
            {({ cx }) => (
              <Styled.ActionCardBottomOrangeButton
                className={cx({ active: action.answer === ActionAnswer.Ok })}
                disabled={action.answer != null}
                variant="white"
                onClick={handleOkClick}
              >
                <Text size={12} weight={700} mr={5}>
                  Bonne idée
                </Text>
                <MIconUp width={15} height={15} />
              </Styled.ActionCardBottomOrangeButton>
            )}
          </ClassNames>
        </Styled.ActionCardBottomItem>
      </Styled.ActionCardBottom>
    </Styled.ActionCardContainer>
  ) : (
    <Styled.ActionCardNotShow>
      <Text size={12} weight={400} color="white">
        Ok, je ne te montre plus ça :) Tu pourras la retrouver en bas de la liste de tes actions
      </Text>
    </Styled.ActionCardNotShow>
  );
};

export default ActionCard;
