import { httpService, HttpTask } from '@core/http';
import { ProfileAddress } from '@modules/profile/address/model';

export namespace ProfileAddressService {
  const URI = '/addresses';

  export function getProfileAddresses(): HttpTask<Array<ProfileAddress.ProfileAddress>> {
    return httpService.get(URI);
  }

  export function createProfileAddress(
    params: ProfileAddress.ProfileAddressParams,
  ): HttpTask<ProfileAddress.ProfileAddress> {
    return httpService.post(URI, params);
  }

  export function getProfileAddress(id: ProfileAddress.ProfileAddressId): HttpTask<ProfileAddress.ProfileAddress> {
    return httpService.get(`${URI}/${id}`);
  }

  export function updateProfileAddress(
    id: ProfileAddress.ProfileAddressId,
    params: ProfileAddress.ProfileAddressParams,
  ): HttpTask<ProfileAddress.ProfileAddress> {
    return httpService.post(`${URI}/${id}`, params);
  }
}
