import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent, Paper, PaperProps } from '@mantine/core';

export const ProfileListItemContainer = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 52px;
  padding: 14px 50px 14px 14px;
`);

export const ProfileListItemIconWrapper = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  position: absolute;
  top: 50%;
  right: 20px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
`);
