import { z } from 'zod';
import { Address } from '@shared/modules/address/model';
import { nonEmptyStringSchema } from '@shared/schemas';

export namespace ProfileAddress {
  export const ProfileAddressId = z.string().uuid().brand<'ProfileAddressId'>();
  export type ProfileAddressId = z.infer<typeof ProfileAddressId>;

  export interface ProfileAddress {
    id: ProfileAddressId;
    name: string;
    type: Address.AddressType;
    label: string;
    placeId: Address.PlaceId;
  }

  export const ProfileAddressParams = z.object({
    type: z.nativeEnum(Address.AddressType),
    name: nonEmptyStringSchema,
    label: nonEmptyStringSchema,
    placeId: Address.PlaceId,
  });

  export type ProfileAddressParams = z.infer<typeof ProfileAddressParams>;

  export const ProfileAddressBody = ProfileAddressParams.pick({ type: true, name: true }).extend({
    address: Address.Address
  })

  export type ProfileAddressBody = z.infer<typeof ProfileAddressBody>
}
