import { httpService, HttpTask } from '@core/http';
import { Impact } from '@modules/impact/model';

export namespace ImpactService {
  const URI = '/impacts';

  export function getImpacts(): HttpTask<Impact> {
    return httpService.get(URI);
  }
}
