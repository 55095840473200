import { defineRoute } from '@core/router';
import { HomeService } from '@modules/home/service';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { FC } from 'react';
import { useProfile } from '@modules/profile/profile-loader';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';
import HomeHeader from '@modules/home/components/header/HomeHeader';
import HomeFooter from '@modules/home/components/footer/HomeFooter';
import styled from '@emotion/styled';
import HomeContent from '@modules/home/components/content/HomeContent';

const HomePageContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`);

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(HomeService.getHome()),
  redirect: ({ result }) => (result.isUserSuspended ? '/logout' : null),
});

const HomePage: FC = () => {
  const profile = useProfile();

  const home = useLoader<typeof loader>();

  return (
    <HomePageContainer>
      <HomeHeader infos={home} profile={profile} />

      <HomeContent home={home} profile={profile} />

      <HomeFooter />
    </HomePageContainer>
  );
};

const homeRoute = defineRoute({
  component: HomePage,
  loader,
});

export default homeRoute;
