import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';

export const DayTravelContent = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    width: 2px;
    height: 100%;
    background: ${props => props.theme.colors.violet[0]};
    transform: translateX(-50%);
  }
`);