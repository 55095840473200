import { z } from 'zod';
import { emailSchema } from '@shared/schemas';

export namespace Passwords {
  export const ResetPasswordToken = z.string().brand<'ResetPasswordToken'>();
  export type ResetPasswordToken = z.infer<typeof ResetPasswordToken>;

  export const forgotPasswordSchema = z.object({
    email: emailSchema,
  });

  export type ForgotPasswordParams = z.infer<typeof forgotPasswordSchema>;

  export interface ResetPasswordParams {
    password: string;
  }

  // Aucun mérite pour la regex : https://www.section.io/engineering-education/password-strength-checker-javascript/
  export const passwordSchema = z
    .string()
    .regex(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
      "Le mot de passe n'est pas suffisamment sécurisé (attendu : 8 caractères avec une majuscule, une minuscule, un chiffre et un caractère spécial)",
    );

  export const passwordsSchema = z
    .object({
      password: passwordSchema,
      confirmPassword: z.string(),
    })
    .refine(value => value?.password && value.confirmPassword && value.password === value.confirmPassword, {
      path: ['confirmPassword'],
      message: 'Les mots de passe ne correspondent pas',
    });

  export type PasswordsFormBody = z.infer<typeof passwordsSchema>;
}
