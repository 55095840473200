import { Weeks } from '@modules/weeks/model';

export enum ImpactType {
  Nox = 'NOx',
  PM = 'pm',
  CO2 = 'CO2',
}

export const impactTypeLabel: Record<ImpactType, string> = {
  [ImpactType.Nox]: 'NOx',
  [ImpactType.PM]: 'Particules',
  [ImpactType.CO2]: 'CO2',
};

export enum ImpactUnit {
  G = 'g',
  KG = 'kg',
}

export const impactUnitLabel: Record<ImpactUnit, string> = {
  [ImpactUnit.G]: 'g',
  [ImpactUnit.KG]: 'kg',
};

export enum ImpactChallengeText {
  HighObjTopGroupTopOrganization = 'high_obj_top_group_top_organization',
  HighObjTopGroupMiddleOrganization = 'high_obj_top_group_middle_organization',
  HighObjTopGroupBottomOrganization = 'high_obj_top_group_bottom_organization',
  HighObjMiddleGroupTopOrganization = 'high_obj_middle_group_top_organization',
  HighObjMiddleGroupMiddleOrganization = 'high_obj_middle_group_middle_organization',
  HighObjMiddleGroupBottomOrganization = 'high_obj_middle_group_bottom_organization',
  HighObjBottomGroupTopOrganization = 'high_obj_bottom_group_top_organization',
  HighObjBottomGroupMiddleOrganization = 'high_obj_bottom_group_middle_organization',
  HighObjBottomGroupBottomOrganization = 'high_obj_bottom_group_bottom_organization',
  MiddleObjTopGroupTopOrganization = 'middle_obj_top_group_top_organization',
  MiddleObjTopGroupMiddleOrganization = 'middle_obj_top_group_middle_organization',
  MiddleObjTopGroupBottomOrganization = 'middle_obj_top_group_bottom_organization',
  MiddleObjMiddleGroupTopOrganization = 'middle_obj_middle_group_top_organization',
  MiddleObjMiddleGroupMiddleOrganization = 'middle_obj_middle_group_middle_organization',
  MiddleObjMiddleGroupBottomOrganization = 'middle_obj_middle_group_bottom_organization',
  MiddleObjBottomGroupTopOrganization = 'middle_obj_bottom_group_top_organization',
  MiddleObjBottomGroupMiddleOrganization = 'middle_obj_bottom_group_middle_organization',
  MiddleObjBottomGroupBottomOrganization = 'middle_obj_bottom_group_bottom_organization',
  LowObjTopGroupTopOrganization = 'low_obj_top_group_top_organization',
  LowObjTopGroupMiddleOrganization = 'low_obj_top_group_middle_organization',
  LowObjTopGroupBottomOrganization = 'low_obj_top_group_bottom_organization',
  LowObjMiddleGroupTopOrganization = 'low_obj_middle_group_top_organization',
  LowObjMiddleGroupMiddleOrganization = 'low_obj_middle_group_middle_organization',
  LowObjMiddleGroupBottomOrganization = 'low_obj_middle_group_bottom_organization',
  LowObjBottomGroupTopOrganization = 'low_obj_bottom_group_top_organization',
  LowObjBottomGroupMiddleOrganization = 'low_obj_bottom_group_middle_organization',
  LowObjBottomGroupBottomOrganization = 'low_obj_bottom_group_bottom_organization',
  VeryLowObjTopGroupTopOrganization = 'very_low_obj_top_group_top_organization',
  VeryLowObjTopGroupMiddleOrganization = 'very_low_obj_top_group_middle_organization',
  VeryLowObjTopGroupBottomOrganization = 'very_low_obj_top_group_bottom_organization',
  VeryLowObjMiddleGroupTopOrganization = 'very_low_obj_middle_group_top_organization',
  VeryLowObjMiddleGroupMiddleOrganization = 'very_low_obj_middle_group_middle_organization',
  VeryLowObjMiddleGroupBottomOrganization = 'very_low_obj_middle_group_bottom_organization',
  VeryLowObjBottomGroupTopOrganization = 'very_low_obj_bottom_group_top_organization',
  VeryLowObjBottomGroupMiddleOrganization = 'very_low_obj_bottom_group_middle_organization',
  VeryLowObjBottomGroupBottomOrganization = 'very_low_obj_bottom_group_bottom_organization',
}

export const impactChallengeLabel: Record<ImpactChallengeText, string> = {
  [ImpactChallengeText.HighObjTopGroupTopOrganization]:
    "C'est génial, un double podium, continue comme ça et passe le message à ton voisin",
  [ImpactChallengeText.HighObjTopGroupMiddleOrganization]:
    'Bravo, tu as fait des progrès mais ton site a encore de la marge. Il faut mettre le turbo (électrique!).',
  [ImpactChallengeText.HighObjTopGroupBottomOrganization]:
    'Les autres sites sont loin devant! Et si tu te dépassais et emmenait tes collègues ?',
  [ImpactChallengeText.HighObjMiddleGroupTopOrganization]:
    'Tu es dans un groupe de champions, mais il te reste encore une marge de progression, la semaine prochaine tu enlèves les petites roues ?',
  [ImpactChallengeText.HighObjMiddleGroupMiddleOrganization]:
    'Toi, moyen ? Non! Et si tu visais plus haut ? La semaine prochaine, on te veut sur le podium !',
  [ImpactChallengeText.HighObjMiddleGroupBottomOrganization]:
    "L'intérêt général, c'est fait. Et si on allait plus loin ? Quelques coéquipiers se rapproches des autres sites, tu les rejoins ?",
  [ImpactChallengeText.HighObjBottomGroupTopOrganization]:
    'Ton groupe est extraordinaire, si tu joues aussi le jeu, vous serez encore plus extraordinaire, tu essaies?',
  [ImpactChallengeText.HighObjBottomGroupMiddleOrganization]:
    "L'intérêt général, c'est fait. Et si on allait plus loin ? Tes coéquipiers mènent la danse, tu les rejoins ?",
  [ImpactChallengeText.HighObjBottomGroupBottomOrganization]:
    "L'intérêt général, c'est fait. Et si on allait plus loin ? Tes coéquipiers ont besoin de toi pour aller défier les autres sites et faire mieux !",
  [ImpactChallengeText.MiddleObjTopGroupTopOrganization]: 'Un double podium se rapproche, continue, tu vas y arriver !',
  [ImpactChallengeText.MiddleObjTopGroupMiddleOrganization]:
    'Bravo, mais il reste une marge de progression ! Continue pour hisser ton site au top niveau !',
  [ImpactChallengeText.MiddleObjTopGroupBottomOrganization]:
    'Tes efforts ne suffisent pas à mettre ton site sur le podium. Il faut continuer !',
  [ImpactChallengeText.MiddleObjMiddleGroupTopOrganization]:
    "C'est pas mal, mais 50% font mieux que toi, tu peux donc mieux faire !",
  [ImpactChallengeText.MiddleObjMiddleGroupMiddleOrganization]:
    'Tu places ton site dans la moyenne. Encore quelques efforts et les collègues seront fiers de toi !',
  [ImpactChallengeText.MiddleObjMiddleGroupBottomOrganization]:
    'Ton site a besoin de toi! Avec un bel objectif en ligne de mire, aide tes collègues à passer devant !',
  [ImpactChallengeText.MiddleObjBottomGroupTopOrganization]:
    '75% de ton groupe fait mieux que toi, courage, tu peux le faire !',
  [ImpactChallengeText.MiddleObjBottomGroupMiddleOrganization]:
    "L'objectif au bout de la semaine ! Ton groupe compte sur toi !",
  [ImpactChallengeText.MiddleObjBottomGroupBottomOrganization]:
    "L'objectif au bout de la semaine ! Ton groupe compte sur toi !",
  [ImpactChallengeText.LowObjTopGroupTopOrganization]:
    "Ton groupe a besoin de toi pour aller plus loin vers l'objectif !",
  [ImpactChallengeText.LowObjTopGroupMiddleOrganization]:
    'Ton site a besoin de toi pour aller pour monter viser le top',
  [ImpactChallengeText.LowObjTopGroupBottomOrganization]:
    'Malheureusement ton site est à la traîne, il faut aller plus loin!',
  [ImpactChallengeText.LowObjMiddleGroupTopOrganization]:
    "Et si on montrait l'exemple? A peine 50% de l'objectif, il faut changer plus vite pour respirer mieux !",
  [ImpactChallengeText.LowObjMiddleGroupMiddleOrganization]:
    'Ta moyenne ne suffit plus! Ton site a besoin de toi, ton groupe a besoin de toi!',
  [ImpactChallengeText.LowObjMiddleGroupBottomOrganization]:
    'Pour mettre ton site sur le podium, il faut te dépasser !',
  [ImpactChallengeText.LowObjBottomGroupTopOrganization]:
    'Allez, allez, les autres te montrent la voie. Tu fais mieux la semaine prochaine ?',
  [ImpactChallengeText.LowObjBottomGroupMiddleOrganization]:
    "Le bas de tableau n'est pas ta place. Tu te mets au niveau la semaine prochaine?",
  [ImpactChallengeText.LowObjBottomGroupBottomOrganization]:
    'Tes résultats entraînent ton site dans les profondeurs du tableau. Ton équipe croit en toi pour redresser la barre.',
  [ImpactChallengeText.VeryLowObjTopGroupTopOrganization]:
    'Difficile mais pas impossible. Ton groupe compte sur toi pour réduire tes émissions!',
  [ImpactChallengeText.VeryLowObjTopGroupMiddleOrganization]:
    "L'objectif fixé en terme de réduction des émissions est certes ambitieux, mais pas impossible, vous y êtes presque, plus que 2 sites devant vous !",
  [ImpactChallengeText.VeryLowObjTopGroupBottomOrganization]:
    "L'objectif d'intérêt général est-il trop haut pour vous ? Apparemment, ce n'est pas le cas des autres sites ! Allez,  allez !",
  [ImpactChallengeText.VeryLowObjMiddleGroupTopOrganization]:
    "C'est pas mal, mais certains de tes coequipiers font mieux. Ils croient en toi pour réduire tes émissions !",
  [ImpactChallengeText.VeryLowObjMiddleGroupMiddleOrganization]:
    "L'objectif est élevé, mais tes coéquipiers te montrent la voie, suis les !",
  [ImpactChallengeText.VeryLowObjMiddleGroupBottomOrganization]:
    "D'autres sites passent en tête, tes coéquipiers te montrent la voie, suis les !",
  [ImpactChallengeText.VeryLowObjBottomGroupTopOrganization]:
    'Allez, allez, les autres te montrent la voie. Tu fais mieux la semaine prochaine ?',
  [ImpactChallengeText.VeryLowObjBottomGroupMiddleOrganization]:
    'Tes coéquipiers ont besoin de toi, rdv la semaine prochaine !',
  [ImpactChallengeText.VeryLowObjBottomGroupBottomOrganization]:
    'Tes coéquipiers ont besoin de toi, rdv la semaine prochaine !',
};

export interface ImpactEmission {
  total: number;
  rank: number;
  currentEmission: number;
  currentProgressionPercentage: number;
  bestEmission: number;
  bestProgressionPercentage: number;
  modelWeekEmission: number;
}

export const impactVehicleLabel: Record<Weeks.TransportType, string> = {
  [Weeks.TransportType.WalkOrBike]: 'Vélo ou marche',
  [Weeks.TransportType.Car]: 'Voiture personnelle',
  [Weeks.TransportType.CarPooling]: 'Covoiturage',
  [Weeks.TransportType.SubwayRerTransilien]: 'Métro, RER, Transilien',
  [Weeks.TransportType.Bus]: 'Bus',
  [Weeks.TransportType.Train]: 'Train',
  [Weeks.TransportType.TwoWheelers]: '2 roues motorisé',
  [Weeks.TransportType.ScooterOrElectricBike]: 'Vélo ou trottinette électrique',
};

export interface ImpactDetail {
  vehicleType: Weeks.TransportType;
  modelValue: number;
  currentValue: number;
}

export interface ImpactInfos {
  type: ImpactType;
  unit: ImpactUnit;
  challengeText: ImpactChallengeText;
  participantCurrentEmission: number;
  participantCurrentProgressionPercentage: number;
  participantBestEmission: number;
  participantBestEmissionPercentage: number;
  participantModelEmission: number;
  groupEmission: ImpactEmission;
  organizationEmission: ImpactEmission;
  participantDetail: Array<ImpactDetail>;
  groupDetail: Array<ImpactDetail>;
}

//export type Impact = Record<ImpactType, ImpactInfos>;

export interface Impact {
  CO2: ImpactInfos;
  NOx: ImpactInfos;
  pm: ImpactInfos;
  weekNumber?: number;
  startDate?: string;
  endDate?: string;
}

export interface ImpactDetailValues {
  current: number;
  typical: number;
}

export type ImpactDetailDatas = Record<Weeks.TransportType, ImpactDetailValues>;

export interface ImpactDetailInfos {
  personal: ImpactDetailDatas;
  team: ImpactDetailDatas;
}

export interface ImpactDetailOld {
  nox: ImpactDetailInfos;
  pm: ImpactDetailInfos;
  co2: ImpactDetailInfos;
}
