import styled from '@emotion/styled';

export const HomeInfosContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeInfosItems = styled.div`
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  ${props => props.theme.fn.smallerThan('sm')} {
    grid-template-columns: 1fr;
  }
`;

export const HomeInfosItem = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.blue[2]};
  border-radius: 8px;
  color: black;
  text-decoration: none;
`;
