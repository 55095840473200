import React, { FC } from 'react';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { ProfileTravels } from '@modules/profile/travels/model';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as TE from 'fp-ts/TaskEither';
import { ProfileAddressService } from '@modules/profile/address/service';
import { ProfileTravelsService } from '@modules/profile/travels/service';
import { defineRoute, useBack } from '@core/router';
import { defineAction, useAction } from '@core/router/action';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import TravelForm from '@modules/profile/travels/components/form/TravelForm';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';

const params = z.object({ id: ProfileTravels.ProfileTravelId });

const loader = defineLoader({
  params,
  handler: ({ params }) =>
    pipe(
      sequenceS(TE.ApplyPar)({
        travel: ProfileTravelsService.getProfileTravel(params.id),
        addresses: ProfileAddressService.getProfileAddresses(),
        vehicles: ProfileVehiclesService.getProfileVehicles(),
      }),
      httpTaskToResponseTask,
    ),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: ProfileTravels.ProfileTravelParams,
    handler: ({ payload, params }) => ProfileTravelsService.updateProfileTravel(params.id, payload),
  }),
};

const ProfileTravelDetailPage: FC = () => {
  const { travel, addresses, vehicles } = useLoader<typeof loader>();

  const goBack = useBack();

  const [loading, update] = useAction(actions.update);

  const handleSubmit = (params: ProfileTravels.ProfileTravelParams) =>
    pipe(
      () => update(params),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Modifier mon trajet favoris" />

      <TravelForm travel={travel} vehicles={vehicles} addresses={addresses} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const profileTravelDetailRoute = defineRoute({
  component: ProfileTravelDetailPage,
  loader,
  actions,
});

export default profileTravelDetailRoute;
