import React, { FC } from 'react';
import * as Styled from './HomeHeaderProfile.styles';
import { Title } from '@mantine/core';
import profileIcon from '@assets/icons/home/profile.svg';
import { Profile } from '@modules/profile/model';

interface HomeHeaderProfileProps {
  profile: Profile.Profile;
}

const HomeHeaderProfile: FC<HomeHeaderProfileProps> = ({ profile }) => {
  return (
    <Styled.HomeHeaderProfileContainer>
      <Title order={1}>
        Bonjour <span>{profile.firstName}</span>
      </Title>

      <Styled.HomeHeaderProfileImgContainer>
        <img src={profileIcon} alt="Profil" />
      </Styled.HomeHeaderProfileImgContainer>
    </Styled.HomeHeaderProfileContainer>
  );
};

export default HomeHeaderProfile;
