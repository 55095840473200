import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import ProfileMenuHeader from '@modules/profile/components/menu/header/ProfileMenuHeader';
import ProfileMenuContent from '@modules/profile/components/menu/content/ProfileMenuContent';
import { Anchor, Box } from '@mantine/core';
import { Link } from 'react-router-dom';
import { NAV_HEIGHT } from '@layout/nav/NavLayout';

const ProfileMenuPage: FC = () => {
  return (
    <Box pb={NAV_HEIGHT + 25} m="0 auto" sx={{ maxWidth: 700 }}>
      <ProfileMenuHeader />
      <ProfileMenuContent />

      <Box mt={20} px={32}>
        <Anchor component={Link} to="/logout">
          Me déconnecter
        </Anchor>
      </Box>
    </Box>
  );
};

const profileMenuRoute = defineRoute({
  component: ProfileMenuPage,
  loader: undefined,
});

export default profileMenuRoute;
