import React, { FC, useMemo } from 'react';
import * as Styled from './ImpactDetailInfos.styles';
import { Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { ImpactDetail, ImpactType, ImpactUnit, impactUnitLabel, impactVehicleLabel } from '@modules/impact/model';
import { Weeks } from '@modules/weeks/model';

function getIcon(vehicleType: Weeks.TransportType) {
  const Icon = Weeks.transportTypeIcon[vehicleType];

  return <Icon />;
}

function getColorLight(impactType: ImpactType) {
  switch (impactType) {
    case ImpactType.Nox:
      return 'violet';
    case ImpactType.PM:
      return 'orange';
    case ImpactType.CO2:
      return 'blue';
  }
}

function getWrapperWidth(modelValue: number, currentValue: number, max: number): number {
  if (max !== 0) return (Math.max(modelValue, currentValue) * 100) / max;
  else return Math.max(modelValue, currentValue) * 100;
}

interface ImpactDetailInfosProps {
  infos: Array<ImpactDetail>;
  impactType: ImpactType;
  unit: ImpactUnit;
  currentWeek: number;
}

const ImpactDetailInfos: FC<ImpactDetailInfosProps> = ({ infos, impactType, unit, currentWeek }) => {
  const theme = useMantineTheme();

  const color = useMemo(() => {
    switch (impactType) {
      case ImpactType.Nox:
        return theme.colors.violet[6];
      case ImpactType.PM:
        return theme.colors.orange[6];
      case ImpactType.CO2:
        return theme.colors.blue[6];
    }
  }, [impactType, theme.colors]);

  const colorLight = getColorLight(impactType);

  const max = infos.reduce(
    (previousValue, currentValue) => Math.max(previousValue, currentValue.currentValue, currentValue.modelValue),
    0,
  );

  return (
    <Styled.ImpactDetailInfosContainer>
      {infos.map((info, index) => (
        <Styled.ImpactDetailInfosItem key={index}>
          <Text size={12}>{impactVehicleLabel[info.vehicleType]}</Text>

          <Styled.ImpactDetailInfosDatas>
            <ThemeIcon
              color={colorLight}
              variant="light"
              radius="xl"
              style={{
                width: '26px',
                minWidth: '26px',
                height: '26px',
                minHeight: '26px',
              }}
            >
              {getIcon(info.vehicleType)}
            </ThemeIcon>

            <Styled.ImpactDetailInfosValues>
              <Styled.ImpactDetailInfosSliderWrapper width={getWrapperWidth(info.modelValue, info.currentValue, max)}>
                <Styled.ImpactDetailInfosSliderContainer>
                  {currentWeek === 0 ? (
                    <Styled.ImpactDetailInfosSliderCurrent color={color} width={100} />
                  ) : info.currentValue > info.modelValue ? (
                    <>
                      <Styled.ImpactDetailInfosSliderCurrent color={color} width={100} />

                      <Styled.ImpactDetailInfosSliderTypical
                        width={
                          info.currentValue !== 0 ? (info.modelValue * 100) / info.currentValue : info.modelValue * 100
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Styled.ImpactDetailInfosSliderTypical width={100} />

                      <Styled.ImpactDetailInfosSliderCurrent
                        color={color}
                        width={
                          info.modelValue !== 0 ? (info.currentValue * 100) / info.modelValue : info.currentValue * 100
                        }
                      />
                    </>
                  )}
                </Styled.ImpactDetailInfosSliderContainer>
              </Styled.ImpactDetailInfosSliderWrapper>

              <Styled.ImpactDetailInfosLabels>
                <Text size={16} color={colorLight}>
                  {info.currentValue}
                  {impactUnitLabel[unit]}
                </Text>

                {currentWeek !== 0 ? (
                  <Text size={16} pl={8}>
                    {info.modelValue}
                    {impactUnitLabel[unit]}
                  </Text>
                ) : null}
              </Styled.ImpactDetailInfosLabels>
            </Styled.ImpactDetailInfosValues>
          </Styled.ImpactDetailInfosDatas>
        </Styled.ImpactDetailInfosItem>
      ))}
    </Styled.ImpactDetailInfosContainer>
  );
};

export default ImpactDetailInfos;
