import { constFalse, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import AuthLayout from '@layout/auth/AuthLayout';
import PasswordsForm from '@modules/passwords/components/PasswordsForm';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { z } from 'zod';
import { defineAction, useAction } from '@core/router/action';
import { PasswordsService } from '@modules/passwords/service';
import { Passwords } from '@modules/passwords/model';

const tokenSchema = z.object({ token: Passwords.ResetPasswordToken });

const loader = defineLoader({
  params: tokenSchema,
  handler: ({ params }) => httpTaskToResponseTask(PasswordsService.getPasswordResetDetail(params.token)),
});

const actions = {
  reset: defineAction({
    type: 'reset',
    params: tokenSchema,
    payload: z.object({ password: z.string() }),
    handler: ({ params, payload }) => PasswordsService.resetPassword(params.token, payload),
    flashOptions: {
      error: constFalse,
      success: () => 'Mot de passe modifié avec succès.',
    },
    redirect: ({ result }) => (EI.isRight(result) ? '/login' : null),
  }),
};

const ResetPasswordPage: FC = () => {
  const { email } = useLoader<typeof loader>();

  const [loading, send, error] = useAction(actions.reset);

  const handleResetPassword = ({ password }: Passwords.PasswordsFormBody) => send({ password });

  const errorMessage = pipe(
    error,
    O.map(() => 'Une erreur technique est survenue.'),
  );

  // FIXME -> A adapter avec design
  return (
    <AuthLayout title="Changement de mot de passe">
      <PasswordsForm email={email} loading={loading} error={errorMessage} onSubmit={handleResetPassword} />
    </AuthLayout>
  );
};

const resetPasswordRoute = defineRoute({
  component: ResetPasswordPage,
  loader,
  actions,
});

export default resetPasswordRoute;
