import React from 'react';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import profileAddressListRoute from '@modules/profile/address/ListPage';
import profileAddressDetailRoute from '@modules/profile/address/DetailPage';

const profileAddressRoutes = (
  <Route path="addresses">
    <Route index {...createRoute(profileAddressListRoute)} />
    <Route path=":id" {...createRoute(profileAddressDetailRoute)} />
  </Route>
);

export default profileAddressRoutes;
