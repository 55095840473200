import { ParsedQuery } from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { parseQueries } from '@shared/utils/queries';

export function useIdParam<I extends string>(key: string = 'id'): I {
  return useParams<{ [key: string]: I }>()[key] as I;
}

export function useTokenParam<I extends string>(): I {
  return useParams<{ token: I }>().token as I;
}

export function useQuery(name: string): string | null {
  return new URLSearchParams(useLocation().search).get(name);
}

export function useQueries(): ParsedQuery {
  return parseQueries(useLocation().search);
}

export function useBack() {
  const navigate = useNavigate();
  const location = useLocation();

  return () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };
}
