import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';

export const HomeHeaderWeeksDisabledButton = createPolymorphicComponent<'a', ButtonProps>(styled(Button)`
  &[data-disabled] {
    background-color: rgba(0, 0, 0, 0.28);
    color: #6b5f76;
  }
`);

export const HomeHeaderWeeksCards = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;
