import React, { FC, useMemo } from 'react';
import { ProfileAddress } from '@modules/profile/address/model';
import { ProfileTravels } from '@modules/profile/travels/model';
import { WeeksUtils } from '@modules/weeks/utils';
import { Weeks } from '@modules/weeks/model';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Group, TextInput } from '@mantine/core';
import DayTravel from '@modules/weeks/components/update/day/travel/DayTravel';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileTravelsUtils } from '@modules/profile/travels/utils';

function getDefaultValues(
  addresses: Array<ProfileAddress.ProfileAddress>,
  travel?: ProfileTravels.ProfileTravel,
): ProfileTravels.ProfileTravelBody {
  return {
    name: travel?.name ?? '',
    ...ProfileTravelsUtils.profileTravelToUpdateTravelBody(travel?.travels ?? [], addresses),
  };
}

interface TravelFormProps {
  travel?: ProfileTravels.ProfileTravel;
  addresses: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  loading: boolean;
  onSubmit: (params: ProfileTravels.ProfileTravelParams) => void;
}

const TravelForm: FC<TravelFormProps> = ({ travel, addresses, vehicles, loading, onSubmit }) => {
  const defaultValues = useMemo(() => getDefaultValues(addresses, travel), [addresses, travel]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
  } = useForm<ProfileTravels.ProfileTravelBody>({
    resolver: zodResolver(ProfileTravels.ProfileTravelBody),
    defaultValues,
  });

  const onSubmitForm = (body: ProfileTravels.ProfileTravelBody) =>
    onSubmit({
      name: body.name,
      travels: WeeksUtils.updateTravelBodyToUpdateTravelParams(body, Weeks.TravelDirection.Outward),
    });

  return (
    <Box mt={33}>
      <form onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <TextInput mb="sm" id="name" label="Nom du trajet" error={!!errors.name} {...register('name')} />

        <DayTravel
          direction={Weeks.TravelDirection.Outward}
          control={control as any}
          addresses={addresses}
          vehicles={vehicles}
        />

        <Group grow mt="md">
          <Button type="submit" loading={loading} disabled={loading}>
            Valider
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default TravelForm;
