import React, { FC } from 'react';
import { defineRoute, useBack } from '@core/router';
import { z } from 'zod';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { defineLoader, useLoader } from '@core/router/loader';
import * as T from 'fp-ts/Task';
import PageTitle from '@layout/title/PageTitle';
import { Box } from '@mantine/core';
import { defineAction, useAction } from '@core/router/action';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';
import VehicleForm from '@modules/profile/vehicules/components/form/VehicleForm';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpStatusCode } from '@core/http';

function getErrorMessage(status: HttpStatusCode): string {
  switch (status) {
    case HttpStatusCode.PRECONDITION_FAILED:
      return 'Nous avons un problème pour calculer votre émission à partir des informations renseignées. Veuillez nous contacter à partenariats-digital@airparif.fr';
    default:
      return 'Une erreur technique est survenue';
  }
}

const params = z.object({ type: z.nativeEnum(ProfileVehicles.ProfileVehicleType) });

const loader = defineLoader({
  params,
  handler: ({ params }) => T.of(params.type),
});

const actions = {
  create: defineAction({
    type: 'create',
    payload: ProfileVehicles.ProfileVehicleParams,
    handler: ({ payload }) => ProfileVehiclesService.createProfileVehicle(payload),
    flashOptions: {
      error: error => getErrorMessage(error.error.status),
    },
  }),
};

const CreateProfileVehiclePage: FC = () => {
  const type = useLoader<typeof loader>();

  const goBack = useBack();

  const [loading, create] = useAction(actions.create);

  const handleSubmit = (params: ProfileVehicles.ProfileVehicleParams) =>
    pipe(
      () => create(params),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle
        title={
          type === ProfileVehicles.ProfileVehicleType.Car
            ? 'Ajouter une voiture'
            : 'Ajouter un véhicule à 2 roues motorisé'
        }
      />

      <VehicleForm type={type} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const createProfileVehicleRoute = defineRoute({
  component: CreateProfileVehiclePage,
  loader,
  actions,
});

export default createProfileVehicleRoute;
