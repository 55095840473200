import React, { FC } from 'react';
import * as Styled from './ImpactInfosLegend.styles';
import { SimpleGrid, Text } from '@mantine/core';
import { MIconPinkStar, MIconVerified } from '@styles/icons';

interface ImpactInfosLegendProps {
  currentWeek: number;
  color: string;
}

const ImpactInfosLegend: FC<ImpactInfosLegendProps> = ({ currentWeek, color }) => {
  return (
    <Styled.ImpactInfosLegendContainer>
      <Text transform="uppercase" size={9} weight={400}>
        Légende
      </Text>

      <SimpleGrid cols={2} pt={5}>
        {currentWeek !== 0 ? (
          <>
            <Styled.ImpactInfosLegendItem>
              <Styled.ImpactInfosLegendColor $color={color} />

              <Text size={9} pl={5}>
                La semaine écoulée
              </Text>
            </Styled.ImpactInfosLegendItem>

            <Styled.ImpactInfosLegendItem>
              <MIconPinkStar />

              <Text size={9} pl={5}>
                La meilleure semaine
              </Text>
            </Styled.ImpactInfosLegendItem>
          </>
        ) : null}

        <Styled.ImpactInfosLegendItem>
          <Styled.ImpactInfosLegendColor $color={currentWeek === 0 ? color : 'white'} />

          <Text size={9} pl={5}>
            La semaine type
          </Text>
        </Styled.ImpactInfosLegendItem>

        <Styled.ImpactInfosLegendItem>
          <MIconVerified />

          <Text size={9} pl={5}>
            Notre objectif collectif
          </Text>
        </Styled.ImpactInfosLegendItem>
      </SimpleGrid>
    </Styled.ImpactInfosLegendContainer>
  );
};

export default ImpactInfosLegend;
