import styled from '@emotion/styled';
import { createPolymorphicComponent, Paper, PaperProps } from '@mantine/core';

export const ImpactDetailLegendContainer = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  margin-top: 15px;
  padding: 10px;
  background-color: rgba(224, 224, 224, 0.28);
  border: none;
`);

export const ImpactDetailLegendItem = styled.div`
  display: flex;
  align-items: center;
`;

export const ImpactDetailLegendItemColor = styled.div<{ color: string }>`
  border-radius: 2px;
  background-color: ${props => props.color};
  width: 12px;
  height: 12px;
`;
