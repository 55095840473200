import styled from '@emotion/styled';

export const NAV_HEIGHT = 66;

export const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${NAV_HEIGHT}px;
  background: ${props => props.theme.white};
  border-radius: 10px 10px 0 0;
  z-index: 10;
`;

export const NavContent = styled.ul<{ length: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.length}, minmax(0, 1fr));
  grid-column-gap: 10px;
  margin: 0 auto;
  padding: 0;
  width: 240px;
  height: 100%;
  list-style: none;

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}px) {
    width: 400px;
    grid-column-gap: 30px;
  }
`;

export const NavItem = styled.li`
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${props => props.theme.black};
    font-size: 8px;
    font-weight: 600;
    transition: color 0.25s ease-in-out;

    span {
      padding-top: 2px;
    }

    &.active {
      color: ${props => props.theme.colors.orange[7]};
    }
  }
`;
