import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Box, Group } from '@mantine/core';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ProfileAddressService } from '@modules/profile/address/service';
import AddressList from '@modules/profile/address/components/list/AddressList';
import { CreateButton } from '@styles/shared';
import { Link } from 'react-router-dom';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(ProfileAddressService.getProfileAddresses()),
});

const ProfileAddressListPage: FC = () => {
  const addresses = useLoader<typeof loader>();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Mes adresses" />

      <AddressList addresses={addresses} />

      <Group position="center" pt="lg">
        <CreateButton component={Link} to="new">
          Ajouter une adresse
        </CreateButton>
      </Group>
    </Box>
  );
};

const profileAddressListRoute = defineRoute({
  component: ProfileAddressListPage,
  loader,
});

export default profileAddressListRoute;
