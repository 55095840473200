import React, { ChangeEventHandler, FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from '@modules/auth/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, Group, Text, TextInput } from '@mantine/core';
import { CustomPasswordInput } from '@styles/shared';
import { Link } from 'react-router-dom';

interface RegisterFormProps {
  loading: boolean;
  onSubmit: (params: Auth.RegisterParams) => void;
}

// FIXME -> CGU link

const RegisterForm: FC<RegisterFormProps> = ({ loading, onSubmit }) => {
  const [CGUAccepted, setCGUAccepted] = useState(false);
  const [CGUError, setCGUError] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Auth.RegisterParams>({
    resolver: zodResolver(Auth.RegisterParams),
  });

  const handleCheckSubmit = (params: Auth.RegisterParams) => {
    if (CGUAccepted) {
      onSubmit(params);
    } else {
      setCGUError(true);
    }
  };

  const handleCGUAcceptationChange: ChangeEventHandler<HTMLInputElement> = e => {
    setCGUError(false);
    setCGUAccepted(e.target.checked ?? false);
  };

  return (
    <form onSubmit={handleSubmit(handleCheckSubmit)} noValidate>
      <TextInput id="firstName" label="Prénom" error={!!errors.lastName} {...register('firstName')} />

      <TextInput mt="sm" id="lastName" label="Nom" error={!!errors.lastName} {...register('lastName')} />

      <TextInput mt="sm" id="email" type="email" label="E-mail" error={!!errors.email} {...register('email')} />

      <CustomPasswordInput
        mt="sm"
        id="password"
        label="Mot de passe"
        error={errors.password?.message}
        {...register('password')}
      />

      <CustomPasswordInput
        mt="sm"
        id="confirmPassword"
        label="Confirmer le mot de passe"
        error={errors.confirmPassword?.message}
        {...register('confirmPassword')}
      />

      <Checkbox
        mt="sm"
        label={
          <Text size={12}>
            J'accepte les{' '}
            <Link to="/cgu" style={{ color: 'black' }}>
              Conditions d'utilisation
            </Link>
            , et le traitement de mes données à des fins de recherche
          </Text>
        }
        checked={CGUAccepted}
        onChange={handleCGUAcceptationChange}
      />

      <Group mt={28} grow>
        <Button type="submit" loading={loading} disabled={loading}>
          Créer mon compte
        </Button>
      </Group>

      {CGUError ? (
        <Text mt="md" size="sm" fw="600" color="red" align="center">
          Veuillez accepter les Conditions Générales d'utilisation
        </Text>
      ) : null}
    </form>
  );
};

export default RegisterForm;
