import styled from '@emotion/styled';

export const ImpactRankingTitle = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 5px;
  line-height: 10px;
`;

export const ImpactRankingValues = styled.div`
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const ImpactRankingText = styled.div`
  margin-top: 20px;
  padding-left: 50px;
  position: relative;
  min-height: 22px;
`;

export const ImpactRankingImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
`;
