import styled from '@emotion/styled';

export const HomeHeaderContainer = styled.div`
  padding: 40px 30px 25px;
  background-color: ${props => props.theme.colors.pink[2]};
  border-radius: 0 0 0 28px;
`;

export const HomeHeaderContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
`;

export const HomeHeaderCompleted = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
`;

export const HomeHeaderEndImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
