import React, { FC } from 'react';
import { ProfileAddress } from '@modules/profile/address/model';
import { Address } from '@shared/modules/address/model';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Group, TextInput } from '@mantine/core';
import AddressSelector from '@shared/modules/address/components/AddressSelector';
import AddressType = Address.AddressType;

interface AddressFormProps {
  type: Address.AddressType;
  body: ProfileAddress.ProfileAddressBody;
  loading: boolean;
  onSubmit: (body: ProfileAddress.ProfileAddressBody) => void;
}

const AddressForm: FC<AddressFormProps> = ({ type, body, loading, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control
  } = useForm<ProfileAddress.ProfileAddressBody>({
    resolver: zodResolver(ProfileAddress.ProfileAddressBody),
    defaultValues: body,
  });

  return (
    <Box mt={30}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {type === AddressType.Custom ? (
          <TextInput id="name" label="Nom" error={!!errors.name} {...register('name')} />
        ) : null}

        <Controller control={control} name="address" render={({ field }) => (
          <AddressSelector
            mt="sm"
            label={type === AddressType.Custom ? 'Adresse' : Address.addressTypeName[type]}
            error={!!errors.address}
            {...field}
          />
        )}/>

        <Group grow mt={20}>
          <Button type="submit" loading={loading} disabled={loading}>Valider</Button>
        </Group>
      </form>
    </Box>
  );
};

export default AddressForm;
