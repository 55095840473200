import React, { FC } from 'react';
import { useProfile } from '@modules/profile/profile-loader';
import { Box, Group, Title } from '@mantine/core';

import headerImage from '@assets/profile/profile-image.png';
import headerImage2x from '@assets/profile/profile-image-2x.png';

import * as Styled from './ProfileMenuHeader.styles';

const ProfileMenuHeader: FC = () => {
  const profile = useProfile();

  return (
    <Group p="47px 15px 35px" spacing={13} noWrap>
      <Styled.ProfileMenuHeaderImageContainer>
        <img width={60} height={60} src={headerImage} srcSet={`${headerImage2x} 2x`} alt="Header" />
      </Styled.ProfileMenuHeaderImageContainer>

      <Title>
        Bonjour <br />
        <Box component="span" sx={theme => ({ color: theme.colors.violet[6] })}>
          {profile.firstName}
        </Box>
      </Title>
    </Group>
  );
};

export default ProfileMenuHeader;
