import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const HomeHeaderCardContainer = styled(Link)`
  padding: 15px;
  display: grid;
  grid-template-columns: 20px 1fr 24px;
  grid-column-gap: 15px;
  align-items: center;
  text-decoration: none;
  background-color: ${props => props.theme.colors.pink[0]};
  border-radius: 8px;
  width: 100%;
`;

export const HomeHeaderCardImg = styled.img`
  width: 20px;
  height: 20px;
`;

export const HomeHeaderCardCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.colors.violet[6]};
  width: 20px;
  height: 20px;
`;

export const HomeHeaderCardText = styled.div`
  display: flex;
  flex-direction: column;
`;
