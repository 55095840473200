import React, { FC, useLayoutEffect, useMemo } from 'react';
import { useMantineTheme } from '@mantine/core';
import { Outlet } from 'react-router-dom';

export type BackgroundLayoutColor = 'pink' | 'violet' | 'blue' | 'orange' | 'beige';

interface BackgroundLayoutProps {
  color: BackgroundLayoutColor;
}

const BackgroundLayout: FC<BackgroundLayoutProps> = ({ color }) => {
  const theme = useMantineTheme();

  const backgroundColor = useMemo(() => {
    switch (color) {
      case 'violet':
        return theme.colors.violet[6];
      case 'blue':
        return theme.colors.blue[2];
      case 'pink':
        return theme.colors.pink[2];
      case 'orange':
        return theme.colors.orange[0];
      default:
        return theme.colors.beige[0];
    }
  }, [color, theme.colors]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = backgroundColor;

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [backgroundColor]);

  return <Outlet />;
};

export default BackgroundLayout;
