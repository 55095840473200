import { ProfileTravels } from '@modules/profile/travels/model';
import { Weeks } from '@modules/weeks/model';
import { ProfileAddress } from '@modules/profile/address/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { WeeksUtils } from '@modules/weeks/utils';

export namespace ProfileTravelsUtils {
  export function profileTravelToUpdateTravelBody(
    travels: Array<ProfileTravels.ProfileTravelItem>,
    addresses: Array<ProfileAddress.ProfileAddress>,
  ): Weeks.UpdateTravelBody {
    // On triche un peu en mettant tous les travels en `Outward` pour réutiliser l'utils
    const list = pipe(
      travels ?? [],
      A.map(travel => ({ ...travel, direction: Weeks.TravelDirection.Outward })),
    );

    return WeeksUtils.travelsToUpdateTravelBody(list, Weeks.TravelDirection.Outward, addresses);
  }
}
