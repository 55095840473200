import styled from '@emotion/styled';

export const AuthHomeSliderImageContainer = styled.div`
  position: relative;
  padding-bottom: 90%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
