import React, { ChangeEventHandler, FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { Weeks } from '@modules/weeks/model';
import { Box, Checkbox, TextInput } from '@mantine/core';
import { renderNullable } from '@shared/utils/render';

interface DayTravelFavoriteSaveProps {
  control: Control<Weeks.UpdateTravelDayBody>;
}

const DayTravelFavoriteSave: FC<DayTravelFavoriteSaveProps> = ({ control }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: 'travelName' });

  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = e =>
    field.onChange(e.target.checked ? '' : undefined);

  return (
    <Box mt="lg">
      <Checkbox
        label="Enregistrer ce trajet dans mes trajets habituels"
        checked={field.value != null}
        onChange={handleCheckboxChange}
      />

      {renderNullable(field.value, value => (
        <TextInput mt="sm" id="travelName" label="Nom du trajet" {...field} value={value} error={!!error} />
      ))}
    </Box>
  );
};

export default DayTravelFavoriteSave;
