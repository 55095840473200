import { Route } from 'react-router-dom';
import BackgroundLayout from '@layout/background/BackgroundLayout';
import NavLayout from '@layout/nav/NavLayout';
import { createRoute } from '@core/router';

import profileMenuPage from '@modules/profile/ProfileMenuPage';
import profileAddressRoutes from '@modules/profile/address/routes';
import profileWeeksRoutes from '@modules/profile/weeks/routes';
import profileTravelsRoutes from '@modules/profile/travels/routes';
import profileVehiclesRoutes from '@modules/profile/vehicules/routes';
import updateProfileRoute from '@modules/profile/UpdateProfilePage';

const profileRoutes = (
  <Route path="profile" element={<BackgroundLayout color="pink" />}>
    <Route element={<NavLayout />}>
      <Route index {...createRoute(profileMenuPage)} />
    </Route>

    <Route path="update" {...createRoute(updateProfileRoute)} />

    {profileAddressRoutes}
    {profileWeeksRoutes}
    {profileTravelsRoutes}
    {profileVehiclesRoutes}
  </Route>
);

export default profileRoutes;
