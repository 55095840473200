import React, { FC, useMemo } from 'react';
import { ImpactInfos as ImpactInfosModel, ImpactType } from '@modules/impact/model';
import { Box, createPolymorphicComponent, Paper, PaperProps, Text, useMantineTheme } from '@mantine/core';
import ImpactProgress from '@modules/impact/components/info/progress/ImpactProgress';
import ImpactRanking from '@modules/impact/components/info/ranking/ImpactRanking';
import ImpactTitle from '@shared/components/impact/title/ImpactTitle';
import { MIconImpactOrganization, MIconImpactTeam, MIconImpactUser } from '@styles/icons';
import ImpactInfosLegend from '@modules/impact/components/info/legend/ImpactInfosLegend';
import styled from '@emotion/styled';

const ImpactInfosPlaceholder = createPolymorphicComponent<'div', PaperProps>(styled(Paper)`
  margin-top: 20px;
  background-color: rgba(224, 224, 224, 0.28);
`);

function getObjective(impactType: ImpactType): number {
  switch (impactType) {
    case ImpactType.Nox:
      return 0.6;
    case ImpactType.PM:
      return 0.1;
    case ImpactType.CO2:
      return 0.3;
  }
}

interface ImpactInfosProps {
  infos: ImpactInfosModel;
  value: ImpactType;
  currentWeek: number;
  isGroupImpactAvailable: boolean;
}

const ImpactInfos: FC<ImpactInfosProps> = ({ infos, value, currentWeek, isGroupImpactAvailable }) => {
  const theme = useMantineTheme();

  const color = useMemo(() => {
    switch (value) {
      case ImpactType.Nox:
        return theme.colors.violet[6];
      case ImpactType.PM:
        return theme.colors.orange[6];
      case ImpactType.CO2:
        return theme.colors.blue[6];
    }
  }, [value, theme.colors]);

  return (
    <Box>
      <ImpactTitle title="Classement" impactType={value} />

      <ImpactRanking
        impactType={value}
        team={infos.groupEmission.rank}
        teamTotal={infos.groupEmission.total}
        organization={infos.organizationEmission.rank}
        organizationTotal={infos.organizationEmission.total}
        challengeText={infos.challengeText}
        isGroupImpactAvailable={isGroupImpactAvailable}
        color={color}
        currentWeek={currentWeek}
      />

      <ImpactTitle title="Progression" impactType={value} />

      <ImpactProgress
        title="Mes émissions"
        current={infos.participantCurrentEmission}
        currentPercentage={infos.participantCurrentProgressionPercentage}
        typical={infos.participantModelEmission}
        objective={getObjective(value)}
        bestWeek={infos.participantBestEmissionPercentage}
        bestWeekValue={infos.participantBestEmission}
        impactType={value}
        unit={infos.unit}
        icon={<MIconImpactUser width={20} height={20} />}
        currentWeek={currentWeek}
        isGroupImpactAvailable={true}
        color={color}
      />

      {!isGroupImpactAvailable ? (
        <ImpactInfosPlaceholder p={15} withBorder={false}>
          <Text size={14}>
            Le diagnostic d'équipe est bientôt prêt ! Presque tous les participants ont répondu, nous allons bientôt
            créer le compte rendu de diagnostic.
          </Text>
        </ImpactInfosPlaceholder>
      ) : null}

      <ImpactProgress
        title="Émissions cumulées de mon équipe"
        current={infos.groupEmission.currentEmission}
        currentPercentage={infos.groupEmission.currentProgressionPercentage}
        typical={infos.groupEmission.modelWeekEmission}
        objective={getObjective(value)}
        bestWeek={infos.groupEmission.bestProgressionPercentage}
        bestWeekValue={infos.groupEmission.bestEmission}
        impactType={value}
        unit={infos.unit}
        icon={<MIconImpactTeam width={20} height={20} />}
        currentWeek={currentWeek}
        isGroupImpactAvailable={isGroupImpactAvailable}
        color={color}
      />

      <ImpactProgress
        title="Émissions cumulées de mon organisation"
        current={infos.organizationEmission.currentEmission}
        currentPercentage={infos.organizationEmission.currentProgressionPercentage}
        typical={infos.organizationEmission.modelWeekEmission}
        objective={getObjective(value)}
        bestWeek={infos.organizationEmission.bestProgressionPercentage}
        bestWeekValue={infos.organizationEmission.bestEmission}
        impactType={value}
        unit={infos.unit}
        icon={<MIconImpactOrganization width={20} height={20} />}
        currentWeek={currentWeek}
        isGroupImpactAvailable={isGroupImpactAvailable}
        color={color}
      />

      <ImpactInfosLegend currentWeek={currentWeek} color={color} />
    </Box>
  );
};

export default ImpactInfos;
