import styled from '@emotion/styled';
import { Button, ButtonProps, createPolymorphicComponent, Group, GroupProps } from '@mantine/core';

export const TransportSelectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 10px;

  &.open {
    border-color: ${props => props.theme.colors.violet[2]};
    background: ${props => props.theme.colors.pink[0]};
  }
`;

export const TransportSelectionButton = createPolymorphicComponent<'button', ButtonProps>(styled(Button)`
  cursor: pointer;

  .mantine-Button-leftIcon {
    margin-right: 0 !important;
  }

  &.open {
    width: 100%;
    padding: 15px 20px;
    background: none;
    border: none;
    font: inherit;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: ${props => props.theme.colors.violet[6]};

    .mantine-Button-inner {
      position: relative;

      .mantine-Button-rightIcon {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`);

export const TransportSelectionContent = styled.div`
  width: 100%;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  opacity: 0;

  &.open {
    opacity: 1;
  }
`;

export const TransportSelectionSection = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.violet[6]};
`;

export const TransportSelectionItem = createPolymorphicComponent<'div', GroupProps>(styled(Group)`
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #f4dcf4;
  }

  svg {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    color: ${props => props.theme.colors.violet[6]};
  }
`);
