import React, { FC, useMemo } from 'react';
import * as Styled from './ImpactDetailLegend.styles';
import { SimpleGrid, Text, useMantineTheme } from '@mantine/core';
import { ImpactType } from '@modules/impact/model';

interface ImpactDetailLegendProps {
  impactType: ImpactType;
  currentWeek: number;
}

const ImpactDetailLegend: FC<ImpactDetailLegendProps> = ({ impactType, currentWeek }) => {
  const theme = useMantineTheme();

  const color = useMemo(() => {
    switch (impactType) {
      case ImpactType.Nox:
        return theme.colors.violet[6];
      case ImpactType.PM:
        return theme.colors.orange[6];
      case ImpactType.CO2:
        return theme.colors.blue[6];
    }
  }, [impactType, theme.colors]);

  return (
    <Styled.ImpactDetailLegendContainer>
      <Text transform="uppercase" size={10} weight={400}>
        Légende
      </Text>

      <SimpleGrid cols={2} pt={5}>
        <Styled.ImpactDetailLegendItem>
          <Styled.ImpactDetailLegendItemColor color={color} />

          <Text size={9} pl={5}>
            {currentWeek === 0 ? 'La semaine type' : 'La semaine écoulée'}
          </Text>
        </Styled.ImpactDetailLegendItem>

        {currentWeek !== 0 ? (
          <Styled.ImpactDetailLegendItem>
            <Styled.ImpactDetailLegendItemColor color="white" />

            <Text size={9} pl={5}>
              La semaine type
            </Text>
          </Styled.ImpactDetailLegendItem>
        ) : null}
      </SimpleGrid>
    </Styled.ImpactDetailLegendContainer>
  );
};

export default ImpactDetailLegend;
