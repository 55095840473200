import { FC, useMemo, useState } from 'react';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { defineRoute } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Box, Button } from '@mantine/core';
import ImpactTabs from '@shared/components/impact/tabs/ImpactTabs';
import { ImpactType } from '@modules/impact/model';
import ImpactTitle from '@shared/components/impact/title/ImpactTitle';
import ImpactDetailLegend from '@modules/impact/components/detail/legend/ImpactDetailLegend';
import ImpactDetailInfos from '@modules/impact/components/detail/infos/ImpactDetailInfos';
import { Link, useLocation } from 'react-router-dom';
import { MIconArrowRight } from '@styles/icons';
import { useParentLoader } from '@core/router/loader';
import { impactLoader } from '@modules/impact/routes';
import ImpactPlaceholder from '@modules/impact/components/placeholder/ImpactPlaceholder';
import { getEnumQuery } from '@shared/modules/filter';
import { parseQueries } from '@shared/utils/queries';

const ImpactDetail: FC = () => {
  const { impact, home } = useParentLoader<typeof impactLoader>('impact');

  const { search } = useLocation();

  const impactType = useMemo(
    () =>
      pipe(
        O.fromNullable(getEnumQuery(parseQueries(search), ImpactType, 'type')),
        O.fold(
          () => ImpactType.Nox,
          type => type,
        ),
      ),
    [search],
  );

  const [value, setValue] = useState<ImpactType>(impactType);

  return (
    <Box p={30} pb={100} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Détail par mode de transport" />

      {home.isWeekClosed ? (
        <>
          <ImpactTabs
            value={value}
            setValue={setValue}
            data={[
              { label: 'NOx', value: ImpactType.Nox },
              { label: 'Particules', value: ImpactType.PM },
              { label: 'CO2', value: ImpactType.CO2 },
            ]}
          />

          <ImpactTitle title="Mes émissions" impactType={value} />

          <ImpactDetailLegend impactType={value} currentWeek={home.currentWeekNumber} />

          <ImpactDetailInfos
            infos={impact[`${value}`].participantDetail}
            impactType={value}
            unit={impact[`${value}`].unit}
            currentWeek={home.currentWeekNumber}
          />

          {home.currentWeekNumber !== 0 ? (
            <Button component={Link} to="/" w="100%" color="orange" rightIcon={<MIconArrowRight />} mt={30}>
              Je passe à l'action
            </Button>
          ) : null}

          <ImpactTitle title="Les émissions de mon équipe" impactType={value} />

          <ImpactDetailLegend impactType={value} currentWeek={home.currentWeekNumber} />

          <ImpactDetailInfos
            infos={impact[`${value}`].groupDetail}
            impactType={value}
            unit={impact[`${value}`].unit}
            currentWeek={home.currentWeekNumber}
          />

          {home.currentWeekNumber !== 0 ? (
            <Button component={Link} to="/" w="100%" color="orange" rightIcon={<MIconArrowRight />} mt={30}>
              Je passe à l'action
            </Button>
          ) : null}
        </>
      ) : (
        <ImpactPlaceholder />
      )}
    </Box>
  );
};

const impactDetail = defineRoute({
  component: ImpactDetail,
  loader: undefined,
});

export default impactDetail;
