import React, { FC } from 'react';
import { Button, Text } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import actionIllu from '@assets/images/actions.svg';
import { Link } from 'react-router-dom';
import { MIconArrowRight } from '@styles/icons';

import * as Styled from './ActionsPlaceholder.styles';

const ActionsPlaceholder: FC = () => {
  return (
    <Styled.ActionsPlaceholderBox>
      <PageTitle title="Je passe à l'action" inverted />

      <Styled.ActionsPlaceholderContainer>
        <img width={175} height={165} src={actionIllu} alt="Illustration" />

        <Text size={16} weight={400} color="white" pt={20}>
          Oups, il n'y a pas d'action par ici pour l'instant :)
        </Text>

        <Button component={Link} to="/" replace variant="white" mt={30}>
          <Text pr={10}>Ok, j'y retourne !</Text>
          <MIconArrowRight />
        </Button>
      </Styled.ActionsPlaceholderContainer>
    </Styled.ActionsPlaceholderBox>
  );
};

export default ActionsPlaceholder;
