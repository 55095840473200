import styled from '@emotion/styled';

export const HomeHeaderInfosContainer = styled.div``;

export const HomeHeaderInfosCards = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 8px;
`;
