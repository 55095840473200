import React, { FC, useMemo } from 'react';
import { Actions } from '@modules/actions/model';
import { useActionsInteractions } from '@modules/actions/hooks';
import ActionCard from '@shared/components/card/ActionCard';

import * as Styled from './ActionsList.styles';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import { renderOptional } from '@shared/utils/render';
import { Accordion, Text } from '@mantine/core';

interface ActionsListProps {
  actions: Array<Actions.ApiDetail>;
}

const ActionsList: FC<ActionsListProps> = ({ actions: receivedActions }) => {
  const { actions, handleAnswerAction } = useActionsInteractions(receivedActions);

  const separatedActions = useMemo(
    () =>
      pipe(
        actions,
        A.partition(action => action.answer !== Actions.ActionAnswer.NotOk || action.pendingNotOk === true),
      ),
    [actions],
  );

  return (
    <>
      {renderOptional(NEA.fromArray(separatedActions.right), actions => (
        <Styled.ActionsListContainer>
          {actions.map(action => (
            <ActionCard key={action.id} action={action} onAnswer={handleAnswerAction} />
          ))}
        </Styled.ActionsListContainer>
      ))}

      {renderOptional(NEA.fromArray(separatedActions.left), actions => (
        <Styled.ActionsListAccordion mt={20}>
          <Accordion.Item value={Actions.ActionAnswer.NotOk}>
            <Accordion.Control>
              <Text size={10} weight={600}>
                Voir les actions rejetées
              </Text>
            </Accordion.Control>

            <Accordion.Panel>
              <Styled.ActionsListContainer>
                {actions.map(action => (
                  <ActionCard key={action.id} action={action} onAnswer={handleAnswerAction} />
                ))}
              </Styled.ActionsListContainer>
            </Accordion.Panel>
          </Accordion.Item>
        </Styled.ActionsListAccordion>
      ))}
    </>
  );
};

export default ActionsList;
