import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';
import styled from '@emotion/styled';

export const ActionsPlaceholderBox = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  padding: 30px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: calc(100vh - 66px);
`);

export const ActionsPlaceholderContainer = createPolymorphicComponent<'div', BoxProps>(styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`);
