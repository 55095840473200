import styled from '@emotion/styled';
import { Accordion } from '@mantine/core';

export const ActionsListContainer = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 17px;
  grid-column-gap: 15px;

  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

export const ActionsListAccordion = styled(Accordion)`
  [data-active] {
    background-color: transparent;
  }

  .mantine-Accordion-item {
    border: none;
  }

  .mantine-Accordion-control {
    padding: 0;
    justify-content: flex-end;
    background: none;
    color: #fff;

    .mantine-Accordion-label {
      flex: 0 0 auto;
    }
  }

  .mantine-Accordion-content {
    padding: 0;
  }
`;
