import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { Infos } from '@modules/infos/model';
import { Actions } from '@modules/actions/model';

export enum HomeWeekStatus {
  Todo = 'todo',
  Error = 'error',
  Validated = 'validated',
}

export enum HomeStep {
  NotNeeded = 'notneeded',
  Done = 'done',
  Todo = 'todo',
}

export interface HomeModel {
  currentWeekNumber: number;
  hasHomeAddress: boolean;
  isSurveyAnswered: boolean;
  isEndSurveyAnswered: boolean;
  isWeekClosed: boolean;
  weekStatus: HomeWeekStatus;
  isGroupImpactAvailable: boolean;
  isUserSuspended: boolean;
  carStep: HomeStep;
  twoWheelersStep: HomeStep;
  stepProgressionPercentage: number;
  currentActions: Array<Actions.ApiDetail>;
  currentInformations: Array<Infos.ApiDetail>;
}

export enum HomeInfosEnum {
  HasHomeAddress = 'hasHomeAddress',
  IsSurveyAnswered = 'isSurveyAnswered',
  IsWeekClosed = 'isWeekClosed',
}

export const homeInfosLabel: Record<HomeInfosEnum, string> = {
  [HomeInfosEnum.HasHomeAddress]: "J'entre mon adresse",
  [HomeInfosEnum.IsSurveyAnswered]: "Je réponds à l'enquête d'accueil",
  [HomeInfosEnum.IsWeekClosed]: 'Je renseigne ma semaine type domicile-travail',
};

export const homeInfosTimeLabel: Record<HomeInfosEnum, string> = {
  [HomeInfosEnum.HasHomeAddress]: '< 1 minute',
  [HomeInfosEnum.IsSurveyAnswered]: '< 15 minutes',
  [HomeInfosEnum.IsWeekClosed]: '< 7 minutes',
};

export const homeInfosLink: Record<HomeInfosEnum, string> = {
  [HomeInfosEnum.HasHomeAddress]: '/profile/addresses/home',
  [HomeInfosEnum.IsSurveyAnswered]: '/survey/start',
  [HomeInfosEnum.IsWeekClosed]: 'weeks',
};

export type HomeInfos = Record<HomeInfosEnum, boolean>;

export enum HomeAddEnum {
  CarStep = 'carStep',
  TwoWheelersStep = 'twoWheelersStep',
}

export const homeAddLabel: Record<HomeAddEnum, string> = {
  [HomeAddEnum.CarStep]: "J'ajoute ma voiture",
  [HomeAddEnum.TwoWheelersStep]: "J'ajoute mon 2 roues motorisé",
};

export const homeAddTimeLabel: Record<HomeAddEnum, string> = {
  [HomeAddEnum.CarStep]: '< 2 minutes',
  [HomeAddEnum.TwoWheelersStep]: '< 2 minutes',
};

export const homeAddLink: Record<HomeAddEnum, string> = {
  [HomeAddEnum.CarStep]: `/profile/vehicles/new/${ProfileVehicles.ProfileVehicleType.Car}`,
  [HomeAddEnum.TwoWheelersStep]: `/profile/vehicles/new/${ProfileVehicles.ProfileVehicleType.TwoWheelers}`,
};

export type HomeAdd = Record<HomeAddEnum, HomeStep>;

export interface RealizedProps {
  description: string;
  time: string;
  link: string;
}
