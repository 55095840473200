import styled from '@emotion/styled';

export const ImpactDetailInfosContainer = styled.div`
  //padding-bottom: 45px;
`;

export const ImpactDetailInfosItem = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const ImpactDetailInfosDatas = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr;
  grid-column-gap: 8px;
  align-items: center;
  height: 30px;
`;

export const ImpactDetailInfosValues = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ImpactDetailInfosSliderWrapper = styled.div<{ width: number }>`
  height: 30px;
  width: ${props => props.width}%;
  min-height: 2px;
`;

export const ImpactDetailInfosSliderContainer = styled.div`
  position: relative;
`;

export const ImpactDetailInfosSliderCurrent = styled.div<{ color: string; width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.color};
  border-radius: 8px;
  width: ${props => props.width}%;
  min-width: 2px;
  height: 30px;
`;

export const ImpactDetailInfosSliderTypical = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-radius: 8px;
  width: ${props => props.width}%;
  min-width: 2px;
  height: 30px;
`;

export const ImpactDetailInfosLabels = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: row;
`;
