import React, { FC } from 'react';
import * as Styled from './HomeHeaderInfos.styles';
import HomeHeaderCard from '@modules/home/components/header/card/HomeHeaderCard';
import { HomeInfos, HomeInfosEnum, homeInfosLabel, homeInfosLink, homeInfosTimeLabel } from '@modules/home/model';

interface HomeHeaderInfosProps {
  step: HomeInfos;
}

const HomeHeaderInfos: FC<HomeHeaderInfosProps> = ({ step }) => {
  return (
    <Styled.HomeHeaderInfosContainer>
      <Styled.HomeHeaderInfosCards>
        {Object.entries(step).map(([key, value], index) => (
          <HomeHeaderCard
            isCompleted={value}
            description={homeInfosLabel[key as HomeInfosEnum]}
            time={homeInfosTimeLabel[key as HomeInfosEnum]}
            link={homeInfosLink[key as HomeInfosEnum]}
            key={index}
          />
        ))}
      </Styled.HomeHeaderInfosCards>
    </Styled.HomeHeaderInfosContainer>
  );
};

export default HomeHeaderInfos;
