import React, { FC, PropsWithChildren } from 'react';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';

const MAX_WIDTH = 500;

interface AuthLayoutProps {
  title: string;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ title, children }) => {
  return (
    <Box p="xl" m="0 auto" sx={{ maxWidth: MAX_WIDTH }}>
      <PageTitle title={title} />

      <Box mt="xl">{children}</Box>
    </Box>
  );
};

export default AuthLayout;
