import React, { FC, useMemo } from 'react';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Anchor, Box, Button, Group, Select, TextInput } from '@mantine/core';
import { getDropdownOptionsFromEnumLabels } from '@shared/utils/enum';
import CritAirOption from '@modules/profile/vehicules/components/crit-air/CritAirOption';
import ProfileVehicleType = ProfileVehicles.ProfileVehicleType;

function getDefaultValues(
  type: ProfileVehicles.ProfileVehicleType,
  vehicle?: ProfileVehicles.ProfileVehicle,
): ProfileVehicles.ProfileVehicleParams {
  if (vehicle) {
    return {
      name: vehicle.name,
      type: vehicle.type,
      critAir: vehicle.critAir ?? ('' as ProfileVehicles.CritAir),
    };
  }

  return {
    name: '',
    type:
      type === ProfileVehicles.ProfileVehicleType.TwoWheelers
        ? ProfileVehicles.TwoWheelersType.GasolineTwoWheelers
        : ('' as ProfileVehicles.CarType),
    critAir: '' as ProfileVehicles.CritAir,
  };
}

interface CritAirOptions {
  value: ProfileVehicles.CritAir;
  label: string;
}

function getCritAirOptions(
  type: ProfileVehicles.CarType | ProfileVehicles.TwoWheelersType.GasolineTwoWheelers,
  critAirOptions: Array<CritAirOptions>,
): Array<CritAirOptions> {
  switch (type) {
    case ProfileVehicles.CarType.GasolineCar:
      return critAirOptions.filter(
        option =>
          option.value !== ProfileVehicles.CritAir.C0 &&
          option.value !== ProfileVehicles.CritAir.C4 &&
          option.value !== ProfileVehicles.CritAir.C5,
      );

    case ProfileVehicles.CarType.DieselCar:
    case ProfileVehicles.CarType.UtilityVehicle:
      return critAirOptions.filter(
        option => option.value !== ProfileVehicles.CritAir.C0 && option.value !== ProfileVehicles.CritAir.C1,
      );

    case ProfileVehicles.CarType.ElectricCar:
      return critAirOptions.filter(option => option.value === ProfileVehicles.CritAir.C0);

    case ProfileVehicles.TwoWheelersType.GasolineTwoWheelers:
      return critAirOptions.filter(
        option => option.value !== ProfileVehicles.CritAir.C0 && option.value !== ProfileVehicles.CritAir.C5,
      );

    default:
      return critAirOptions;
  }
}

interface VehicleFormProps {
  type: ProfileVehicles.ProfileVehicleType;
  vehicle?: ProfileVehicles.ProfileVehicle;
  loading: boolean;
  onSubmit: (params: ProfileVehicles.ProfileVehicleParams) => void;
}

const VehicleForm: FC<VehicleFormProps> = ({ type, vehicle, loading, onSubmit }) => {
  const defaultValues = useMemo(() => getDefaultValues(type, vehicle), [type, vehicle]);

  const {
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
    control,
    register,
  } = useForm<ProfileVehicles.ProfileVehicleParams>({
    resolver: zodResolver(ProfileVehicles.ProfileVehicleParams),
    defaultValues,
  });

  const handleChangeVehicle = (type: ProfileVehicles.CarType | ProfileVehicles.TwoWheelersType.GasolineTwoWheelers) => {
    setValue('type', type);
    setValue('critAir', '' as ProfileVehicles.CritAir);
  };

  const critAirOptions = useMemo(
    () =>
      Object.values(ProfileVehicles.CritAir)
        // Les deux roues n'ont pas le critAir 5
        .filter(critAir => (type === ProfileVehicleType.TwoWheelers ? critAir !== ProfileVehicles.CritAir.C5 : true))
        .map(critAir => ({
          value: critAir,
          label: ProfileVehicles.critAirInfos[critAir].label,
        })),
    [type],
  );

  return (
    <Box mt={33}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextInput id="name" label="Nom du véhicule" error={!!errors.name} {...register('name')} />

        {type === ProfileVehicleType.Car ? (
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                mt="sm"
                id="type"
                label="Type de véhicule"
                data={getDropdownOptionsFromEnumLabels(ProfileVehicles.carTypeLabel)}
                error={!!errors.type}
                onChange={handleChangeVehicle}
              />
            )}
          />
        ) : null}

        <Controller
          control={control}
          name="critAir"
          render={({ field }) => (
            <Select
              mt="sm"
              id="type"
              label="Vignette CRIT’Air"
              data={getCritAirOptions(watch('type'), critAirOptions)}
              itemComponent={CritAirOption}
              error={!!errors.critAir}
              styles={{ item: { padding: 0 } }}
              {...field}
            />
          )}
        />

        <Box mt="sm">
          <Anchor
            c="black"
            href="https://www.service-public.fr/particuliers/vosdroits/R44803"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trouver la catégorie Crit'Air de mon véhicule
          </Anchor>
        </Box>

        <Group grow mt="md">
          <Button type="submit" loading={loading} disabled={loading}>
            Valider
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default VehicleForm;
