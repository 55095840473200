import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import PageTitle from '@layout/title/PageTitle';
import { Anchor, AnchorProps, Box, createPolymorphicComponent, Text } from '@mantine/core';
import { MIconArrowRight } from '@styles/icons';
import styled from '@emotion/styled';

const OperationLinkContainer = createPolymorphicComponent<'div', AnchorProps>(styled(Anchor)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
`);

interface OperationLinkProps {
  link: string;
}

const OperationLink: FC<OperationLinkProps> = ({ link }) => {
  return (
    <OperationLinkContainer component="a" href={link} target="_blank" rel="noreferrer noopener">
      <Text size={14} weight={600} underline pr={5} color="white">
        En savoir plus
      </Text>

      <MIconArrowRight width={14} color="white" />
    </OperationLinkContainer>
  );
};

const OperationPage: FC = () => {
  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title="Opération réduction des émissions" inverted />

      <Text color="white" pt={35}>
        La qualité de l’air est un enjeu majeur pour la santé et l'environnement. En France, le coût de la pollution
        atmosphérique est évalué de 70 à 100 milliards d’euros par an par la Commission d’enquête du Sénat (rapport
        remis en 2015). Sur la période 2016-2019, Santé publique France estime que « chaque année près de 40 000 décès,
        pour des personnes âgées de 30 ans et plus, seraient attribuables à une exposition aux particules fines (PM2,5).
        Ainsi, l’exposition à la pollution de l’air ambiant représente en moyenne pour les personnes âgées de 30 ans et
        plus une perte d’espérance de vie de près de 8 mois pour les PM2,5 ». La politique en faveur de la qualité de
        l’air nécessite des actions ambitieuses, au niveau international comme au niveau local, dans tous les secteurs
        d’activité. L’État, les collectivités territoriales, les entreprises, les citoyens et les organisations non
        gouvernementales doivent conjuguer leurs efforts pour garantir à chacun le droit de respirer un air qui ne nuise
        pas à sa santé. Cette politique est engagée, elle s’inscrit dans la durée et ses effets sont progressifs.
      </Text>

      <OperationLink link="https://www.ecologie.gouv.fr/politiques-publiques-reduire-pollution-lair" />

      <Text color="white" pt={20}>
        En Ile-de-France, malgré une diminution tendancielle des niveaux moyens annuels des polluants réglementés dans
        l’air ambiant importante ces dernières décennies, les valeurs limites ne sont toujours pas respectées sur une
        partie du territoire pour certains polluants, le dioxyde d’azote et les particules PM10.
      </Text>

      <Text color="white" pt={20}>
        En appui aux politiques publiques visant à améliorer la qualité de l’air et à sortir des contentieux en cours
        avec la Commission européenne et le Conseil d’Etat, Airparif a calculé les ordres de grandeurs de l’ampleur de
        l’effort restant à fournir pour respecter les valeurs limites françaises et européennes.
      </Text>

      <Text color="white" pt={20}>
        Airparif s’est également attachée à étudier les gains d’émissions nécessaires pour tendre vers un respect des
        recommandations de l’OMS, actualisées en septembre 2021, c’est-à-dire évaluer les gains nécessaires permettant
        d’atteindre les cibles intermédiaires 3 ou 4 de ces recommandations.
      </Text>

      <Text color="white" pt={20}>
        A Paris, où la densité d’émissions d’oxyde d’azote (NOx) est la plus importante, la situation à proximité au
        trafic routier nécessite une baisse des émissions liées aux transports de plus de 60% pour que l’ensemble des
        points de proximité respecte ce seuil. Cette diminution permettrait un respect de la valeur limite y compris à
        proximité du Boulevard Périphérique.
      </Text>

      <Text color="white" pt={20}>
        Pour les particules PM10, le levier principal de réduction des émissions est lié au secteur du « bâti », qui
        représente plus de la moitié des émissions franciliennes. Cependant les dépassements de la valeur limite ne sont
        plus observés ces dernières années qu’en situation de proximité au trafic routier et que pour une station de
        mesure du réseau de d’Airparif, à savoir au droit de l’autoroute A1.
      </Text>

      <Text color="white" pt={20}>
        Une baisse de 20% des émissions du transport permettrait de s’assurer pleinement d’un respect de la valeur
        limite à proximité du trafic routier. D’autres scénario mixte bâti et trafic routier permettraient aussi de s’en
        assurer.
      </Text>

      <OperationLink link="https://www.airparif.asso.fr/sites/default/files/pdf/22_07_Rapport_Trajectoire_Scenarisation.pdf" />

      <Text size={16} weight={700} color="white" pt={30}>
        Et pour le CO2 ?
      </Text>

      <Text color="white" pt={20}>
        Les pays signataires de l'Accord de Paris en 2015 se sont engagés à limiter l'augmentation de la température
        moyenne bien en dessous de +2°C, et si possible à +1,5°C. Pour cela, ils se sont engagés, en se basant sur les
        travaux du GIEC, à atteindre la neutralité carbone au cours de la deuxième moitié du 21ème siècle au niveau
        mondial.
      </Text>

      <Text color="white" pt={20}>
        Pour y parvenir, l’Union Européenne s’est fixée un objectif de réduction de ses émissions de gaz à effet de
        serre de 55% au moins en 2030 par rapport à 1990. Pour le moment, la France a baissé ses émissions de moins de
        25% depuis 1990, le reste à faire en matière d’émissions est donc conséquent pour tous les secteurs, notamment
        celui du transport dont les émissions n’ont pas baissé depuis 1990.
      </Text>

      <Text color="white" pt={20}>
        C’est, par exemple, dans ce cadre que l’Union Européenne a fixé la date de fin de la vente de véhicules
        thermiques en 2035.
      </Text>

      <Text color="white" pt={20}>
        Mais alors que la France a réduit ses émissions de 25% entre 1990 et 2018, nous avons encore des efforts non
        négligeables à fournir pour accélérer cette trajectoire.
      </Text>

      <Text color="white" pt={20}>
        <span style={{ fontWeight: 600 }}>30% entre 2023 et 2030 c’est notre nouvel objectif !</span>
        Le secteur des transports en fait partie et chacun de nous a les moyens d’agir en se tournant notamment vers des
        modes de déplacement plus sobres en émissions de gaz à effet de serre.
      </Text>
    </Box>
  );
};

const operationPage = defineRoute({
  component: OperationPage,
  loader: undefined,
});

export default operationPage;
