import React, { FC } from 'react';
import { defineRoute } from '@core/router';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ActionsService } from '@modules/actions/service';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import ActionsPlaceholder from '@modules/actions/components/placeholder/ActionsPlaceholder';
import ActionsList from '@modules/actions/components/list/ActionsList';
import { Box, Text } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';

const loader = defineLoader({
  handler: () => httpTaskToResponseTask(ActionsService.getActions()),
});

const ActionsPage: FC = () => {
  const actions = useLoader<typeof loader>();

  return renderOptional(
    NEA.fromArray(actions),
    actions => (
      <Box p="30px 30px 100px 30px" m="0 auto" sx={{ maxWidth: 700 }}>
        <PageTitle inverted title="Je passe à l'action" />

        <Text c="white" pt={35}>
          Ces actions ont été choisies suite à ton diagnostic. Top ou flop, c'est toi qui garde les actions que tu veux
          tenter !
        </Text>

        <ActionsList actions={actions} />
      </Box>
    ),
    () => <ActionsPlaceholder />,
  );
};

const actionsPage = defineRoute({
  component: ActionsPage,
  loader,
});

export default actionsPage;
