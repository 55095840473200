import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: "'Montserrat', sans-serif",
  headings: {
    fontFamily: "'Montserrat', sans-serif",
    sizes: {
      h1: {
        fontSize: 28,
        lineHeight: '32px',
        fontWeight: '600',
      },
      h2: {
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: '600',
      },
      h3: {
        fontSize: 18,
        lineHeight: '22px',
        fontWeight: '700',
      },
      h4: {
        fontSize: 15,
      },
    },
  },
  primaryColor: 'violet',
  primaryShade: 6,
  colors: {
    // 10x la même couleur -> beige de figma
    beige: [
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
      '#FFF7E7',
    ],
    blue: [
      // 0 -> blue3 figma
      '#E1F3FF',
      '#d0ebff',
      // 2 -> blue2 figma
      '#A3DAFF',
      '#74c0fc',
      '#4dabf7',
      '#339af0',
      // 6 -> blue1 figma
      '#56B1F0',
      '#1c7ed6',
      '#1971c2',
      '#1864ab',
    ],
    violet: [
      // 0 -> violet 0 figma
      '#F1E3FF',
      '#e5dbff',
      // 2 -> violet 2 figma
      '#E2B6E2',
      '#b197fc',
      '#9775fa',
      '#845ef7',
      // 6 -> violet tout court figma
      '#6A25AE',
      '#7048e8',
      '#6741d9',
      '#5f3dc4',
    ],
    orange: [
      // 0 -> orange 3 figma
      '#FFE0CE',
      '#ffe8cc',
      '#ffd8a8',
      '#ffc078',
      '#ffa94d',
      '#ff922b',
      // 6 -> orange button
      '#EE5800',
      //7 -> orange bright
      '#FF5E00',
      '#e8590c',
      '#d9480f',
    ],
    pink: [
      // 0 -> light mauve
      '#FFF3FF',
      '#ffdeeb',
      // 2 -> pink
      '#F4CBF4',
      '#faa2c1',
      '#f783ac',
      '#f06595',
      '#e64980',
      '#d6336c',
      '#c2255c',
      '#a61e4d',
    ],
  },
  components: {
    // Buttons
    Button: {
      defaultProps: {
        size: 'lg',
        radius: 'md',
        fz: 16,
      },
    },

    // Inputs
    InputWrapper: {
      styles: {
        label: {
          fontSize: 14,
          paddingBottom: 5,
        },
        error: {
          fontSize: 14,
          fontWeight: 600,
        },
      },
    },
    Input: {
      styles: {
        input: {
          border: '1px solid #000',
          fontSize: '16px',
        },
      },
    },
    TextInput: {
      defaultProps: {
        size: 'lg',
        radius: 'md',
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'lg',
        radius: 'md',
      },
      styles: {
        innerInput: {
          fontSize: 16,
        },
      },
    },
    Select: {
      defaultProps: {
        size: 'lg',
        radius: 'md',
      },
      styles: theme => ({
        itemsWrapper: {
          padding: 0,

          overflow: 'hidden',
        },
        item: {
          fontSize: 14,
          borderRadius: 0,

          '&:not(:last-child)': {
            borderBottom: '1px solid #000',
          },

          '&[data-selected], &[data-selected]:hover': {
            backgroundColor: theme.colors.violet[0],
            color: theme.black,
          },
        },
        dropdown: {
          border: '1px solid #000',
          borderRadius: 8,
          overflow: 'hidden',
        },
      }),
    },

    Alert: {
      defaultProps: {
        variant: 'outline',
      },
    },

    Anchor: {
      defaultProps: {
        size: 'sm',
        fw: 600,
        underline: true,
        color: 'violet.6',
      },
    },

    // FIXME -> a vérifier
    Paper: {
      defaultProps: {
        withBorder: true,
        radius: 16,
      },
    },
    Modal: {
      styles: {
        root: {
          zIndex: 2000,
        },
        modal: {
          border: 'none',
        },
      },
      defaultProps: theme => ({
        overlayColor: theme.colors.gray[2],
        overlayBlur: 2,
      }),
    },
  },
};
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <NotificationsProvider position="top-center">{children}</NotificationsProvider>
    </MantineProvider>
  );
};

export default MantineTheme;
