import React, { FC } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from '@modules/auth/model';
import { Anchor, Button, Group, TextInput } from '@mantine/core';
import { CustomPasswordInput } from '@styles/shared';

interface LoginFormProps {
  loading: boolean;
  onSubmit: (params: Auth.AuthenticateParams) => void;
}

const LoginForm: FC<LoginFormProps> = ({ loading, onSubmit }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Auth.AuthenticateParams>({
    resolver: zodResolver(Auth.AuthenticateParams),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextInput id="email" type="email" label="E-mail" error={!!errors.email} {...register('email')} />

      <CustomPasswordInput
        mt="sm"
        id="password"
        label="Mot de passe"
        error={!!errors.password}
        {...register('password')}
      />

      <Group mt={28} grow>
        <Button type="submit" loading={loading} disabled={loading}>
          Se connecter
        </Button>
      </Group>

      <Group mt="sm" position="center">
        <Anchor component={Link} to="/password-reset/forgot" align="center">
          Oups. Mot de passe oublié ?
        </Anchor>
      </Group>
    </form>
  );
};

export default LoginForm;
