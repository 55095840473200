import BackgroundLayout from '@layout/background/BackgroundLayout';
import { Route } from 'react-router-dom';
import { createRoute } from '@core/router';
import operationPage from '@modules/operation/OperationPage';

const operationRoutes = (
  <Route path="operation" element={<BackgroundLayout color="violet" />}>
    <Route index {...createRoute(operationPage)} />
  </Route>
);

export default operationRoutes;
