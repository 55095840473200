import React, { FC, PropsWithChildren } from 'react';
import { Box, Text } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import styled from '@emotion/styled';

const PagesItems = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 17px;
  grid-column-gap: 15px;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

interface PagesProps {
  title: string;
  description: string;
  inverted?: boolean;
  withMenu?: boolean;
}

const Pages: FC<PropsWithChildren<PagesProps>> = ({
  title,
  description,
  inverted = false,
  withMenu = false,
  children,
}) => {
  const color = inverted ? 'white' : 'black';

  return (
    <Box p={withMenu ? '30px 30px 100px 30px' : 30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle title={title} inverted={inverted} />

      <Text color={color} pt={35}>
        {description}
      </Text>

      <PagesItems>{children}</PagesItems>
    </Box>
  );
};

export default Pages;
