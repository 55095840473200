import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { Weeks } from '@modules/weeks/model';

export namespace ProfileVehiclesUtils {
  export function getProfileVehicleType({ type }: ProfileVehicles.ProfileVehicle): ProfileVehicles.ProfileVehicleType {
    return type === ProfileVehicles.TwoWheelersType.GasolineTwoWheelers
      ? ProfileVehicles.ProfileVehicleType.TwoWheelers
      : ProfileVehicles.ProfileVehicleType.Car;
  }

  export function getTransportTypeFromVehicle({ type }: ProfileVehicles.ProfileVehicle): Weeks.TransportType {
    return type === ProfileVehicles.TwoWheelersType.GasolineTwoWheelers
      ? Weeks.TransportType.TwoWheelers
      : Weeks.TransportType.Car;
  }
}
