import React, { ChangeEventHandler, FC, useMemo } from 'react';
import { Weeks } from '@modules/weeks/model';
import { ProfileAddress } from '@modules/profile/address/model';
import DayTravelCard from '@modules/weeks/components/update/day/card/DayTravelCard';
import { Button, Checkbox, Group, Paper } from '@mantine/core';
import { WeeksUtils } from '@modules/weeks/utils';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DayTravel from '@modules/weeks/components/update/day/travel/DayTravel';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { ProfileTravels } from '@modules/profile/travels/model';
import DayTravelFavoriteSave from '@modules/weeks/components/update/day/favorite-save/DayTravelFavoriteSave';

function getFormValues(
  day: Weeks.TravelDay,
  addresses: Array<ProfileAddress.ProfileAddress>,
): Weeks.UpdateTravelDayReturnBody {
  const { steps, destination } = WeeksUtils.travelsToUpdateTravelBody(
    day.travels,
    Weeks.TravelDirection.Return,
    addresses,
  );

  return {
    isReturnTravelIdentical: day.isReturnTravelIdentical,
    steps,
    destination,
  };
}

interface DayTravelReturnProps {
  day: Weeks.TravelDay;
  addresses: Array<ProfileAddress.ProfileAddress>;
  vehicles: Array<ProfileVehicles.ProfileVehicle>;
  travels: Array<ProfileTravels.ProfileTravel>;
  loading: boolean;
  readonly: boolean;
  onSubmit: (body: Weeks.UpdateTravelDayReturnBody, navDirection: 'prev' | 'next') => void;
}

const DayTravelReturn: FC<DayTravelReturnProps> = ({
  day,
  addresses,
  vehicles,
  travels,
  loading,
  readonly,
  onSubmit,
}) => {
  const values = useMemo(() => getFormValues(day, addresses), [addresses, day]);

  const { handleSubmit, watch, setValue, control } = useForm<Weeks.UpdateTravelDayReturnBody>({
    resolver: readonly ? undefined : zodResolver(Weeks.UpdateTravelDayReturnBody),
    values,
  });

  const isReturnTravelIdentical = watch('isReturnTravelIdentical');

  const onSubmitForm = (navDirection: 'prev' | 'next') => (body: Weeks.UpdateTravelDayReturnBody) =>
    onSubmit(body, navDirection);

  const handleNavigateNext = handleSubmit(onSubmitForm('next'));
  const handleNavigatePrev = handleSubmit(onSubmitForm('prev'));

  const handleReturnTravelIdenticalChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (!readonly) {
      setValue('isReturnTravelIdentical', e.target.checked ?? false);
    }
  };

  return (
    <DayTravelCard
      day={day.day}
      direction={Weeks.TravelDirection.Return}
      loading={loading}
      onNavigateNext={handleNavigateNext}
      onNavigatePrev={handleNavigatePrev}
    >
      <form onSubmit={handleSubmit(onSubmitForm('next'))} noValidate>
        <Paper mt="xs" bg="pink.0" px={8} py={6} radius={4} withBorder={false}>
          <Checkbox
            checked={isReturnTravelIdentical}
            label="Trajet identique au trajet aller"
            onChange={handleReturnTravelIdenticalChange}
          />
        </Paper>

        {!isReturnTravelIdentical ? (
          <DayTravel
            direction={Weeks.TravelDirection.Return}
            control={control as any}
            addresses={addresses}
            vehicles={vehicles}
            travels={travels}
            readonly={readonly}
          />
        ) : null}

        {!isReturnTravelIdentical && !readonly ? <DayTravelFavoriteSave control={control as any} /> : null}

        {!readonly ? (
          <Group grow my="lg">
            <Button type="submit" loading={loading} disabled={loading}>
              {!WeeksUtils.getAllowedNavigation(day.day, Weeks.TravelDirection.Return).canGoNext
                ? 'Valider'
                : 'Trajet suivant'}
            </Button>
          </Group>
        ) : null}
      </form>
    </DayTravelCard>
  );
};

export default DayTravelReturn;
