import React, { FC } from 'react';
import { z } from 'zod';
import { ProfileVehicles } from '@modules/profile/vehicules/model';
import { defineLoader, httpTaskToResponseTask, useLoader } from '@core/router/loader';
import { ProfileVehiclesService } from '@modules/profile/vehicules/service';
import { defineRoute, useBack } from '@core/router';
import { ProfileVehiclesUtils } from '@modules/profile/vehicules/utils';
import { Box } from '@mantine/core';
import PageTitle from '@layout/title/PageTitle';
import VehicleForm from '@modules/profile/vehicules/components/form/VehicleForm';
import { defineAction, useAction } from '@core/router/action';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const params = z.object({ id: ProfileVehicles.ProfileVehicleId });

const loader = defineLoader({
  params,
  handler: ({ params }) => httpTaskToResponseTask(ProfileVehiclesService.getProfileVehicle(params.id)),
});

const actions = {
  update: defineAction({
    type: 'update',
    params,
    payload: ProfileVehicles.ProfileVehicleParams,
    handler: ({ params, payload }) => ProfileVehiclesService.updateProfileVehicle(params.id, payload),
  }),
};

const ProfileVehicleDetailPage: FC = () => {
  const vehicle = useLoader<typeof loader>();

  const type = ProfileVehiclesUtils.getProfileVehicleType(vehicle);

  const goBack = useBack();

  const [loading, update] = useAction(actions.update);

  const handleSubmit = (params: ProfileVehicles.ProfileVehicleParams) =>
    pipe(
      () => update(params),
      TE.chainIOK(() => goBack),
    )();

  return (
    <Box p={30} m="0 auto" sx={{ maxWidth: 700 }}>
      <PageTitle
        title={
          type === ProfileVehicles.ProfileVehicleType.Car
            ? 'Modifier ma voiture'
            : 'Modifier mon véhicule à 2 roues motorisé'
        }
      />

      <VehicleForm type={type} vehicle={vehicle} loading={loading} onSubmit={handleSubmit} />
    </Box>
  );
};

const profileVehicleDetailRoute = defineRoute({
  component: ProfileVehicleDetailPage,
  loader,
  actions,
});

export default profileVehicleDetailRoute;
