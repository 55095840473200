import { httpService, HttpTask } from '@core/http';
import { HomeModel } from '@modules/home/model';

export namespace HomeService {
  const URI = '/home';

  export function getHome(): HttpTask<HomeModel> {
    return httpService.get(URI);
  }
}
