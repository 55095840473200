import styled from '@emotion/styled';
import { Anchor, AnchorProps, createPolymorphicComponent } from '@mantine/core';

export const CardContainer = createPolymorphicComponent<'div', AnchorProps>(styled(Anchor)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-decoration: none;
  color: black;
`);

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;

  img {
    height: 100%;
    max-width: 100%;
  }
`;

export const CardMore = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 12px;
`;
